'use client';
import StyledSpecificationAndCarbook from './StyledSpecificationAndCarbook';
import propTypes from 'prop-types';
import useIsMobile from 'hooks/useIsMobile';
import Link from '@/Core/Link/Link';
import ButtonLink from '@/UIComponents/Button/ButtonLink';
import { useState } from 'react';
import ModalCarBook from '@/UIComponents/ModalCarBook';

const ClientSpecificationAndCarbook = ({
  order,
  layout,
  content,
  downloadCarbook,
  downloadCatalog,
  selectedFinish
}) => {
  const isMobile = useIsMobile();
  const [isModalShown, setIsModalShown] = useState(false);
  const { finishFields = {} } = selectedFinish || {};
  const { modelFields = {}, modelId } = finishFields?.parentModel || {};
  const technicalSpecsFileLink = modelFields?.technicalSpecsFile?.mediaItemUrl;
  const carBookFile = modelFields?.carbooks?.[0]?.file;
  return (
    <StyledSpecificationAndCarbook id={order} $layout={layout}>
      <div className="content" dangerouslySetInnerHTML={{ __html: content }} />

      <div className="buttons">
        {isMobile ? (
          <>
            <Link className={'link'} href={technicalSpecsFileLink}>
              {downloadCatalog}
            </Link>
            <Link className={'link'} onClick={() => setIsModalShown(true)}>
              {downloadCarbook}
            </Link>
          </>
        ) : (
          <>
            <ButtonLink variant={'primary'} href={technicalSpecsFileLink}>
              {downloadCatalog}
            </ButtonLink>
            <ButtonLink variant={'outlined'} onClick={() => setIsModalShown(true)}>
              {downloadCarbook}
            </ButtonLink>
          </>
        )}
        {isModalShown && (
          <ModalCarBook
            carBookFile={carBookFile}
            modelId={modelId}
            isModalShown={isModalShown}
            setIsModalShown={setIsModalShown}
          ></ModalCarBook>
        )}
      </div>
    </StyledSpecificationAndCarbook>
  );
};

ClientSpecificationAndCarbook.propTypes = {
  layout: propTypes.object,
  order: propTypes.string,
  content: propTypes.string,
  downloadCarbook: propTypes.string,
  downloadCatalog: propTypes.string,
  selectedFinish: propTypes.object
};

export default ClientSpecificationAndCarbook;
