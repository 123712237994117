/* eslint-disable no-fallthrough */
/* eslint-disable no-duplicate-case */

import propTypes from 'prop-types';
import NextLink from 'next/link';

const socialDetectors = [
  /facebook/,
  /instagram/,
  /linkedin/,
  /youtube/,
  /twitter/,
  /whatsapp/,
];

const internalDetectors = [
  /^https:\/\/((www\.)|(stage\.))?smart\./,
  /^\/[^\/\s]+/,
  /localhost/,
  /^\/$/
];

const Link = ({ href, ...rest }) => {
  if (!href) return <span {...rest} />;

  const isSocialDetected = socialDetectors.some((social) => href.match(social));
  const isExternalDetected = !internalDetectors.some((internal) => href.match(internal));
  const isNofollowDetected = href.match(/nidx/);

  const attrs = {};

  switch (true) {
    case isExternalDetected:
      attrs.target = '_blank';
      attrs.rel = 'nofollow';
      break;

    case isNofollowDetected:
      attrs.rel = 'nofollow';
      break;

    case isSocialDetected:
      attrs.target = '_blank';
      attrs.rel = 'me';
      break;
  }

  return <NextLink href={href} {...attrs} {...rest} />;
};

Link.propTypes = {
  href: propTypes.string,
  isSocial: propTypes.bool,
  isNoFollow: propTypes.bool,
  isExternal: propTypes.bool,
};

export default Link;
