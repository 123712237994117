import styled from 'styled-components';

const StyledImageInformation = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  ${({ theme }) => theme.mixins?.layout};

  .title {
    font-size: 124px;
    text-align: center;
    font-family: ${({ theme }) => theme.fonts?.smartSans};

    ${({ theme }) => theme.bps?.mobile} {
      font-size: 40px;
    }
  }

  .model-description {
    width: 800px;
    text-align: center;
    font-size: 21px;
    margin: 34px auto 180px auto;

    ${({ theme }) => theme.bps?.mobile} {
      margin: 34px 0 80px 0;
      width: unset;
      text-align: right;
    }
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  .content {
    display: flex;
    flex-direction: ${({ mediaPosition }) => (mediaPosition === 'left' ? 'row-reverse' : 'unset')};
    gap: 160px;

    .image {
      width: 720px;
      height: 720px;

      ${({ theme }) => theme.bps?.mobile} {
        width: unset;
        height: unset;
      }
    }

    .info-ctr {
      width: 425px;
      ${({ theme }) => theme.bps?.mobile} {
        width: unset;
      }
    }

    .model-title {
      font-family: ${({ theme }) => theme.fonts?.smartSans};
      font-size: 35px;
      margin-bottom: 10px;

      ${({ theme }) => theme.bps?.mobile} {
        font-size: 25px;
      }
    }

    .price-details {
      margin-bottom: 40px;
    }

    .description {
      margin-bottom: 20px;
    }

    .extra-details {
      margin-bottom: 36px;
      p {
        font-size: 21px;
        ${({ theme }) => theme.bps?.mobile} {
          font-size: 13px;
          font-weight: bold;
        }
      }
    }

    ${({ theme }) => theme.bps?.mobile} {
      gap: 36px;
      font-size: 13px;
      flex-direction: column;
    }
  }
`;

export default StyledImageInformation;
