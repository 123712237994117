import styled from 'styled-components';

const StyledModelsCards = styled.div`
  ${({ theme }) => theme.mixins.layout};

  .main-title {
    text-align: center;
    margin-bottom: 100px;
  }

  .models-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 67px;

    ${({ theme }) => theme.bps?.mobile} {
      gap: 20px;
    }

    .model-card {
      ${({ theme }) => theme.bps?.mobile} {
        margin: 0 14px;
      }

      .image {
        width: 767px;
        height: 504px;

        ${({ theme }) => theme.bps?.mobile} {
          width: 339px;
          height: unset;
        }
      }

      .content {
        max-height: 344px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        padding: 46px 66px 36px;
        background-color: ${({ theme }) => theme?.colors?.buttons?.outlinedHover};

        ${({ theme }) => theme.bps?.mobile} {
          padding: 23px 12px;
        }
        
        .model-link {
          cursor: pointer;
          text-decoration: underline;
          display: flex;
          color: ${({ theme }) => theme.colors?.dark};
          font-size: 13px;
          margin-top: 16px;
          font-weight: 600;
        }

        .model-name {
          font-size: 25px;
          font-family: ${({ theme }) => theme.fonts?.smartNext};

          ${({ theme }) => theme.bps?.mobile} {
            font-size: 16px;
          }
        }

        .section-space {
          margin-bottom: 18px;
        }

        .text-size {
          ${({ theme }) => theme.bps?.mobile} {
            font-size: 13px;
          }
        }

        .legal-text {
          margin-top: 30px;
        }

        p,h1,h2,h3,h4,h5,h6  {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
`;

export default StyledModelsCards;
