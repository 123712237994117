import styled from 'styled-components';

const StyledBannerImageTitle = styled.div`
  position: relative;

  .image-banner {
    max-height: 640px;
    width: 100%;

    ${({ theme }) => theme.bps?.mobile} {
      position: relative;
      min-width: 100%;
      max-height: 375px;
      overflow: hidden;
    }
  }

  .title {
    position: absolute;
    top: 35%;
    width: 100%;
    font-size: 131px;
    font-weight: bold;
    text-align: center;
    font-family: ${({ theme }) => theme.fonts?.smartNext};
    color: ${({ theme }) => theme.colors?.white};
    padding: 0 25px;

    ${({ theme }) => theme.bps?.mobile} {
      font-size: 40px;
      line-height: normal;
      padding: 0 33px;
      top: 40%;
    }
  }
`;

export default StyledBannerImageTitle;
