import React, { useState, useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import StyledCustomDropDown from './StyledCustomDropDown';

const CustomDropdown = ({ options, placeholder, setValue = () => {}, value = '', register, id, isError }) => {
  const [selectedOption, setSelectedOption] = useState({ value : '', text : ''});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  useEffect(() => {
    if (value && setValue) {
      setValue(id, value, { shouldValidate: true });
    }
  }, [value]);
  const handleSelectChange = (event) => {
    setSelectedOption({value:  event.target.value, text : event.target.text});
    setIsDropdownOpen(false);
    if (setValue) {
      setValue(id, event.target.value, {shouldValidate: true});
    }
  };
  const toggleDropdown = () => {
    if(isDropdownOpen){
      setIsDropdownOpen(false);
    }else{
      setIsDropdownOpen(false);
    }
    setIsDropdownOpen(!isDropdownOpen);
  };
  const closeDropdownOnOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    window.addEventListener('click', closeDropdownOnOutsideClick);
    return () => {
      window.removeEventListener('click', closeDropdownOnOutsideClick);
    };
  }, []);
  return (
    <StyledCustomDropDown isError={isError} ref={dropdownRef} onClick={() => toggleDropdown()}>
      <div className="header-placeholder">
        <div className='header-placeholder-input'>{selectedOption.text || placeholder}</div>
        <input type='hidden' id={id} name={id} {...register} value={selectedOption.value || ''} />
        <span className={`icon-toggle ${isDropdownOpen && 'toggled'}`} />
      </div>
      {
        (isDropdownOpen) &&
        <ul className="dropdown-options" >
          {options.map((option, index) => (
            <li
              key={option.value}
              className="dropdown-option"
              onClick={() => {
                handleSelectChange({ target: { value: option.value, text: option.text } });
              }}
            >
              {option.text}
              { index !== options.length - 1 && <div className="seperator" /> }
            </li>
          ))}
        </ul>
      }
    </StyledCustomDropDown>
  );
};
CustomDropdown.propTypes = {
  options: propTypes.arrayOf(
    propTypes.shape({
      value: propTypes.string.isRequired,
      text: propTypes.string.isRequired,
    })
  ).isRequired,
  placeholder: propTypes.string,
  register: propTypes.object,
  id: propTypes.string,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  setValue: propTypes.func,
  isError: propTypes.bool
};
export default CustomDropdown;