import styled from 'styled-components';

const StyledModalCarBook = styled.div`
  width: 100%;
  position: relative;
  padding: ${({ isSuccess }) => (isSuccess ? '48px 54px 30px' : '35px 30px 34px 36px')};

  ${({ theme }) => theme.bps?.mobile} {
    padding: ${({ isSuccess }) => (isSuccess ? '45px 0 30px' : '25px 13px 25px 13px')};
  }

  .close-btn {
    position: absolute;
    background: none;
    border: none;
    padding: 0;
    width: 17px;
    height: 17px;
    cursor: pointer;
    z-index: 15;
    left: 38px;
    top: 35px;

    ${({ theme }) => theme.bps?.mobile} {
      left: 22px;
      top: 25px;
    }

    display: ${({ isSuccess }) => (isSuccess ? 'none' : 'inital')};
  }

  .title {
    padding-top: 59px;
    font-size: 25px;
    font-weight: 600;
    line-height: 1.1;
    color: ${({ theme }) => theme.colors?.black};
    margin-bottom: 6px;

    ${({ theme }) => theme.bps?.mobile} {
      padding-top: 15px;
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: 0.2px;
    }
  }

  .subtitle {
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.16px;
    text-align: right;
    color: ${({ theme }) => theme.colors?.black};

    ${({ theme }) => theme.bps?.mobile} {
      font-size: 13px;
      line-height: 1.2;
      letter-spacing: 0.12px;
      max-width: 192px;
    }
  }

  .input-component {
    margin-top: 36px;
    max-width: 100%;

    ${({ theme }) => theme.bps?.mobile} {
      margin-top: 22px;
    }

    .input::placeholder {
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: 0.16px;
      color: ${({ theme }) => theme.colors?.dark};
    }
  }

  .rules {
    font-size: 11px;
    line-height: 1.42;
    letter-spacing: 0.12px;
    text-align: right;
    color: ${({ theme }) => theme.colors?.black};
    margin-top: 35px;

    ${({ theme }) => theme.bps?.mobile} {
      margin-top: 22px;
    }

    .underline {
      text-decoration: underline;
      font-size: 11px;
      line-height: 1.42;
      letter-spacing: 0.12px;
      color: ${({ theme }) => theme.colors?.black};
    }
  }

  .acceptance-input {
    display: flex;
    align-items: flex-start;
    margin-top: 10px;
    gap: 13.5px;

    .label {
      font-size: 11px;
      line-height: 1.42;
      letter-spacing: 0.12px;
      color: ${({ theme }) => theme.colors?.black};

      .sub-label {
        font-size: 11px;
        line-height: 1.42;
        letter-spacing: 0.12px;
        color: ${({ theme }) => theme.colors?.black};
        text-decoration: underline;
      }
    }

    .input-component {
      margin: 0;
      gap: 0;
      .checkmark {
        border-radius: 2px;
        border: solid 1px ${({ theme }) => theme.colors?.black};
        width: 19px;
        height: 19px;
      }
    }
  }

  .loader {
    display: flex;
    justify-content: center;

    .submit-loader {
      svg {
        fill: white;
      }
    }
  }

  .container-button {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 46px;

    ${({ theme }) => theme.bps?.mobile} {
      margin-top: 28px;
    }

    .button-send {
      font-size: 13px;
      font-weight: 600;
      line-height: 1.15;
      height: 53px;
      width: 154px;
      align-items: ${({isSubmitting}) => isSubmitting ? 'center': 'flex-end'};
    }
  }

  .terms {
    padding-top: 59px;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.16px;
    color: ${({ theme }) => theme.colors?.black};
  }

  .container-success {
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-success {
      position: relative;
      width: 72.9px;
      height: 40.6px;
      margin-bottom: 37px;

      ${({ theme }) => theme.bps?.mobile} {
        margin-bottom: 24px;
      }
    }
    .success-label,
    .success-email {
      margin: 0;
      font-size: 16px;
      line-height: 1.4;
      letter-spacing: 0.16px;
      text-align: center;
      color: ${({ theme }) => theme.colors?.black};

      ${({ theme }) => theme.bps?.mobile} {
        font-size: 13px;
        line-height: 1.2;
        letter-spacing: 0.12px;
      }
    }

    .success-btn {
      margin-top: 43px;
      display: flex;
      justify-content: center;

      ${({ theme }) => theme.bps?.mobile} {
        margin-top: 20px;
      }
    }
  }
`;

export default StyledModalCarBook;
