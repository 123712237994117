'use client';

import StyledInputText from './StyledInputText';
import propTypes from 'prop-types';

const Input = ({type = 'text', label = '', isError = true, errorMessage = '', className = 'input-text', registerAttr, ...rest }) => {
  return (
    <StyledInputText className={className} isError={isError}>
      <label className="label-input">{label}</label>
      {type === 'textarea' ? (
        <textarea type="text" className="textarea" {...rest} {...registerAttr} />
      ) : <input type="text" className="input" {...rest} {...registerAttr} /> }
      {isError && <span className="error-message">{errorMessage}</span>}
    </StyledInputText>
  );
};

Input.propTypes = {
  type: propTypes.oneOf(['text', 'radio','textarea']),
  label: propTypes.string,
  isError: propTypes.bool,
  errorMessage: propTypes.string,
  className: propTypes.string,
  registerAttr: propTypes.object
};

export default Input;
