
'use client';

import StyledBulletsText from './StyledBulletsText';
import StyledBulletsTextElm from './StyledBulletTextElm';
import propTypes from 'prop-types';

const ClientBulletsText = ({order, layout, bulletsText}) => {
  const bulletsTextJSX = bulletsText && bulletsText.map(({bullet}, index) => <StyledBulletsTextElm liIndex={(index +1)} key={`bullet-${index}`} className='bullet'>{bullet}</StyledBulletsTextElm>);
  return (
    <StyledBulletsText id={order} $layout={layout}>
      <ul className='bullet-text-ui'>
        {bulletsTextJSX}
      </ul>
    </StyledBulletsText>
  );
};

ClientBulletsText.propTypes = {
  layout: propTypes.object,
  order: propTypes.string,
  bulletsText : propTypes.arrayOf(
    propTypes.shape({
      bullet : propTypes.string
    })
  ),
};

export default ClientBulletsText;
