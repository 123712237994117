import styled from 'styled-components';

const StyledLevelBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 19px;
  height: 19px;
  margin: 0 2.4px;
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.colors?.white};
  font-size: 11px;

  ${({ theme }) => theme.bps?.mobile} {
    margin: 0 1.5px;

    > span {
      font-size: 11px;
      margin: auto;
    }
  }

  > span {
    font-size: 11px;
    line-height: 19px;
    margin: auto;
  }
`;

export default StyledLevelBox;
