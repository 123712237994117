'use client';

import propTypes from 'prop-types';
import BannerImageTitle from '@/UIComponents/BannerImageTitle';
import Breadcrumb from '@/UIComponents/Breadcrumb';
import StyledServiceCenterTemplate from './StyledServiceCenterTemplate';
import ItemInfos from '../ShowroomTemplate/ItemInfos';

const ClientServiceCenterTemplate = ({
  sections,
  breadcrumbs,
  showroomsServicesCentersFields = {},
  serviceCentersList = []
}) => {
  const SERVICE_CENTER_TITLE = 'מרכזי שירות';

  const { imageBanner = {}, titleBanner = '' } = showroomsServicesCentersFields || {};
  const serviceCentersJSX =
    serviceCentersList.length > 0 &&
    serviceCentersList.map(({ showroomServiceCenterInfos, title }, index) => (
      <ItemInfos infos={{ ...showroomServiceCenterInfos, title }} key={index}></ItemInfos>
    ));

  return (
    <StyledServiceCenterTemplate>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <BannerImageTitle image={imageBanner} title={titleBanner} />
      <div className="list-service-centers">
        <h2 className="service-center-title">{SERVICE_CENTER_TITLE}</h2>
        <div className="service-center-list">{serviceCentersJSX}</div>
      </div>
      {sections}
    </StyledServiceCenterTemplate>
  );
};

ClientServiceCenterTemplate.propTypes = {
  sections: propTypes.node,
  breadcrumbs: propTypes.array,
  showroomsServicesCentersFields: propTypes.object,
  serviceCentersList: propTypes.array
};

export default ClientServiceCenterTemplate;
