
'use client';
import StyledImage from './StyledImage';
import propTypes from 'prop-types';
import ImageNext from 'next/image'; 

const Image = ({src, alt, sizes, width = sizes?.width, height = sizes?.height, responsive = false, className='', ...rest}) => {
  // if(alt === undefined) throw new Error('Image component must have alt prop');
  
  const {blurHeight, blurWidth, isAutoplay, isMuted, displayControlsPanel, objectFit, ...restImageProps} = rest || {};
  
  if(!src) return null;
  
  return (
    <StyledImage $sizes={sizes} $responsive={responsive} className={className}>
      <ImageNext alt={alt || ''} src={src} {...responsive ? {fill:true} : {height, width}} sizes={`${width}px`} quality={100} style={objectFit ? {objectFit} : {}} {...restImageProps} />
    </StyledImage>
  );
};

Image.propTypes = {
  src: propTypes.oneOfType([propTypes.string, propTypes.object]),
  alt: propTypes.string,
  sizes: propTypes.object,
  width: propTypes.number,
  height: propTypes.number,
  className: propTypes.string,
  responsive: propTypes.bool
};

export default Image;
