'use client';
import StyledColorViewer from './StyledColorViewer';
import propTypes from 'prop-types';
import {buttonItemProps, MEDIA_POSITION_OPTIONS, responsiveMediaItemPropTypes} from '../../../utils/generalProptypes';
import Button from '@/UIComponents/Button/Button';
import {useEffect, useState} from 'react';
import Video from '@/UIComponents/Video/Video';
import FilledTabs from '@/UIComponents/FilledTabs/FilledTabs';
import useIsMobile from '../../../hooks/useIsMobile';
import Finishes from './Finishes/Finishes';
import Colors from './Colors/Colors';
import {isEmpty} from 'lodash';

const ClientColorViewer = ({ finishes, title, description, mediaPosition, layout, order, buttonFields }) => {
  const isMobile = useIsMobile();

  const [finishColors , setFinishColors] = useState(finishes[0]?.colors?.outsideColors || []);
  const [selectedFinish, setSelectedFinish] = useState(finishes[0]?.finishName);

  const getDefaultColorVideo = () => {
    if (isEmpty(finishColors)) {
      return;
    }

    return !isMobile ? finishColors[0]?.video?.desktop : finishColors[0]?.video?.mobile ? finishColors[0]?.video?.mobile: finishColors[0]?.video?.desktop;
  };

  const [selectedColor, setSelectedColor] = useState(finishes[0]?.colors?.outsideColors[0]);
  const [selectedType, setSelectedType] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState(getDefaultColorVideo());

  useEffect(() => {
    updateFinishColors(selectedFinish);
    setDefaultActiveColor();
  }, [selectedType, selectedFinish]);

  const findFinish = () => {
    const finishData = finishes.find(finish => finish?.finishName === selectedFinish)?.colors;
    return selectedType === 0 ? finishData?.outsideColors : finishData?.insideColors;
  };

  const updateFinishColors = () => {
    const finishColors = findFinish();
    setFinishColors(finishColors);
  };

  const updateFinishName = (finishName) => {
    setSelectedFinish(finishName);
  };

  const updateColor = (color) => {
    const video = !isMobile ? color?.video?.desktop : color?.video?.mobile ? color?.video?.mobile : color?.video?.desktop;
    setSelectedVideo(video);
    setSelectedColor(color);
  };

  const toggleFinishColorsByType = (value) => {
    setSelectedType(value);
  };

  const setDefaultActiveColor = () => {
    const finishColors = findFinish();
    const video = !isMobile ? finishColors[0]?.video?.desktop : finishColors[0]?.video?.mobile ? finishColors[0]?.video?.mobile : finishColors[0]?.video?.desktop;
    setSelectedColor(finishColors[0]);
    setSelectedVideo(video);
  };

  return (
    <StyledColorViewer mediaPosition={mediaPosition} id={order} $layout={layout}>
      <>
        <div className='video-ctr'>
          {title && isMobile && <h3 className="title">{title}</h3>}
          <Video {...selectedVideo} width={840} isFixedHeight height={710} displayFullControls={false}/>
          <FilledTabs items={['חוץ', 'פנים']} onChange={toggleFinishColorsByType} className='tabs'/>
        </div>

        <div className="info-ctr">
          {title && !isMobile && <h3 className="title">{title}</h3>}
          {finishes?.length > 0 && <Finishes finishesArr={finishes} selectedFinish={selectedFinish} updateFinishName={updateFinishName}/>}
          {finishColors?.length > 0 && <Colors finishColors={finishColors} updateColor={updateColor} selectedColor={selectedColor}/>}
          {buttonFields?.label && <Button {...buttonFields} className='btn'>{buttonFields.label}</Button>}
          {description && <div className="description" dangerouslySetInnerHTML={{__html: description}}/>}
        </div>
      </>
    </StyledColorViewer>
  );
};

ClientColorViewer.propTypes = {
  layout: propTypes.object,
  order: propTypes.string,
  title: propTypes.string,
  description: propTypes.string,
  mediaPosition: propTypes.oneOf(MEDIA_POSITION_OPTIONS),
  buttonFields: buttonItemProps,
  finishes: propTypes.arrayOf(propTypes.shape({
    finishName: propTypes.string,
    colors: propTypes.shape({
      insideColors: propTypes.arrayOf(propTypes.shape({
        colorIcon: responsiveMediaItemPropTypes,
        video: responsiveMediaItemPropTypes,
      })),
      outsideColors: propTypes.arrayOf(propTypes.shape({
        colorIcon: responsiveMediaItemPropTypes,
        video: responsiveMediaItemPropTypes,
      })),
    })
  }))
};


export default ClientColorViewer;

