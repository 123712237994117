'use client';
import StyledModelsCards from './StyledModelsCards';
import SectionTitle from '@/UIComponents/SectionTitle';
import propTypes from 'prop-types';
import Image from 'core/image';
import {Button} from '@/UIComponents/Button';
import useIsMobile from '../../../hooks/useIsMobile';
import Link from "core/link";

const modelCard = (model) => {
  const { uri, modelFields: { modelsCards, modelGeneralFields } } = model || {};
  const { imageFields, description, linkText, legalText, extraDetails } = modelsCards || {};
  const { modelName } = modelGeneralFields || {};

  return (
    <div key={modelName} className='model-card'>
      <div className='image'>
        <Image responsive {...imageFields} />
      </div>

      <div className='content'>
        <h3 className='section-space model-name'>{modelName}</h3>
        <div className='section-space text-size' dangerouslySetInnerHTML={{ __html: description }}></div>
        <div className='text-size' dangerouslySetInnerHTML={{ __html: extraDetails }}></div>
        {linkText && <Link href={uri} className='model-link'>{linkText}</Link>}
        <div className='text-size legal-text' dangerouslySetInnerHTML={{ __html: legalText }}></div>
      </div>
    </div>
  );
};


const ClientModelsCards = ({order, layout, title, models }) => {
  const isMobile = useIsMobile();

  return (
    <StyledModelsCards id={order} $layout={layout}>
      {title && !isMobile && <SectionTitle className='main-title'>{title}</SectionTitle>}
      {models?.length > 0 && (
        <div className='models-cards'>
          {models.map((model) => modelCard(model))}
        </div>
      )}
    </StyledModelsCards>
  );
};

ClientModelsCards.propTypes = {
  layout: propTypes.object,
  order: propTypes.string,
  title: propTypes.string,
  models: propTypes.array
};

export default ClientModelsCards;
