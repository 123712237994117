import propTypes from 'prop-types';

export const mediaItemPropTypes = propTypes.shape({
  src: propTypes.string,
  alt: propTypes.string,
  sizes: propTypes.shape({
    width: propTypes.number,
    height: propTypes.number,
  }),
});

const BUTTON_TYPES = ['page_link', 'url', 'action'];
export const VARIANT_TYPES = ['primary', 'outlined', 'disabled', 'text', 'outlinedWhite', 'filledWhite'];
export const MEDIA_POSITION_OPTIONS = ['left', 'right'];

export const buttonItemProps = propTypes.shape({
  label: propTypes.string,
  isNewWindow: propTypes.bool,
  type: propTypes.oneOf(BUTTON_TYPES),
  interiorUrl: propTypes.object,
  exteriorUrl: propTypes.string,
  functionName: propTypes.string,
  variant: propTypes.oneOf(VARIANT_TYPES),
  withIcon: propTypes.bool
});

export const responsiveMediaItemPropTypes = propTypes.shape({
  desktop: mediaItemPropTypes,
  mobile: mediaItemPropTypes,
});
