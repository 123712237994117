'use client';
import Media from '@/UIComponents/Media';
import StyledMediaSection from './StyledMediaSection';
import propTypes from 'prop-types';
import SectionTitle from "@/UIComponents/SectionTitle";
import {buttonItemProps} from "../../../utils/generalProptypes";
import {Button} from "@/UIComponents/Button";

const ClientMediaSection = ({order, layout, isShrink, media, title, description, buttonFields}) => {
  const {type} = media || {};
  return (
    <StyledMediaSection id={order} $layout={layout} isShrink={isShrink}>
      <div className="container">
        <Media type={type} {...media}/>
        {(title || description || buttonFields) && (
            <div className="centered">
              {title && <SectionTitle as='h3' className='title'>{title}</SectionTitle>}
              {description && <p className='description'>{description}</p>}
              {buttonFields?.label && <Button className='btn' {...buttonFields}>{buttonFields.label}</Button>}
            </div>
        )}
      </div>
    </StyledMediaSection>
  );
};

ClientMediaSection.propTypes = {
  layout: propTypes.object,
  order: propTypes.oneOfType([propTypes.string, propTypes.number]),
  isShrink: propTypes.bool,
  media: propTypes.object,
  buttonFields: buttonItemProps,
  title: propTypes.string,
  description: propTypes.string
};

export default ClientMediaSection;
