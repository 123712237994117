'use client';

import StyledGallery from './StyledGallery';
import propTypes from 'prop-types';
import Image from 'core/image';
import useHorizontalScroll from 'hooks/useHorizontalScroll';
import { useRef, useState } from 'react';
import { handleScroll } from '../../../utils/progressHandler';
import SectionTitle from '@/UIComponents/SectionTitle';

const ClientGallery = ({ order, layout, disclaimer, gallery, imagePosition, sectionTitle }) => {
  const containerRef = useRef(null);
  const isDragging = useHorizontalScroll(containerRef);
  const [scrollValue, setScrollValue] = useState(0);

  gallery = gallery.slice(0, 4);
  const isScrollable = gallery.length > 3;

  return (
    <StyledGallery
      id={order}
      $layout={layout}
      isDragging={isDragging}
      imagePosition={imagePosition}
      isScrollable={isScrollable}
    >
      {sectionTitle && (
        <SectionTitle type="h2" className="section-title">
          {sectionTitle}
        </SectionTitle>
      )}
      <div ref={containerRef} onScroll={()=>handleScroll(containerRef,setScrollValue)} className="wrapper-gallery scroll">
        {gallery?.map((item, index) => (
          <div className={`item-block item-block-${index % 3}`} key={index}>
            <Image {...item.image} alt={`gallery-image-${index}`} />
            <div className="details">
              <h3 className="title-image">{item.title}</h3>
              <p className="description">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
      {isScrollable && <progress dir='rtl' max='100' value={scrollValue} className='progress-bar'></progress>}
      <div className="disclaimer">{disclaimer}</div>
    </StyledGallery>
  );
};

ClientGallery.propTypes = {
  layout: propTypes.object,
  order: propTypes.oneOfType([propTypes.string, propTypes.number]),
  disclaimer: propTypes.string,
  gallery: propTypes.array,
  imagePosition: propTypes.any,
  sectionTitle: propTypes.string
};

export default ClientGallery;
