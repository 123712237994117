import styled from 'styled-components';

const StyledHamburgerMenu = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 7px;
  border-radius: 100%;
  background-color: ${({ hamburgerOpen, theme, isSubMenuShown }) =>
    !hamburgerOpen || isSubMenuShown ? 'transparent' : theme.colors?.buttons.disabledPress};
  transition: all 0.05s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 88px;

  ${({ theme }) => theme.bps?.mobile} {
    background-color: unset;
    position: relative;
    z-index: 2;
    right: 19px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors?.buttons.disabledHover};

    ${({ theme }) => theme.bps?.mobile} {
      background-color: unset;
    }
  }

  &:active {
    background-color: ${({ theme }) => theme.colors?.buttons.disabledPress};

    ${({ theme }) => theme.bps?.mobile} {
      background-color: unset;
    }
  }

  > div {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

export default StyledHamburgerMenu;
