export const E_DAYS_HEBREW = {
  sunday: 'א',
  monday: 'ב',
  tuesday: 'ג',
  wednesday: 'ד',
  thursday: 'ה',
  friday: 'ו'
};
export const E_FORMS_TYPES = {
  scheduleAppointment: 'scheduleAppointment',
  lead: 'lead',
  insurance:'insurance',
  buyACarForm: 'buyACarForm',
  finance: 'finance',
  buySpecificCar:'buySpecificCar'
};
