import styled from 'styled-components';

const StyledModalLegal = styled.div`
  position: relative;

  .close-btn {
    position: absolute;
    background: none;
    border: none;
    padding: 0;
    width: 17px;
    height: 17px;
    cursor: pointer;
    z-index: 15;
    left: -17px;
    top: -17px;
  }

  .footer-modal-content {
    padding-top: 40px;
    font-size: 13px;
  }
`;

export default StyledModalLegal;
