export const breakPoints = {
  server: 0,
  mobile: 1,
  desktop: 1279,
};

export const bps = {
  mobile: `@media (max-width: ${breakPoints.desktop}px)`,
  desktop: `@media (min-width: ${breakPoints.desktop}px)`
};

export const layoutHeights = {
  header: 66,
  mobileHeader: 66
};

export const headerHeight = {
  desktop: 136,
  mobile: 111
};

export const minWidth = {
  desktopBig: 1600,
  desktop: 1280,
  mobile: 320,
};

export const maxWidth = {
  desktop: 1280,
  mobile: 1279,
};