import styled from 'styled-components';

const StyledFinishDetails = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  ${({ theme }) => theme.mixins?.layout};

  display: flex;
  flex-direction: ${({ mediaPosition }) => (mediaPosition === 'left' ? 'row-reverse' : 'unset')};
  gap: 160px;

  .title {
    font-family: ${({ theme }) => theme.fonts?.smartSans};
    line-height: 1.2;
  }

  .min-price {
    font-family: ${({ theme }) => theme.fonts?.ploni};
    padding-top: 10px;
    line-height: 1.4;
  }

  .fee {
    font-family: ${({ theme }) => theme.fonts?.ploni};
    font-size: 11px;
    padding-top: 5px;
    line-height: 1.42;
  }

  .button {
    margin-top: 10px;
  }

  .links {
    text-align: center;
    padding-top: 16px;
    cursor: pointer;

    .link {
      font-size: 13px;
      font-family: ${({ theme }) => theme.fonts?.ploni};
      font-weight: 600;
      line-height: 1.15;
      text-align: center;
      margin: 0 15px;
      color: ${({ theme }) => theme.colors?.black};
      border-bottom: 1px solid ${({ theme }) => theme.colors?.black};
    }
  }

  ${({ theme }) => theme.bps?.mobile} {
    gap: 43px;
    flex-direction: column-reverse;

    .info-ctr {
      text-align: center;
    }
  }

  .description {
    margin: 29px 0 68px;

    p {
      font-size: 21px;
    }
  }
`;

export default StyledFinishDetails;
