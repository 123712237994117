const CONSTANTS = {
  TITLE: 'קבלת ספר רכב',
  SUBTITLE: 'נשמח לשלוח לך ספר רכב, אנא הזן את הפרטים הבאים',
  EMAIL_LABEL: 'אימייל*',
  ACCEPTANCE: 'מאשר/ת קבלת פניות שיווקיות, מידע על מבצעים והטבות לרבות בדוא״ל/sms/ווטסאפ',
  ACCEPTANCE2: 'מכלמוביל',
  SEND: 'שלחו לי',
  SUCCESS: 'הספר נשלח בהצלחה לכתובת המייל',
  CLOSE: 'סגור',
  validRegex:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  RECAPTCHA_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_FRONTENT_SITE_KEY,
  EMAIL_ERROR: "אימייל לא תקין",
  SERVER_ERROR: 'אופס.. משהו השתבש',
};

export default CONSTANTS;
