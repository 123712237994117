// !TODO: add proptypes when real data is available
import Image from 'core/image';
import Link from 'core/link';
import { numberWithCommas } from 'utils/text';
import CtaIcon from 'public/icons/circled-arrow-left-white.svg';
import StyledFinishCard from './StyledFinishCard';
import propTypes from 'prop-types';
import SectionTitle from '@/UIComponents/SectionTitle';

const FinishCard = ({ uri, title, ConfigurationPrices, finishFields, showPrice }) => {
  const { vehicletotalpricewvat, vehiclelicensefee } = ConfigurationPrices || {};
  const { finishesCardsImage, finishPriceLabel } = finishFields || {};

  const STARTING_FROM = 'החל מ-';
  const LICENSE_FEE_TEXT = 'בתוספת אגרת רישוי';
  const priceComputed = numberWithCommas(vehicletotalpricewvat, true);
  const startingPriceStr = `${STARTING_FROM}${priceComputed}`;
  const licenseFeeComputed = numberWithCommas(vehiclelicensefee, true);
  const licenseFeeStr = `${LICENSE_FEE_TEXT} ${licenseFeeComputed}`;

  return (
    <StyledFinishCard>
      <Image {...finishesCardsImage} responsive={true} className="finish-card-image" />
      <div className="image-overlay" />
      <div className="content-container">
        <SectionTitle as="h3" className="finish-name">
          {title}
        </SectionTitle>
        {showPrice && vehicletotalpricewvat && (
          <>
            {finishPriceLabel && <div className="price-title">{finishPriceLabel}</div>}
            <SectionTitle as="h3" className="starting-price" variant={'secondary'}>
              *{startingPriceStr}
            </SectionTitle>
            {vehiclelicensefee && <div className="license-fee">{licenseFeeStr}</div>}
          </>
        )}
      </div>
      <Link className="cta-icon" href={uri}>
        <Image src={CtaIcon} alt="לעמוד הדגם" fill={false} />
      </Link>
    </StyledFinishCard>
  );
};

FinishCard.propTypes = {
  title: propTypes.string,
  ConfigurationPrices: propTypes.object,
  finishFields: propTypes.object,
  uri: propTypes.string,
  showPrice: propTypes.bool,
  finishPriceLabel: propTypes.string
};

export default FinishCard;
