import styled from 'styled-components';

const StyledFinishColorsSection = styled.div`
  ${({ theme }) => theme.mixins?.layout};

  .colors-section-main {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .video-wrap {
      width: 100%;
      top: 0;
      right: 0;

      video {
        width: 100%;
      }

      > div {
        width: 100%;
        height: 100%;
        overflow: hidden;

        video {
          width: 100%;
        }
      }
    }

    .top-wrap {
      ${({ theme }) => theme.mixins?.shrink};
      position: absolute;
      color: ${({ theme }) => theme.colors?.white};
      font-family: ${({ theme }) => theme.fonts?.ploni};
      padding-top: 66px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      ${({ theme }) => theme.bps?.mobile} {
        padding-top: 45px;
      }

      h2 {
        direction: ltr;

        ${({ theme }) => theme.bps?.mobile} {
          font-size: 63px;
        }
      }

      .colors-tabs {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 70px;
        padding-top: 5px;

        ${({ theme }) => theme.bps?.mobile} {
          gap: 38px;
        }

        .tab {
          cursor: pointer;
          letter-spacing: 1px;

          &.selected {
            position: relative;

            &:after {
              position: absolute;
              content: '';
              width: 100%;
              height: 1px;
              right: 0;
              bottom: -5px;
              background-color: ${({ theme }) => theme.colors?.white};
            }
          }
        }
      }
    }

    .colors-content {
      backdrop-filter: blur(10px);
      background: hsla(240, 1%, 52%, 0.3);
      bottom: 0;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 100%;
    }
  }

  .colors-ap-safety {
    ${({ theme }) => theme.mixins?.shrink};
    font-size: 11px;
    padding: 14px 0;

    ${({ theme }) => theme.bps?.mobile} {
      padding-bottom: 20px;
    }

    .bottom-text {
      padding-bottom: 13px;
      white-space: pre-wrap;
    }
  }
`;

export default StyledFinishColorsSection;
