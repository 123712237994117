'use client';

import StyledSuccessMessage from './StyledSuccessMessage';
import { CONSTANTS } from '../../Constants';

const SuccessMessage = () => {
  const SUCCESS_TITLE = CONSTANTS.THANK_YOU;
  const MESSAGE_CONTACT = CONSTANTS.SUCCESS_MSG;

  return ( 
    <StyledSuccessMessage className='success'>
      <div className="infos-container">
        <h3 className="infos-container-title">{SUCCESS_TITLE}</h3>
        <h4 className="infos-container-message">{MESSAGE_CONTACT}</h4>
      </div>
    </StyledSuccessMessage>
  );
};


export const dynamic = 'error';
export default SuccessMessage;
