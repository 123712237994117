import propTypes from 'prop-types';
import { ELECTRIC_USAGE, WATT_PER_HOUR, TRAVEL_RANGE, KM, AP_LEVEL_LABEL, ELECTRIC_LABEL} from '../TextConstants';
import {StyledApSafetyTable, StyledCellPollutionDegree} from './StyledApSafetyTable';

const ApSafetyElectricTable = ({ modelData }) => {
  const { title = '', modelFields: { finishes } = []} = modelData || {};
  const flatFinishesList = finishes?.map(item => item?.finish);

  const finishesJSX = flatFinishesList?.map((finish, i) => {
    const {title, finishFields: { airPollutionFields }} = finish || {};
    const {lineElectricDistanceRange, lineElectricUsage, pollutionLevel} = airPollutionFields || {};

    return (
      <tr key={i} className='row table-row'>
        <td className='table-cell'>{title}</td>
        <td className='table-cell bold'>{lineElectricDistanceRange}</td>
        <td className='table-cell bold'>{lineElectricUsage}</td>
        <StyledCellPollutionDegree pollutionLevel={pollutionLevel} className='table-cell pollution-degree'>{pollutionLevel}</StyledCellPollutionDegree>
      </tr>
    )
  });

  return (
    <StyledApSafetyTable>
      <tbody>
      <tr className='header-label'>
        <th colSpan="6">{ELECTRIC_LABEL}</th>
      </tr>
      <tr className='row table-header'>
        <th className='head-cell model-name-label' scope="col">{title}</th>
        <th className="head-cell label-width">{ELECTRIC_USAGE}<br></br>{WATT_PER_HOUR}</th>
        <th className="head-cell label-width">{TRAVEL_RANGE}<br></br>{KM}</th>
        <th className='head-cell pollution-label'>{AP_LEVEL_LABEL}</th>
      </tr>
      {finishesJSX}
      </tbody>
    </StyledApSafetyTable>
  );
};

ApSafetyElectricTable.propTypes = {
  modelData: propTypes.shape({
    id: propTypes.string,
    title: propTypes.string,
    modelFields: propTypes.shape({
      finishes: propTypes.arrayOf(propTypes.shape({
        finish: propTypes.shape({
          finishFields: propTypes.shape({
            airPollutionFields: propTypes.shape({
              lineElectricDistanceRange: propTypes.number,
              lineElectricUsage: propTypes.number,
              pollutionLevel: propTypes.number
            })
          })
        })
      })),
    })
  }),
};

export default ApSafetyElectricTable;
