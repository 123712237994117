import styled from 'styled-components';

const StyledHeroBanner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  .content {
    position: absolute;
    height: 100%;
    border: 0;
    bottom: 0;
    left: 0;
    right: 0;
    flex: 1;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 20%;
    margin-bottom: 50px;

    .title {
      color: ${({ titleColor }) => titleColor};
      font-size: ${({ titleFontSize }) => titleFontSize}px;
      text-shadow: 0 0 50px rgba(0, 0, 0, 0.6);
      font-weight: bold;
      font-family: ${({ theme }) => theme.fonts?.smartNext};
      margin-bottom: 32px;
    }

    .description {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        padding: 0;
        margin: 0;
      }
      span {
        font-size: 25px;
      }
    }

    .price-title,
    .description-banner {
      font-size: 40px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors?.white};

      ${({ theme }) => theme.bps?.mobile} {
        font-size: 16px;
      }
    }

    .license-fee-text {
      font-size: 16px;
      font-weight: normal;
      color: ${({ theme }) => theme.colors?.white};

      ${({ theme }) => theme.bps?.mobile} {
        font-size: 13px;
      }
    }

    .bottom-text {
      ${({ theme }) => theme.mixins?.shrink};
      position: absolute;
      bottom: 25px;
      height: fit-content;
      color: ${({ theme }) => theme.colors?.white};
      padding: 0;
      margin: 0 auto;
      font-size: 16px;
      line-height: 23px;

      & > p {
        padding: 0;
        margin: 0;

        ${({ theme }) => theme.bps?.mobile} {
          font-size: 11px;
          line-height: 16px;
        }
      }
    }
  }

  .background {
    width: 100%;
    height: 100%;

    video {
      width: 100%;
        
    }
  }
    
  .responsive-video {
    width: 100%;
      height: calc(100dvh - 66px);
      

    .btn-wrapper {
      bottom: 97px;

      ${({ theme }) => theme.bps?.mobile} {
        bottom: 118px;
      }
    }
  }

  ${({ theme }) => theme.bps?.mobile} {
    .content {
      padding: 0 14.7%;
      margin-bottom: 35px;

      .title {
        font-size: ${({ fontSizeMobile }) => fontSizeMobile}px;
        margin-bottom: 28px;
        max-width: 180px;
      }
    }
  }

  .btn-transparent {
    background-color: transparent;
    color: #fff;
    border-color: #fff;
  }
`;

export default StyledHeroBanner;
