import styled from 'styled-components';

const StyledFooter = styled.div`
  margin: 0 auto;
  background-color: ${({ theme }) => theme.colors?.dark};
  color: ${({ theme }) => theme.colors?.pressedGray};
  padding-bottom: 34px;

  ${({ theme }) => theme.bps?.mobile} {
    padding-bottom: 54px;
  }

  .container {
    ${({ theme }) => theme.mixins?.shrink};
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.bps?.mobile} {
      margin-left: 24px;
      margin-right: 24px;
    }

    .menu {
      margin-top: 127px;
      margin-bottom: 99px;
      display: flex;
      padding: 0 10px;
      justify-content: space-between;
      width: 72%;

      ${({ theme }) => theme.bps?.mobile} {
        margin-top: 96px;
        margin-bottom: 46px;
        flex-direction: column;
        align-items: center;
        row-gap: 60px;
        text-align: center;
        width: unset;
      }

      .group {
        display: flex;
        flex-direction: column;
        width: 13%;

        ${({ theme }) => theme.bps?.mobile} {
          width: 100%;
          justify-content: center;
          align-items: center;
        }

        .items-list {
          display: flex;
          flex-direction: column;
          gap: 15.2px;
          list-style: none;
          padding: 0;
          margin: 0;

          ${({ theme }) => theme.bps?.mobile} {
            gap: 7.2px;
          }

          .item {
            a {
              font-size: 13px;
              line-height: 1.15;
              text-decoration: none;
              color: ${({ theme }) => theme.colors?.pressedGray};
            }
          }
        }
      }

      .button-updates {
        width: 183px;

        .button-footer {
          background-color: transparent;
          border: 1px solid white;
          align-items: center;
          white-space: nowrap;
        }
      }

      .group:nth-of-type(2) {
        .items-list {
          .item {
            a {
              color: ${({ theme }) => theme.colors?.white};
            }
          }
        }
      }
    }

    .bottom {
      padding-top: 26px;
      border-top: 1px solid ${({ theme }) => theme.colors?.pressedGray};
      display: flex;
      justify-content: space-between;

      ${({ theme }) => theme.bps?.mobile} {
        margin-bottom: 29px;
        align-items: center;
      }

      .colmobil-logo {
        position: relative;
      }

      a,
      span {
        font-size: 14px;
        text-align: right;
      }
    }

    .localisation {
      display: flex;
      align-items: center;
      margin-top: 16px;
      margin-bottom: 22px;
      cursor: pointer;

      ${({ theme }) => theme.bps?.mobile} {
        margin: 0;
      }

      .label {
        margin-right: 6px;
        color: ${({ theme }) => theme.colors?.pressedGray};
        font-size: 12px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.28px;
      }
    }

    .social-links {
      display: flex;
      gap: 16px;
      width: 20%;
      justify-content: end;
      height: 35px;
      align-items: center;

      ${({ theme }) => theme.bps?.mobile} {
        width: 100%;
        gap: 25px;
        justify-content: space-between;
        margin-bottom: 24px;
      }

      .social-link {
        height: 20px;
        width: 28.5px;
        position: relative;

        ${({ theme }) => theme.bps?.mobile} {
          height: initial;
          width: initial;
        }
      }
    }

    .legal-right {
      font-size: 13px;
      line-height: 1.4;
      color: ${({ theme }) => theme.colors?.pressedGray};
      margin-bottom: 8px;

      ${({ theme }) => theme.bps?.mobile} {
        font-size: 11px;
        line-height: 1.42;
        letter-spacing: 0.12px;
      }
    }

    .remarks {
      width: 62.5%;
      font-size: 13px;
      line-height: 1.4;
      p {
        a{
          color: ${({ theme }) => theme.colors?.pressedGray};
          text-decoration: underline;
          &:hover{
            color: ${({ theme }) => theme.colors?.dark2Gray};
          }
        }
      }

      ${({ theme }) => theme.bps?.mobile} {
        max-width: 271px;
        width: 100%;
        font-size: 11px;
        line-height: 1.42;
        letter-spacing: 0.12px;
      }
    }

    .read-more {
      font-size: 13px;
      cursor: pointer;
      display: inline;
      text-decoration: underline;
    }
  }
`;

export default StyledFooter;
