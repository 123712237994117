'use client';
import propTypes from 'prop-types';
import FinishCard from './FinishCard';
import StyledFinishesCardsList from './StyledFinishesCardsList';
import { useRef, useState } from 'react';
import useHorizontalScroll from 'hooks/useHorizontalScroll';
import { handleScroll } from '../../../utils/progressHandler';
import useIsMobile from 'hooks/useIsMobile';

const ClientFinishesCardsList = ({ order, layout, showPrice, bottomText, finishes, currentUri }) => {
  const finishesListWithoutCurrent = finishes?.filter(({finish}) => finish?.uri !== currentUri) || [];
  const finishesCardsRef = useRef(null);
  const isMobile = useIsMobile();
  const isDragging = useHorizontalScroll(finishesCardsRef);
  const [scrollValue, setScrollValue] = useState(0);

  const finishCardsJSX = finishesListWithoutCurrent?.map(({finish = {}}, index) => {
    return <FinishCard key={index} {...finish} showPrice={showPrice} />;
  });

  const isScrollable = finishCardsJSX.length > 3;

  return (
    <StyledFinishesCardsList id={order} $layout={layout} isDragging={isDragging}>
      <div className="finishes-cards-wrap">
        <ul className={`finishes-cards-inner-wrap ${isScrollable ? 'scrollable' : ''}`} ref={finishesCardsRef} onScroll={()=>handleScroll(finishesCardsRef, setScrollValue)} >
          {finishCardsJSX}
        </ul>
      </div>
      {bottomText && <div className="bottom-text" dangerouslySetInnerHTML={{__html: bottomText}} />}
      {!isMobile && isScrollable && <progress dir='rtl' max='100' value={scrollValue} className='progress-bar'></progress>}
    </StyledFinishesCardsList>
  );
};

ClientFinishesCardsList.propTypes = {
  layout: propTypes.object,
  order: propTypes.string,
  finishes: propTypes.array,
  currentUri: propTypes.string,
  bottomText: propTypes.string,
  showPrice: propTypes.bool
};

export default ClientFinishesCardsList;
