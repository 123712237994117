import styled from 'styled-components';

const StyledCheckBox = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  input[type='checkbox'] {
    display: none;
    visibility: hidden;
    height: 0;
    width: 0;
  }

  .check {
    display: flex;
    align-items: center;
    width: 15px;
  }

  .label {
    color: ${({ theme, isDisable }) => isDisable && theme.colors?.disabled};
    margin-right: 9px;
    font-size: 16px;
  }
`;

export default StyledCheckBox;
