
'use client';

import { useEffect } from 'react';
import ColorCircle from './ColorCircle';
import StyledColorsComponent from './StyledColorsComponent';
import propTypes from 'prop-types';

const ColorsComponent = ({colorsDetails, setSelectedVideo, selectedVideo, colorsType, colorsLength}) => {

  useEffect(() => {
    if (colorsDetails && colorsDetails.length > 0) {
      setSelectedVideo(colorsDetails[0].video);
    }
  }, [colorsDetails]);

  const colorsListJSX = colorsDetails?.map((colorItem, index)=>{
    const {colorName, upperColor, bottomColor, video} = colorItem || {};

    return <ColorCircle 
      key={index} 
      colorName={colorName} 
      upperColor={upperColor} 
      bottomColor={bottomColor} 
      video={video} 
      setSelectedVideo={setSelectedVideo} 
      isColorSelected={selectedVideo === video}
    />;
  });

  return (
    <StyledColorsComponent colorsType={colorsType} colorsLength={colorsLength}>
      {colorsListJSX}
    </StyledColorsComponent>
  );
};

ColorsComponent.propTypes = {
  colorsDetails: propTypes.array,
  setSelectedVideo: propTypes.func,
  selectedVideo: propTypes.object,
  colorsType:propTypes.string,
  colorsLength: propTypes.number
};

export default ColorsComponent;
