'use client';
import StyledBulletsCTABanner from './StyledBulletsCTABanner';
import propTypes from 'prop-types';
import { buttonItemProps, mediaItemPropTypes } from '../../../utils/generalProptypes';
import Image from 'core/image';
import { Button } from '@/UIComponents/Button';
import SectionTitle from '@/UIComponents/SectionTitle';
import useIsMobile from "../../../hooks/useIsMobile";

const ClientBulletsCTABanner = ({
  order,
  layout,
  imageTitle,
  title,
  description,
  imageBackground,
  imageBulletsTitle,
  bulletsTitle,
  bulletsText,
  legalStatement,
  bulletsBtnGroup
}) => {
  const isMobile = useIsMobile();

  const bulletsTextJSX =
    bulletsText &&
    bulletsText.map(({ bullet, chosen }, index) => (
      <li key={`bullet-${index}`} className={`bullet${chosen ? ' bold' : ''}`}>
        {bullet}
      </li>
    ));

  const bulletsBtnGroupJSX =
    bulletsBtnGroup &&
    bulletsBtnGroup.map(({ buttonFields }, index) => <Button className='button' key={`bullet-btn-${index}`} {...buttonFields} />);

  return (
    <StyledBulletsCTABanner id={order} $layout={layout}>
      <Image className="title-image" desktop={imageTitle?.image} />
      <SectionTitle className="sec-title">{title}</SectionTitle>
      <div className="sec-description">{description}</div>

      <div className="content-container">
        <div className="bullets-list-container">
          <Image className="title-image" desktop={imageBulletsTitle?.image} />
          <div className="bullets-title" dangerouslySetInnerHTML={{ __html: bulletsTitle }} />
          <ul className="bullet-text-ui">{bulletsTextJSX}</ul>
        </div>

        {!isMobile && <Image className="background-image" desktop={imageBackground?.image} />}
      </div>
      <div className="buttons">
        <div className="buttons-ctr">
          {bulletsBtnGroupJSX}
        </div>
      </div>
      <div className="sec-legal-statement">{legalStatement}</div>
    </StyledBulletsCTABanner>
  );
};

ClientBulletsCTABanner.propTypes = {
  layout: propTypes.object,
  order: propTypes.oneOfType([propTypes.string, propTypes.number]),
  imageTitle: propTypes.shape(mediaItemPropTypes),
  title: propTypes.string,
  description: propTypes.string,
  imageBackground: propTypes.shape(mediaItemPropTypes),
  imageBulletsTitle: propTypes.shape(mediaItemPropTypes),
  bulletsTitle: propTypes.string,
  bulletsText: propTypes.arrayOf(
    propTypes.shape({
      bullet: propTypes.string,
      chosen: propTypes.bool
    })
  ),
  bulletsBtnGroup: propTypes.arrayOf(buttonItemProps),
  legalStatement: propTypes.string
};

export default ClientBulletsCTABanner;
