import { styled } from 'styled-components';

const StyledContactForm = styled.div`
  display: flex;
  align-items: center;
  form {
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 5px;
    justify-content: space-between;
    .button-wrapper {
      padding-top: 32px;
      width: 100%;
      display: flex;
      justify-content: center;
      button {
        position: relative;
      }
      ${({ theme }) => theme.bps?.mobile} {
        .loader {
          padding: 0;
        }
      }

      .loader {
        position: absolute;
        display: flex;
        height: 100%;
        top: 0;
      }
      .button-text {
        font-size: 13px;
        font-weight: 600;
        &.submitting {
          opacity: 0;
        }
      }
    }
    .error {
      visibility: hidden;
      color: red;
      margin-top: 5px;
      font-size: 9px;
      line-height: 1.2;
      text-align: right;
      color: ${({ theme }) => theme.colors?.error};
      &.show {
        visibility: visible;
      }
    }
    .legal-remark {
      font-size: 11px;
    }
    .input,
    .textarea,
    .header-placeholder .header-placeholder-input {
      background-color: transparent;
    }
  }
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
  }
`;
export default StyledContactForm;
