'use client';
import StyledApSafetyTemplate from './StyledApSafetyTemplate';
import propTypes from 'prop-types';
import HeroBanner from "@/UIComponents/HeroBanner";
import ApSafetyTable from "@/Templates/ApSafetyTemplate/ApSafetyTable";
import ApSafetyRules from "@/Templates/ApSafetyTemplate/ApSafetyRules";

const ClientApSafetyTemplate = ({ sections, heroBannerFields, models, airPollutionDescription, airPollutionComments }) => {

  const {title, titleColor, fontSize, fontSizeMobile, mediafields: mediaFields, layout} = heroBannerFields || {};
  const modelsList = models?.nodes.map(model => model) || [];

  return (
    <StyledApSafetyTemplate $layout={layout}>
      <HeroBanner
        title={title}
        titleColor={titleColor}
        fontSize={fontSize}
        fontSizeMobile={fontSizeMobile || fontSize}
        mediafields={mediaFields}
      />

      <div className="ctr">
        {airPollutionDescription && <div className="description" dangerouslySetInnerHTML={{ __html: airPollutionDescription }} />}
        {modelsList?.length > 0 && modelsList?.map((modelData, index) => <ApSafetyTable key={index} modelData={modelData}/>)}
        <ApSafetyRules airPollutionComments={airPollutionComments}/>
      </div>

      {sections}
    </StyledApSafetyTemplate>
  );
};

ClientApSafetyTemplate.propTypes = {
  sections: propTypes.node,
  heroBannerFields: propTypes.object,
  models: propTypes.object,
  airPollutionDescription: propTypes.string,
  airPollutionComments: propTypes.string
};

export default ClientApSafetyTemplate;
