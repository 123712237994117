import styled from 'styled-components';
const StyledAdvertisingAllowanceComponent = styled.div`
  .float-label {
    white-space: break-spaces;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: ${({ theme }) => theme.colors?.black};
    ${({ theme }) => theme.bps?.mobile} {
      font-size: 15px;
      white-space: normal;
      width: auto;
      .checkbox-item {
        margin-right: 0;
      }
    }
    .lega-remark {
      font-size: 14px;
      text-align: right;
      color: ${({ theme }) => theme.colors?.black};
      margin: 31px 0 8px 0;
    }
    .advertising-allowance-label-link {
      white-space: nowrap;
      font-size: 14px;
    }
    .checkbox-item {
      display: inline-block;
      position: relative;
      width: 90%;
      ${({ theme }) => theme.bps?.mobile} {
        width: 100%;
      }
      & > label {
        align-items: flex-start;
        .check {
          width: 20px;
        }
        .label {
          font-size: 11px;
          font-family: ${({ theme }) => theme.fonts?.text};
        }
      }
    }
  }
  .clickable-crop-icons-text {
    font-size: 11px;
    text-decoration: underline;
  }
  .clickable-crop-icons-text:hover {
    font-weight: bold;
  }
`;
export default StyledAdvertisingAllowanceComponent;
