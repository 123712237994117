export const FORM_TEMPLATES = {
  basic: 'basic',
  basicWithModel: 'basicWithModel',
  withShowRoom: 'withShowRoom',
  basicWithComment: 'basicWithComment',
  basicWithLicense: 'basicWithLicense'
};

export const CONSTANTS = {
  DISCLAIMER_TEXT: 'למדיניות הפרטיות',
  DISCLAIMER_APPROVE: 'מאשר/ת קבלת פניות שיווקיות, מידע על מבצעים והטבות לרבות בדוא״ל/sms /ווטסאפ ',
  COLMOBIL_CORP: 'מכלמוביל',
  DATA_PRIVACY_REMARK: 'ידוע לי שהפרטים שמסרתי לעיל ייכללו במאגרי המידע של כלמוביל בהתאם למדיניות הפרטיות',
  THANK_YOU: '!Thank you',
  SUCCESS_MSG: `פנייתך התקבלה, נהיה בקשר בקרוב!
  אפשר לדבר איתנו גם בטלפון או ב-WhatsApp`
};
