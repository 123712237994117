import styled from 'styled-components';

const StyledModelsListItem = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 52px;
  justify-content: space-between;

  .model-content {
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0;
      margin: 0;
    }

    .text-space {
      margin-bottom: 14px;
    }

    .title {
      font-weight: bold;
      color: ${({ theme }) => theme.colors?.dark};
      display: block;
    }

    .description {
      max-width: 324px;
    }

    .model-link {
      cursor: pointer;
      text-decoration: underline;
      display: flex;
      color: ${({ theme }) => theme.colors?.dark};
    }
  }

  .model-image {
    height: 85px;
    width: 372px;
  }
`;

export default StyledModelsListItem;
