import propTypes from 'prop-types';
import StyledLevelBox from './StyledLevelBox';

const LevelBox = ({ level = 1, color = '' }) => {
  if (!level || Array.isArray(level)) return null;

  return <StyledLevelBox color={color}><span>{level}</span></StyledLevelBox>;
};

LevelBox.propTypes = {
  level: propTypes.oneOfType([propTypes.number, propTypes.array]).isRequired,
  color: propTypes.string
};

export default LevelBox;
