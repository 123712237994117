import styled from 'styled-components';

const StyledBulletsCTABanner = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  ${({ theme }) => theme.mixins?.layout};
  text-align: center;

  ${({ theme }) => theme.bps?.desktop} {
    width: 852px;
  }

  .title-image {
    justify-content: center;
  }

  .sec-title {
    font-size: 27px;
    font-weight: bold;

    ${({ theme }) => theme.bps?.mobile} {
      font-size: 16px;
      margin-top: 15px;
    }
  }

  .sec-description {
    font-size: 21px;
    font-weight: 500;

    ${({ theme }) => theme.bps?.mobile} {
      font-size: 13px;
    }
  }

  .content-container {
    display: flex;
    justify-content: center;
    padding-top: 36px;

    ${({ theme }) => theme.bps?.mobile} {
      padding-top: 40px;
    }

    .bullets-list-container {
      width: 50%;
      border: solid 2px ${({ theme }) => theme.colors?.oliveBright};
      padding-top: 36px;

      ${({ theme }) => theme.bps?.mobile} {
        width: 100%;
      }

      .bullets-title {
        font-size: 24px;

        p {
          margin: 0;
          padding: 0;
          font-size: 24px;

          ${({ theme }) => theme.bps?.mobile} {
            font-size: 16px;
          }
        }
      }

      .bullet-text-ui {
        list-style: none;
        display: block;
        padding: 2px 89px;
        text-align: right;

        ${({ theme }) => theme.bps?.mobile} {
          padding: 2px 58px;
        }

        li {
          margin: 15px 0;

          ${({ theme }) => theme.bps?.mobile} {
            font-size: 13px;
          }

          &.bold {
            font-weight: 600;
          }

          &:before {
            content: '';
            background: ${({ theme }) => theme.colors?.black};
            width: 7px;
            height: 7px;
            border-radius: 50%;
            position: relative;
            left: 20px;
            top: -2px;
            display: inline-block;
          }
        }
      }
    }
  }

  .background-image {
    padding-top: 36px;
    width: 50%;
  }

  .buttons {
    padding: 45px 0;
    display: flex;
    justify-content: center;
    width: 50%;

    ${({ theme }) => theme.bps?.mobile} {
      width: 100%;
    }

    .buttons-ctr {
      display: flex;
      width: 70%;

      ${({ theme }) => theme.bps?.mobile} {
        width: 100%;
        display: unset;
      }
    }

    .button {
      flex-grow: 1;

      ${({ theme }) => theme.bps?.mobile} {
        &:nth-child(1) {
          margin-bottom: 12px;
        }
      }

      ${({ theme }) => theme.bps?.desktop} {
        &:nth-child(2) {
          margin-right: 20px;
        }
      }
    }
  }

  .sec-legal-statement {
    font-size: 11px;
    line-height: 1.42;
    text-align: right;
    margin-bottom: 5px;
  }
`;

export default StyledBulletsCTABanner;
