
import { Bars } from 'react-loader-spinner';
import propTypes from 'prop-types';

const Loader = ({visible, height = 30, width = 30, color = 'black' }) => {
  return (
    <div className='loader'>
      <Bars
        height={height}
        width={width}
        color={color}
        wrapperClass="submit-loader"
        visible={visible}
        ariaLabel="bars-loading"
      />
    </div>
  );
};

export default Loader;


Loader.propTypes = {
  visible: propTypes.bool,
  height: propTypes.number,
  width: propTypes.number,
  color : propTypes.string
};


