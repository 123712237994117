import styled from 'styled-components';

const StyledModelsMenu = styled.div`
  .menu-button {
    cursor: pointer;
    top: 26px;
    color: #fff;
    display: inline-flex;
    justify-content: center;
    position: absolute;
    right: 160px;
    align-items: center;

    .text {
      margin-left: 6px;
    }

    .arrow-down {
      border: solid #fff;
      border-width: 0 2px 2px 0;
      display: inline-flex;
      padding: 4px;
      transform: rotate(${({ isModelsMenuOpen }) => (isModelsMenuOpen ? '-135deg' : '45deg')});
      align-self: ${({ isModelsMenuOpen }) => (isModelsMenuOpen ? 'unset' : 'baseline')};
    }
  }

  .menu {
    display: flex;
    position: absolute;
    top: 66px;
    right: 0;
    flex-direction: row;
    width: 100%;
    height: ${({ isModelsMenuOpen }) => (isModelsMenuOpen ? '340px' : '0')};
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors?.veryLightGray};
    gap: 108px;
    transition: all 0.5s ease-in-out;
  }
`;

export default StyledModelsMenu;
