'use client';

import propTypes from 'prop-types';
import BannerImageTitle from '@/UIComponents/BannerImageTitle';
import Breadcrumb from '@/UIComponents/Breadcrumb';
import StyledShowroomTemplate from './StyledShowroomTemplate';
import ItemInfos from './ItemInfos';

const ClientShowroomTemplate = ({ sections, breadcrumbs, showroomsServicesCentersFields = {}, showroomsList = [] }) => {
  const SHOWROOM_TITLE = 'אולמות תצוגה';

  const { imageBanner = {}, titleBanner = '' } = showroomsServicesCentersFields || {};
  const showroomsJSX =
    showroomsList?.length > 0 &&
    showroomsList.map(({ showroomServiceCenterInfos, title }, index) => (
      <ItemInfos infos={{ ...showroomServiceCenterInfos, title }} key={index}></ItemInfos>
    ));

  return (
    <StyledShowroomTemplate>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <BannerImageTitle image={imageBanner} title={titleBanner} />
      <div className="list-showroom">
        <h2 className="showroom-title">{SHOWROOM_TITLE}</h2>
        <div className="showroom-list">{showroomsJSX}</div>
      </div>
      {sections}
    </StyledShowroomTemplate>
  );
};

ClientShowroomTemplate.propTypes = {
  sections: propTypes.node,
  breadcrumbs: propTypes.array,
  showroomsServicesCentersFields: propTypes.object,
  showroomsList: propTypes.array
};

export default ClientShowroomTemplate;
