import styled from 'styled-components';

const StyledColorCircle = styled.div`
  .color-circle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    ${({ theme }) => theme.bps?.mobile} {
      position: initial;
    }

    .color-name {
      position: absolute;
      top: -30px;
      margin: 0 auto;
      display: ${({ isColorSelected }) => (isColorSelected ? 'block' : 'none')};
      color: ${({ theme }) => theme.colors?.white};
      font-size: 15px;
      left: 50%;
      transform: translateX(-50%);
      white-space: nowrap;

      ${({ theme }) => theme.bps?.mobile} {
        top: 5px;
      }
    }

    .circle-wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;
      cursor: pointer;

      .upper {
        position: relative;
        width: 100%;
        height: 50%;
        border-radius: 24px 24px 0 0;
        background-color: ${({ upperColor }) => upperColor && upperColor};

        &:before {
          position: absolute;
          content: '';
          top: -10px;
          height: calc(100% + 10px);
          width: calc(100% + 20px);
          left: -10px;
          background-color: ${({ upperColor }) => upperColor && `${upperColor}70`};
          border-radius: 34px 34px 0 0;
          opacity: ${({ isColorSelected }) => (isColorSelected ? '1' : '0')};
          z-index: -1;
          transition: all 0.4s ease-in-out;
        }

        &:after {
          position: absolute;
          content: '';
          width: 100%;
          height: 1px;
          bottom: 0;
          right: 0;
          background-color: ${({ upperColor, bottomColor }) =>
            upperColor === bottomColor ? upperColor : 'transparent'};
          filter: invert(60%);
        }
      }

      .bottom {
        position: relative;
        width: 100%;
        height: 50%;
        background-color: ${({ bottomColor }) => bottomColor && bottomColor};
        border-radius: 0 0 24px 24px;

        &:after {
          position: absolute;
          content: '';
          bottom: -10px;
          height: calc(100% + 10px);
          width: calc(100% + 20px);
          left: -10px;
          background-color: ${({ bottomColor }) => bottomColor && `${bottomColor}70`};
          border-radius: 0 0 34px 34px;
          opacity: ${({ isColorSelected }) => (isColorSelected ? '1' : '0')};
          z-index: -1;
          transition: all 0.4s ease-in-out;
        }
      }
    }
  }
`;

export default StyledColorCircle;
