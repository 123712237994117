'use client';

import { useRouter } from 'next/navigation';
import Image from '@/Core/Image/Image';
import errorIcon from 'public/icons/error-icon.svg';
import StyledErrorMessage from './StyledErrorMessage';

const ErrorMessage = () => {
  const router = useRouter();
  const ERROR_TITLE = 'אופס.. משהו השתבש';
  const CANCEL = 'בטל';
  const handleClick = () => router.refresh();

  return (
    <StyledErrorMessage className='error-message'>
      <div className="icon-error">
        <Image {...errorIcon} width={58} height={34} alt='icon-error' responsive={false} />
      </div>
      <div className="infos-container">
        <h3 className="infos-container-title">{ERROR_TITLE}</h3>
      </div>
      <div className="btn-links" onClick={handleClick}>
        {CANCEL}
      </div>
    </StyledErrorMessage>
  );
};

export default ErrorMessage;
