'use client';
import StyledImageInformation from './StyledImageInformation';
import SectionTitle from '@/UIComponents/SectionTitle';
import propTypes from 'prop-types';
import Image from 'core/image';
import {responsiveMediaItemPropTypes} from '../../../utils/generalProptypes';

const ClientImageInformation = ({ imageFields, title, description, mediaPosition, layout, order, priceDetails, extraDetails, legalText, sectionTitle, modelDescription }) => {
  
  return (
    <StyledImageInformation mediaPosition={mediaPosition} id={order} $layout={layout}>
      <SectionTitle as='h2' className="title" variant="secondary">{sectionTitle}</SectionTitle>
      <p className='model-description' dangerouslySetInnerHTML={{__html: modelDescription}}></p>

      <div className='content'>
        <div className='image'>
          <Image responsive {...imageFields} />
        </div>

        <div className="info-ctr">
          {title && <h3 className="model-title">{title}</h3>}
          {priceDetails && <p className='price-details' dangerouslySetInnerHTML={{__html: priceDetails}}></p>}
          {description && <div className="description" dangerouslySetInnerHTML={{__html: description}}/>}
          {extraDetails && <p className='extra-details' dangerouslySetInnerHTML={{__html: extraDetails}}></p>}
          {legalText && <p dangerouslySetInnerHTML={{__html: legalText}}></p>}
        </div>
      </div>

    </StyledImageInformation>
  );
};

ClientImageInformation.propTypes = {
  layout: propTypes.object,
  imageFields: responsiveMediaItemPropTypes,
  order: propTypes.string,
  title: propTypes.string,
  description: propTypes.string,
  mediaPosition: propTypes.string,
  priceDetails: propTypes.string,
  extraDetails: propTypes.string,
  legalText: propTypes.string,
  sectionTitle: propTypes.string,
  modelDescription: propTypes.string
};

export default ClientImageInformation;
