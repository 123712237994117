'use client';

import styled from 'styled-components';

const StyledLayout = styled.div`
  margin: 0 auto;
  padding-top: ${({ isAuthenticated }) => (isAuthenticated ? 35 : 0)}px;
  min-width: ${({ theme }) => theme.minWidth?.desktop}px;

  ${({ theme }) => theme.bps?.mobile} {
    min-width: ${({ theme }) => theme.minWidth?.mobile}px;
    max-width: ${({ theme }) => theme.maxWidth?.mobile}px;
    overflow-x: auto;
  }

  main {
    min-width: ${({ theme }) => theme.minWidth?.desktop}px;

    ${({ theme }) => theme.bps?.mobile} {
      margin: ${({ theme }) => theme.layoutHeights?.mobileHeader}px auto auto;
      min-width: ${({ theme }) => theme.minWidth?.mobile}px;
      max-width: ${({ theme }) => theme.maxWidth?.mobile}px;
      padding: 0;
    }

    ${({ styles }) => styles && { ...styles }};
  }
`;

export default StyledLayout;
