import styled from 'styled-components';

const StyledApSafety = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  ${({ theme }) => theme.mixins?.layout};

  table tr td.text-center.data-cell,
  .content .spec-wrap .submodels-names {
    direction: ltr;
  }
`;

export default StyledApSafety;
