import propTypes from 'prop-types';
import { AIR_POLLUTION_COMMENT, AIR_POLLUTION_MAX, AIR_POLLUTION_MIN } from '../TextConstants';
import StyledApSafetyRules, {StyledColoredBox} from './StyledApSafetyRules';

const ApSafetyRules = ({ airPollutionComments }) => {

  const arrayPollutionDegrees = Array.from({length: 15}, (_, i) => i + 1);
  const ColoredBoxesJSX = arrayPollutionDegrees?.map((degree, index) => <StyledColoredBox key={index} pollutionDegree={degree}>{degree}</StyledColoredBox>);

  return (
    <StyledApSafetyRules>
      <p>{AIR_POLLUTION_COMMENT}</p>

      <div className='infection-level'>
        <div className='air-pol-max'>{AIR_POLLUTION_MAX}</div>
        <div className='container-boxes'>
          {ColoredBoxesJSX}
        </div>
        <div className='air-pol-min'>{AIR_POLLUTION_MIN}</div>
      </div>

      {airPollutionComments && <p className='air-pollution-comments' dangerouslySetInnerHTML={{__html: airPollutionComments}}></p>}
    </StyledApSafetyRules>
  );
};

ApSafetyRules.propTypes = {
  airPollutionComments: propTypes.string
};

export default ApSafetyRules;
