'use client';

import propTypes from 'prop-types';
import StyledPopupPrivacyCookies from './StyledPopupPrivacyCookies';
import { Button } from '@/UIComponents/Button';
import closeIconWhite from 'public/icons/close-white.svg';
import useIsMobile from 'hooks/useIsMobile';
import Link from 'core/link';
import Image from 'core/image';
import { setCookie, hasCookie } from 'cookies-next';
import { useState, useEffect } from 'react';

const ClientPopupPrivacyCookies = ({ popupCookies = {} }) => {
  const { privacyLink, privacyText, text } = popupCookies || {};
  const isMobile = useIsMobile();
  const [isPrivacyCookies, setIsPrivacyCookies] = useState(false);
  const setPrivacyCookies = () => {
    setCookie('smart-privacy-cookies', 'true');
    setIsPrivacyCookies(true);
  };
  
  useEffect(() => {
    if (hasCookie('smart-privacy-cookies') && !isPrivacyCookies) {
      setIsPrivacyCookies(true);
    }
  }, []);

  return (
    <StyledPopupPrivacyCookies isPrivacyCookies={isPrivacyCookies}>
      <p className="text">
        {text}{' '}
        <Link href={privacyLink} className="privacy-text">
          {privacyText}
        </Link>
      </p>
      {isMobile ? (
        <Image {...closeIconWhite} className="close-button" onClick={setPrivacyCookies} />
      ) : (
        <Button label="סגור" className="button" onClick={setPrivacyCookies} />
      )}
    </StyledPopupPrivacyCookies>
  );
};

ClientPopupPrivacyCookies.propTypes = {
  popupCookies: propTypes.shape({
    text: propTypes.string.isRequired,
    privacyText: propTypes.string.isRequired,
    privacyLink: propTypes.string.isRequired
  })
};

export default ClientPopupPrivacyCookies;
