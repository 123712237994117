import colors from './colors';
import mixins from './mixin';
import { bps, layoutHeights, maxWidth, minWidth } from './layout';
import * as fonts from '../fonts';

const fontsVars = Object.entries(fonts).reduce((acc, [key, value]) => ({...acc, [key]: value.style.fontFamily}), {});

const theme = {
  bps,
  layoutHeights,
  maxWidth,
  minWidth,
  colors,
  mixins,
  fonts: fontsVars,
  transitions: {
    default: '0.3s ease-in-out',
    fast: '0.1s ease-in-out',
  },
};

export default theme;