import styled from 'styled-components';

const StyledBulletsTextElm = styled.li`
  padding-left: 1em;
  text-indent: -1em;
  font-size: 11px;
  margin: 15px 0;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  text-align: right;
  letter-spacing: 0.01px;
  ${({ theme }) => theme.bps?.mobile} {
    margin: 8px 0;
  }
  &:before {
    content: '${({ liIndex }) => liIndex}. ';
    font-size: 11px;
    font-weight: 600;
  }
`;

export default StyledBulletsTextElm;
