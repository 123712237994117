import { css } from 'styled-components';

const shrink = css`
  width: unset;
  max-width: ${({ theme }) => theme.maxWidth?.desktop}px;
  margin: 0 auto;
  height: 100%;
  right: 0;
  left: 0;

  ${({ theme }) => theme.bps?.mobile} {
    margin-left: 15px;
    margin-right: 15px;
  }
`;


const mixins = {
  shrink,
  absoluteShrink: css`
      ${shrink}
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
    `,
  
  layout: css`  ${({ $layout, theme }) => css`
    margin-bottom: ${$layout?.marginBottom?.desktop || 0}px;
    ${theme.bps?.mobile} {
      margin-bottom: ${$layout?.marginBottom?.mobile || 0}px;
    }
  `};
  `,

  media: {
    dynamicHeight: ({ ratio = 1, desktop = true, mobile = false } = {}) => {
      const props = css`
          width: 100%;
          aspect-ratio: 16 / ${3 + ratio * 1.5};
        `;
  
      if (desktop && mobile) return props;
      const media = desktop ? 'desktop' : 'mobile';
  
      return css`
          ${({ theme }) => theme.bps[media]} {
            ${props}
          }
        `;
    }
  },
};

export default mixins;