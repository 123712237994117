import styled from 'styled-components';

const StyledInputRadio = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  position: relative;
  width: fit-content;
  cursor: pointer;

  .checkmark {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: ${({ theme, isChecked }) => (isChecked ? theme.colors?.focusBlue : theme.colors?.white)};
    border: 1px solid ${({ theme }) => theme.colors?.focusBlue};
  }

  ${({ isError, theme }) =>
    isError &&
    `
    .checkmark {
      position:relative;
        opacity: 1;
        background-color: initial;
        border: none;
    }
    .checkmark:before, .checkmark:after {
        position: absolute;
        left: 5px;
        content: ' ';
        height: 15px;
        width: 2px;
        background-color: ${theme.colors?.error};
    }
    .checkmark:before {
        transform: rotate(45deg);
    }
    .checkmark:after {
        transform: rotate(-45deg);
    }
  `}
`;

export default StyledInputRadio;
