'use client';
import propTypes from 'prop-types';
import StyledBannerImageTitle from './StyledBannerImageTitle';
import Media from '../Media';

const BannerImageTitle = ({image = {}, title = ''}) => {

  return (
    <StyledBannerImageTitle>
      <div className='image-banner'>
        <Media {...image} responsive/>
      </div>
      <h1 className='title'>{title}</h1>
    </StyledBannerImageTitle>
  );
};

BannerImageTitle.propTypes = {
  image: propTypes.object,
  title: propTypes.string
};

export default BannerImageTitle;
