import styled from 'styled-components';

const StyledImageInformationLegal = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  ${({ theme }) => theme.mixins?.layout};

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  .content {
    display: flex;
    flex-direction: ${({ mediaPosition }) => (mediaPosition === 'left' ? 'row-reverse' : 'unset')};
    gap: 160px;

    ${({ theme }) => theme.bps?.mobile} {
      gap: 34px;
      font-size: 13px;
      flex-direction: column-reverse;
    }

    .image {
      width: 720px;
      height: 720px;

      ${({ theme }) => theme.bps?.mobile} {
        width: unset;
        height: unset;
        margin-bottom: 43px;
      }
    }

    .info-ctr {
      width: 430px;

      ${({ theme }) => theme.bps?.mobile} {
        width: unset;
      }

      .title {
        font-size: 40px;
        margin-bottom: 28px;
        font-weight: bold;

        ${({ theme }) => theme.bps?.mobile} {
          font-size: 20px;
        }
      }

      .description {
        margin-bottom: 68px;

        ${({ theme }) => theme.bps?.mobile} {
          font-size: 13px;
          margin-bottom: 36px;
        }
      }

      .details {
        display: flex;
        gap: 30px;

        ${({ theme }) => theme.bps?.mobile} {
          display: block;
        }

        .detail {
          display: flex;
          flex-direction: column;

          ${({ theme }) => theme.bps?.mobile} {
            margin-bottom: 21px;
          }

          .detail-title {
            font-weight: 600;
            font-size: 40px;

            ${({ theme }) => theme.bps?.mobile} {
              font-size: 25px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
`;

export default StyledImageInformationLegal;
