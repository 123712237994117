'use client';
import StyledFinishTemplate from './StyledFinishTemplate';
import Breadcrumb from '@/UIComponents/Breadcrumb';
import propTypes from 'prop-types';
import HeroBanner from '@/UIComponents/HeroBanner';
import SafetyAndPollutionStrip from '@/UIComponents/SafetyAndPollutionStrip';
import { numberWithCommas } from '../../../utils/text';

const ClientFinishTemplate = ({
  breadcrumbs,
  sections,
  title,
  titleColor,
  fontSize,
  fontSizeMobile,
  mediaFields,
  safetyLevel = [],
  pollutionLevel = [],
  price,
  vehiclelicensefee,
  layout,
  priceTitle,
  underBannerText,
  finishImage,
  modelName,
  finishPath,
  seoDescription,
  finishName
}) => {
  const descriptionJSX = price ? (
    <>
      <h2 className="price-title">{priceTitle}</h2>
      <h2 className="description-banner" style={{ color: titleColor }}>
        החל מ-{numberWithCommas(price, true)}*
      </h2>
      <span className="license-fee-text">בתוספת אגרת רישוי על סך-₪{vehiclelicensefee}</span>
    </>
  ) : null;

  const createStructuredSchemaData = () => {
    let originPath = process.env.NEXT_PUBLIC_FRONTEND_URL || '';
    if (typeof window !== 'undefined') {
      originPath = window ? window?.location?.origin : '';
    }

    return {
      '@context': 'https://schema.org/',
      '@type': 'Car',
      name: `${modelName} ${finishName}`,
      image: finishImage,
      description: seoDescription,
      vehicleModelDate: new Date().getFullYear(),
      brand: {
        '@type': 'Brand',
        name: 'smart - סמארט'
      },
      model: modelName,
      fuelType: 'חשמלי',
      vehicleTransmission: 'תיבת הילוכים אוטומטית',
      itemCondition: 'https://schema.org/NewCondition',
      vehicleIdentificationNumber: 'New Vehicle Offer',
      numberOfPreviousOwners: 0,
      mileageFromOdometer: {
        '@type': 'QuantitativeValue',
        value: '20000'
      },
      manufacturer: { '@type': 'Organization', name: 'smart' },
      offers: {
        '@type': 'Offer',
        url: `${originPath}${finishPath?.slice(0, -1)}`,
        priceCurrency: 'ILS',
        price: price || 0,
        availability: 'https://schema.org/InStock',
        itemCondition: 'https://schema.org/NewCondition'
      }
    };
  };

  return (
    <StyledFinishTemplate $layout={layout}>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="space-layout">
        <HeroBanner
          title={title}
          titleColor={titleColor}
          fontSize={fontSize}
          fontSizeMobile={fontSizeMobile || fontSize}
          mediafields={mediaFields}
          descriptionJSX={descriptionJSX}
          underBannerText={underBannerText}
        />
        <div className="shrink">
          <SafetyAndPollutionStrip
            safetyLevels={[safetyLevel]}
            pollutionLevels={[pollutionLevel]}
            showMoreDetailsLink
            brand="smart"
          />
        </div>
      </div>
      {sections}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(createStructuredSchemaData()) }}
      />
    </StyledFinishTemplate>
  );
};
ClientFinishTemplate.propTypes = {
  breadcrumbs: propTypes.array,
  mediaFields: propTypes.object,
  title: propTypes.string,
  titleColor: propTypes.string,
  safetyLevel: propTypes.number,
  pollutionLevel: propTypes.number,
  price: propTypes.number,
  fontSize: propTypes.number,
  fontSizeMobile: propTypes.number,
  sections: propTypes.array,
  layout: propTypes.object,
  modelName: propTypes.string,
  seoDescription: propTypes.string,
  finishImage: propTypes.string,
  pageTitle: propTypes.string,
  finishPath: propTypes.string,
  priceTitle: propTypes.string,
  underBannerText: propTypes.string,
  finishName: propTypes.string,
  vehiclelicensefee: propTypes.number
};

export default ClientFinishTemplate;
