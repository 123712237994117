'use client';

import StyledInformationBannerTopText from './StyledInformationBannerTopText';
import SectionTitle from '@/UIComponents/SectionTitle';
import propTypes from 'prop-types';
import Media from '@/UIComponents/Media';
import { Button } from '@/UIComponents/Button';
import useIsMobile from 'hooks/useIsMobile';

const ClientInformationBannerTopText = ({
  title,
  titleType,
  description,
  buttons,
  mediaPosition,
  media,
  order,
  layout
}) => {
  const isMobile = useIsMobile();

  return (
    <StyledInformationBannerTopText mediaPosition={mediaPosition} id={order} $layout={layout}>
      <Media responsive={!!isMobile} {...media} width={635} height={606} />

      <div className="info-ctr">
        {title && (
          <SectionTitle as={titleType} className="title" variant="secondary">
            {title}
          </SectionTitle>
        )}
        {description && <div className="description" dangerouslySetInnerHTML={{ __html: description }} />}
        {buttons?.map(({ button }, index) => (
          <div className="button" key={index}>
            <Button {...button}>{button?.label}</Button>
          </div>
        ))}
      </div>
    </StyledInformationBannerTopText>
  );
};

ClientInformationBannerTopText.propTypes = {
  media: propTypes.object,
  title: propTypes.string.isRequired,
  titleType: propTypes.string,
  tags: propTypes.array,
  description: propTypes.string,
  buttons: propTypes.array,
  mediaPosition: propTypes.string,
  coords: propTypes.object,
  infoType: propTypes.string,
  layout: propTypes.object,
  order: propTypes.oneOfType([propTypes.string, propTypes.number])
};

export default ClientInformationBannerTopText;
