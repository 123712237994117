import styled from 'styled-components';

const StyledCardsListSection = styled.div`
  margin: auto;
  ${({ theme }) => theme.mixins?.layout};
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1600px;
  padding: 0;

  ${({ theme }) => theme.bps?.mobile} {
    max-width: 90%;
  }

  .cards-outer-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;

    ul.cards-wrap {
      cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'auto')};
      display: flex;
      flex-direction: row;
      align-items: stretch;
      list-style-type: none;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      position: relative;
      scrollbar-width: none; /* From Firefox */
      -ms-overflow-style: none; /* From IE and Edge */
      gap: 12px;

      ${({ theme }) => theme?.bps?.mobile} {
        flex-direction: column;
        padding: 0;
        gap: 24px;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .progress-bar {
    margin-top: 20px;
    height: 3px;
    background-color: ${({ theme }) => theme.colors?.buttons.outlinedPress}; /* Progress bar color */
    align-self: center;
  }

  progress[value]::-webkit-progress-value {
    transition: width 0.5s;
    background: black;
  }
`;

export default StyledCardsListSection;
