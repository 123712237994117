import styled, { css } from 'styled-components';

const StyledImage = styled.div`
  display: flex;
  ${({ $responsive, $sizes }) =>
    $responsive &&
    css`
      position: relative;
      width: 100%;
      aspect-ratio: ${$sizes?.width / $sizes?.height};
    `};
`;

export default StyledImage;
