import { useState, useRef, useEffect, useCallback } from 'react';
import { useInView } from 'react-intersection-observer';
import useIsMobile from 'hooks/useIsMobile';
import propTypes from 'prop-types';
import pauseDeskFill from 'public/icons/pause-desk-fill.svg';
import pauseDeskOutline from 'public/icons/pause-desk-outline.svg';
import playDeskFill from 'public/icons/play-desk-fill.svg';
import playDeskOutline from 'public/icons/play-desk-outline.svg';
import soundFillDefault from 'public/icons/sound-fill-default.svg';
import soundFillMute from 'public/icons/sound-fill-mute.svg';
import soundOutlineDefault from 'public/icons/sound-outline-default.svg';
import soundOutlineMute from 'public/icons/sound-outline-mute.svg';
import fullScreenFill from 'public/icons/fullscreen-fill.svg';
import fullScreenOutline from 'public/icons/fullscreen-outline.svg';
import shrinkScreenFill from 'public/icons/shrinkscreen-fill.svg';
import shrinkScreenOutline from 'public/icons/shrinkscreen-outline.svg';
import StyledVideo from './StyledVideo';
import Image from 'core/image';
import { VideoSeekSlider } from 'react-video-seek-slider';
import { isIOS } from 'utils/isIOS';

const Video = ({
  src,
  isAutoplay = true,
  buttonStyle = 'row', //row, column or centered
  isMuted = true,
  displayControlsPanel = false,
  sizes = {},
  width = sizes.width,
  height = sizes.height,
  progressBarWidth = '56%', //directly in % or px
  isFixedHeight = false,
  iconsColorType = 'outline', // outline or fill
  controlsBottom = 0,
  mobileControlsBottom = 0,
  className,
  minHeight = 0,
  displayFullControls = true,
  ...rest
}) => {
  const isMobile = useIsMobile();
  const iconSize = isMobile ? 35 : 48;
  const videoRef = useRef(null);
  const interval = useRef(null);

  const [isPlaying, setIsPlaying] = useState(isAutoplay);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMute, setIsMute] = useState(isMuted);

  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [maxTime, setMaxTime] = useState(0);

  const { ref, inView } = useInView();
  let iconPlay, iconPause, iconSoundDefault, iconSoundMuted, iconFullScreen, iconShrinkScreen;
  switch (iconsColorType) {
    case 'outline':
      iconPlay = playDeskOutline;
      iconPause = pauseDeskOutline;
      iconSoundDefault = soundOutlineDefault;
      iconSoundMuted = soundOutlineMute;
      iconFullScreen = fullScreenOutline;
      iconShrinkScreen = shrinkScreenOutline;
      break;
    case 'fill':
      iconPlay = playDeskFill;
      iconPause = pauseDeskFill;
      iconSoundDefault = soundFillDefault;
      iconSoundMuted = soundFillMute;
      iconFullScreen = fullScreenFill;
      iconShrinkScreen = shrinkScreenFill;
      break;
  }
  const deactivateProgressBar = () => {
    videoRef.current.pause();
    // stops the interval previously set for updating the currentTime.
    // If the video is being paused, it clears the interval defined by setInterval.
    clearInterval(interval.current);
  };
  const activateProgressBar = () => {
    videoRef.current.play();
    // when it's playing check every second the currentTime
    interval.current = setInterval(() => {
      //Initiates an interval to continuously update the currentTime by getting the current time of the video.
      setCurrentTime((videoRef.current?.currentTime || 0) * 1000);
    }, 1000);
  };
  const togglePlay = () => {
    if (isPlaying) {
      deactivateProgressBar();
    } else {
      activateProgressBar();
    }
    setIsPlaying(!isPlaying);
  };
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const PlayPauseIconJSX = (
    <Image
      src={isPlaying ? iconPause : iconPlay}
      alt={isPlaying ? 'pause' : 'play'}
      width={iconSize}
      height={iconSize}
      objectfit="contain"
      onClick={togglePlay}
      className="button-video"
    />
  );

  const FullShrinkScreenIconJSX = (
    <Image
      src={isFullScreen ? iconShrinkScreen : iconFullScreen}
      alt={isFullScreen ? 'fullscreen' : 'minimize'}
      width={iconSize}
      height={iconSize}
      objectFit="contain"
      onClick={toggleFullScreen}
      className="button-video"
    />
  );

  const SoundIconJSX = (
    <Image
      src={isMute ? iconSoundMuted : iconSoundDefault}
      alt={isMute ? 'mute' : 'unmute'}
      width={iconSize}
      height={iconSize}
      objectFit="contain"
      onClick={() => setIsMute(!isMute)}
      className="sound-icon button-video"
    />
  );

  const handleTimeChange = useCallback((time, offsetTime) => {
    if (!videoRef.current?.currentTime) {
      return;
    }
    videoRef.current.currentTime = time / 1000;
    setCurrentTime(time);
  }, []);

  // handleProgress() examines the video buffer's time ranges to determine where the current video time resides in relation to those ranges and updates the progress accordingly.
  const handleProgress = () => {
    // buffer is a process of preloading data into a reserved area of memory called buffer
    // it stores information about time ranges of the video buffer in the buffer variable.
    const buffer = videoRef?.current?.buffered;
    if (((buffer?.length > 0 && videoRef.current?.duration) || 0) > 0) {
      let currentBuffer = 0;
      const inSeconds = videoRef.current?.currentTime || 0;

      for (let i = 0; i < buffer.length; i++) {
        if (buffer.start(i) <= inSeconds && inSeconds <= buffer.end(i)) {
          // if the current video time is within this buffer range defined by buffer.start(i) (start of the range) and buffer.end(i)
          currentBuffer = i;
          break;
        }
      }
      setProgress(buffer.end(currentBuffer) * 1000 || 0);
    }
  };
  const handleDataLoaded = () => {
    setMaxTime((videoRef?.current?.duration || 0) * 1000);
  };

  useEffect(() => {
    if (isFullScreen) {
      if (videoRef.current) {
        //checking if ref was initiated
        if (videoRef.current.requestFullscreen) {
          videoRef.current.requestFullscreen();
        } else if (videoRef.current.webkitRequestFullscreen) {
          /* Safari */
          videoRef.current.webkitRequestFullscreen();
        } else if (videoRef.current.msRequestFullscreen) {
          /* IE11 */
          videoRef.current.msRequestFullscreen();
        } else if (videoRef.current.webkitEnterFullscreen) {
          videoRef.current.webkitEnterFullscreen();
        }
      }
    }
    setIsFullScreen(false);
    if (navigator && navigator.userAgent) {
      const isDeviceIOS = isIOS();
      if (isDeviceIOS) {
        setIsPlaying(false);
      }
    }
  }, [isFullScreen]);

  useEffect(() => {
    if(!isPlaying) {
      setIsPlaying(true);
      activateProgressBar();
    }
    setCurrentTime(0);
    setMaxTime((videoRef?.current?.duration || 1) * 1000);
  }, [src]);

  useEffect(() => {
    if (inView) {
      setIsPlaying(true);
      activateProgressBar();
    } else {
      setIsPlaying(false);
      deactivateProgressBar();
    }
  }, [inView]);

  useEffect(() => {
    if (!videoRef) {
      return;
    }
    videoRef.current?.addEventListener('loadeddata', handleDataLoaded);
    videoRef.current?.addEventListener('progress', handleProgress);
  }, [videoRef]);

  if(rest.responsive === true) rest.responsive = 'true';
  
  return (
    <StyledVideo
      className={className}
      progressBarWidth={progressBarWidth}
      buttonStyle={buttonStyle}
      minHeight={minHeight}
      $width={width}
      $height={isFixedHeight && height}
      $controlsBottom={controlsBottom}
      $mobileControlsBottom={mobileControlsBottom}
      ref={ref}
    >
      <video
        playsInline
        ref={videoRef}
        src={src || ''}
        type="video/mp4"
        muted={isMute}
        loop
        controls={false}
        autoPlay
        {...rest}
      >
        <source src={src || ''} type="video/mp4"></source>
      </video>
      <figcaption className="btn-wrapper">
        {PlayPauseIconJSX}

        {displayFullControls && (
          <>
            {buttonStyle !== 'centered' && (
              <div className="progress-wrapper">
                <VideoSeekSlider
                  id="progress"
                  className="progress-bar"
                  max={maxTime}
                  currentTime={currentTime}
                  bufferTime={progress}
                  onChange={handleTimeChange}
                  limitTimeTooltipBySides={true}
                  secondsPrefix="00:"
                  minutesPrefix="0:"
                />
              </div>
            )}
            {buttonStyle === 'row' && (
              <>
                {SoundIconJSX}
                {FullShrinkScreenIconJSX}
              </>
            )}
          </>
        )}
  
        
      </figcaption>
    </StyledVideo>
  );
};

Video.propTypes = {
  src: propTypes.string,
  sizes: propTypes.object,
  height: propTypes.number,
  width: propTypes.number,
  isAutoplay: propTypes.bool,
  buttonStyle: propTypes.oneOf(['row', 'column', 'centered']),
  isMuted: propTypes.bool,
  displayControlsPanel: propTypes.bool,
  isFixedHeight: propTypes.bool,
  iconsColorType: propTypes.oneOf(['outline', 'fill']),
  progressBarWidth: propTypes.string,
  controlsBottom: propTypes.number,
  mobileControlsBottom: propTypes.number,
  className: propTypes.string,
  minHeight: propTypes.number,
  displayFullControls: propTypes.bool
};

export default Video;
