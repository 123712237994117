'use client';

import propTypes from 'prop-types';
import HeroBanner from '@/UIComponents/HeroBanner';
import StyledHeroBannerSection from './StyledHeroBannerSection';
import { minBy } from 'lodash';
import { Button } from '@/UIComponents/Button';
import { numberWithCommas } from 'utils/text';

const ClientHeroBannerSection = ({ order, layout, HeroBannerFields }) => {
  const {
    model: { modelFields: { finishes = {} } = {} } = {},
    bannerType,
    buttons,
    description,
    priceTitle,
    titleColor
  } = HeroBannerFields || {};

  const finishWithLowestPrice = minBy(
    finishes,
    ({ finish: { ConfigurationPrices } }) => ConfigurationPrices?.vehicletotalpricewvat
  );

  const lowestPrice = finishWithLowestPrice?.finish?.ConfigurationPrices?.vehicletotalpricewvat || 0;
  const lowestLicenseFee = finishWithLowestPrice?.finish?.ConfigurationPrices?.vehiclelicensefee;

  const contentJSX = lowestPrice ? (
    <>
      <h2 className="price-title">{priceTitle}</h2>
      <h2 className="description-banner" style={{ color: titleColor }}>
        החל מ-{numberWithCommas(lowestPrice, true)}*
      </h2>
      <span className="license-fee-text">בתוספת אגרת רישוי על סך-₪{lowestLicenseFee}</span>
    </>
  ) : null;

  const buttonsJSX = buttons?.length > 0 && (
    <div className="buttons">
      {buttons.map((button, index) => {
        const { label } = button || {};
        return (
          <Button key={index} className="button-item" {...button}>
            {label}
          </Button>
        );
      })}
    </div>
  );
  const descriptionJSX =
    bannerType === 'model' ? (
      <>
        {contentJSX}
        {buttonsJSX}
      </>
    ) : (
      <>
        {description && <div className="description" dangerouslySetInnerHTML={{ __html: description }} />}
        {buttonsJSX}
      </>
    );
  return (
    <StyledHeroBannerSection id={order} $layout={layout}>
      <HeroBanner {...HeroBannerFields} descriptionJSX={descriptionJSX} />
    </StyledHeroBannerSection>
  );
};

ClientHeroBannerSection.propTypes = {
  layout: propTypes.object,
  order: propTypes.oneOfType([propTypes.string, propTypes.number]),
  HeroBannerFields: propTypes.object
};

export default ClientHeroBannerSection;
