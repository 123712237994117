import styled from 'styled-components';

const StyledSafetyAndPollutionStrip = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .container {
    font-size: 21px;
    display: flex;
    margin: 10px 0;

  ${({ theme }) => theme.bps?.mobile} {
      font-size: 11px;
  }
  }

  .safety,
  .pollution {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    ${({ theme }) => theme.bps?.mobile} {
      flex-shrink: 0;
    }

    &:first-child {
      margin-right: 0;
    }

    .label {
      margin-left: 8px;

      ${({ theme }) => theme.bps?.mobile} {
        margin-left: 0;
      }

      > span {
        font-size: 11px;
      }
    }
  }
  
  .pollution {
    margin: 0 15px;
    ${({ theme }) => theme.bps?.mobile} {
      margin: 0 5px;
    }
  }

  .more-details {
    background-color: ${({ theme }) => theme?.colors?.white};
    display: flex;
    align-items: center;
    
    .more-details-link {
      font-size: 21px;

        ${({ theme }) => theme.bps?.mobile} {
            font-size: 11px;
        }
      a {
        font-size: 21px;
        color: #000;

          ${({ theme }) => theme.bps?.mobile} {
              font-size: 11px;
          }
      }
    }
    
    ${({ theme }) => theme.bps?.mobile} {
      flex-shrink: 0;
    }
  }
`;

export default StyledSafetyAndPollutionStrip;
