'use client';
import propTypes from 'prop-types';
import useIsMobile from 'hooks/useIsMobile';
import Video from './Video';

const ResponsiveVideo = ({ desktop, mobile = desktop, ...rest}) => {
  const isMobile = useIsMobile();
  if(isMobile && (!!mobile || !!rest?.src)) return <Video {...mobile} {...rest} />;
  else if (!isMobile || !mobile) return <Video {...desktop} {...rest} className="responsive-video" />;
};

ResponsiveVideo.propTypes = {
  mobile: propTypes.object,
  desktop: propTypes.object 
};

export default ResponsiveVideo;
