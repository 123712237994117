'use client';
import { Button } from '@/UIComponents/Button';
import Modal from '@/UIComponents/Modal';
import { sendCarBookWorkato } from '@colmobil/colmolib/minify/sendCarBookWorkato';
import Image from 'core/image';
import useIsMobile from 'hooks/useIsMobile';
import propTypes from 'prop-types';
import closeBlack from 'public/icons/close-x-black.svg';
import success from 'public/images/success.png';
import { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Loader from '../ContactForm/FormMessages/Loader';
import Input from '../Input';
import CONSTANTS from './CONSTANTS';
import StyledModalCarBook from './StyledModalCarBook';

const ModalCarBook = ({ isModalShown = false, setIsModalShown = () => {}, carBookFile = {}, modelId = '' }) => {
  const [inputValue, setInputValue] = useState('');
  const [messageError, setMessageError] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const WPAttachementId = carBookFile?.mediaItemId;
  const reRef = useRef();
  const isMobile = useIsMobile();
  const modalStyles = {
    content: {
      borderRadius: '13px',
      boxShadow: '0 1px 28.7px 0 rgba(0, 0, 0, 0.17)',
      height: 'fit-content',
      position: 'absolute',
      maxWidth: isMobile ? '295px' : '417px',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      margin: 'auto',
      padding: '0',
      outline: '0'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.17)'
    }
  };

  const onSubmit = async () => {
    if (!inputValue.match(CONSTANTS.validRegex) || inputValue?.length < 1) {
      setMessageError(CONSTANTS.EMAIL_ERROR);
      return;
    } else {
      try {
        setIsSubmitting(true);
        const recaptchaToken = await reRef.current.executeAsync();
        if (recaptchaToken) {
          const body = {
            Email: inputValue,
            gRecaptchaResponse: recaptchaToken,
            WPModelId: modelId,
            WPAttachementId,
            site: 'Smart',
            campaign: 'sm_colmobil-websites_VehicleGuideBook'
          };
          const { isSuccess: responseSuccess } = await sendCarBookWorkato(body);

          if (responseSuccess) {
            setIsSubmitting(false);
            reRef?.current?.reset();
            setIsSuccess(true);
          } else {
            setMessageError(CONSTANTS.SERVER_ERROR);
            setIsSubmitting(false);
            reRef?.current?.reset();
          }
        } else {
          setMessageError(CONSTANTS.SERVER_ERROR);
          setIsSubmitting(false);
          reRef?.current?.reset();
        }
      } catch (error) {
        setMessageError(CONSTANTS.SERVER_ERROR);
        setIsSubmitting(false);
        reRef?.current?.reset();
      }
    }
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
    setMessageError('');
  };

  const handleCloseModal = () => {
    reRef?.current?.reset();
    setIsModalShown(false);
  };

  return (
    <Modal isShown={isModalShown} handleClose={handleCloseModal} styles={modalStyles}>
      <StyledModalCarBook isSuccess={isSuccess} isSubmitting={isSubmitting}>
        <Button variant="text" className="close-btn" onClick={handleCloseModal}>
          <Image src={closeBlack || ''} alt={'close'} fill responsive={false} className="image-close" />
        </Button>
        {!isSuccess && (
          <>
            <div className="title">{CONSTANTS.TITLE}</div>
            <div className="subtitle">{CONSTANTS.SUBTITLE}</div>
            <Input
              isError={!!messageError}
              errorMessage={messageError}
              label={CONSTANTS.EMAIL_LABEL}
              type="text"
              placeholder="example@mail.com"
              onChange={handleChange}
            />

            <div className="container-button">
              <Button disabled={isSubmitting} type="action" label={!isSubmitting && CONSTANTS.SEND} className="button-send" onClick={onSubmit}>
                {isSubmitting && <Loader visible={true} height={15} width={15} />}
              </Button>
            </div>
            <ReCAPTCHA ref={reRef} sitekey={CONSTANTS.RECAPTCHA_SITE_KEY} size="invisible" hl={'iw'} />
          </>
        )}
        {isSuccess && (
          <div className="container-success">
            <Image src={success || ''} alt={'success'} fill responsive={false} className="image-success" />
            <p className="success-label">{CONSTANTS.SUCCESS}</p>
            <p className="success-email">{inputValue}</p>
            <div className="success-btn">
              <Button type="action" label={CONSTANTS.CLOSE} className="button-close" onClick={handleCloseModal} />
            </div>
          </div>
        )}
      </StyledModalCarBook>
    </Modal>
  );
};

ModalCarBook.propTypes = {
  isModalShown: propTypes.bool,
  setIsModalShown: propTypes.func,
  carBookFile: propTypes.object,
  modelId: propTypes.number
};

export default ModalCarBook;
