
import propTypes from 'prop-types';
// import Input from '@/UIComponents/Input';
import StyledInputComponent from './StyledInputComponent';
import Input from '@/UIComponents/Input';

const InputComponent = ({asType = 'text', id = '', size = 'big', register = () => {}, ...rest}) => {
  const {validations, errors = {}, placeholder = ''} = rest || {};
  const {required = false} = validations || {};
  const isError = !!errors[id];
  const error =`אנא הזינו ${placeholder} תקין`;
  const registerAttr = register(id,validations);
  
  const inputLabel = `${placeholder}${required ? '*': ''}`;

  return (
    <StyledInputComponent size={size}>
      <Input type={asType} label={inputLabel} isError={isError} errorMessage={error} registerAttr={registerAttr} />
    </StyledInputComponent>
  );
};

InputComponent.propTypes = {
  id: propTypes.string,
  asType: propTypes.oneOf(['input', 'textarea']),
  size: propTypes.oneOf(['big', 'small']),
  register: propTypes.func
};

export default InputComponent;
