import styled from 'styled-components';

const StyledContactUsSection = styled.div`
  ${({ theme }) => theme.mixins?.layout};
  height: ${({ isFormSubmitted }) => (isFormSubmitted ? '630px' : 'max-content')};
  position: relative;
  font-family: ${({ theme }) => theme.fonts?.ploni};

  .content {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 710px;
    margin: 0 auto;
    padding: 48px 0 37.4px 0;
    height: 100%;

    ${({ isFormSubmitted }) =>
      isFormSubmitted &&
      `
    padding-top:0;
    justify-content: center;
    `};
  }

  .section-title {
    text-align: center;
    ${({ titleSize }) => titleSize && `font-size:${titleSize}px`};
    ${({ titleColor }) => titleColor && `color:${titleColor}`};
  }

  .description {
    text-align: center;
    padding: 0 170px 41.3px 170px;
  }

  form {
    padding: 0 127px;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .background-image {
    position: absolute;
    width: 100%;
    height: 100%;

    .image {
      position: initial;
    }
  }

  ${({ theme }) => theme.bps?.mobile} {
    height: ${({ isFormSubmitted }) => (isFormSubmitted ? '551px' : 'max-content')};

    .content {
      width: 100%;
      padding: 24.7px 21px;
    }

    .description {
      padding: 0;
    }

    form {
      padding: 0;

      .input-component {
        max-width: 100%;
      }
    }
  }

  .contact-options {
    position: relative;
    display: flex;
    gap: 47px;
    justify-content: center;

    .contact-option {
      direction: rtl;
      display: flex;
      gap: 10px;
      align-items: center;
      padding-top: 21px;

      ${({ theme }) => theme.bps?.mobile} {
        flex-direction: column;
      }

      a {
        color: initial;
        direction: ltr;

        span {
          vertical-align: text-top;
        }
      }
    }
  }
`;

export default StyledContactUsSection;
