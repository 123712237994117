'use client';

import {createBreakpoint, useDebounce} from 'react-use';
import {breakPoints} from 'styles/theme/layout';
import {useState} from 'react';
const useBreakpoint = createBreakpoint(breakPoints);

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);
  const breakpoint = useBreakpoint();
  useDebounce(() => {
    setIsMobile(breakpoint === 'mobile');
  }, 200, [breakpoint]);

  return isMobile;
};

export default useIsMobile;

