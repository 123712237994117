
'use client';


import StyledCardsListSection from './StyledCardsListSection';
import propTypes from 'prop-types';
import Card from './Card';
import useIsMobile from 'hooks/useIsMobile';
import { handleScroll } from '../../../utils/progressHandler';
import { useRef, useState } from 'react';
import useHorizontalScroll from 'hooks/useHorizontalScroll';

const ClientCardsListSection = ({order, layout, cardsList}) => {
  const isMobile = useIsMobile();
  const cardsRef = useRef(null);
  const isDragging = useHorizontalScroll(cardsRef);
  const [scrollValue, setScrollValue] = useState(0);

  const CardsJSX = cardsList.map((card, index)=><Card key={index} {...card} />);

  const isScrollable = CardsJSX.length > 3;

  return (
    <StyledCardsListSection id={order} $layout={layout} isDragging={isDragging}>
      <div className="cards-outer-wrap">
        <ul className="cards-wrap" ref={cardsRef} onScroll={()=>handleScroll(cardsRef, setScrollValue)} >
          {CardsJSX}
        </ul>
      </div>
      {!isMobile && isScrollable && <progress dir='rtl' max='100' value={scrollValue} className='progress-bar'></progress>}
    </StyledCardsListSection>
  );
};

const CardsListPropTypes = propTypes.shape({
  cardText: propTypes.string,
  cardTitle: propTypes.string,
  finishLink: propTypes.shape({
    uri: propTypes.string,
  }),
  imageFields: propTypes.shape({
    desktop: propTypes.object,
    mobile: propTypes.object,
  }),
  linkText: propTypes.string,
});

ClientCardsListSection.propTypes = {
  layout: propTypes.object,
  order: propTypes.string,
  cardsList: propTypes.arrayOf(CardsListPropTypes)
};

export default ClientCardsListSection;
