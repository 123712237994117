
import propTypes from 'prop-types';
import LevelBox from './LevelBox';
import StyledLevelStrip from './StyledLevelStrip';

const LevelStrip = ({ levels = [], colorsPalette = {} }) => {
  const levelsJSX = levels?.map((level, index) =>
    <LevelBox key={index} level={Number(level)} color={colorsPalette[level]} />
  );
  return (
    <StyledLevelStrip colorsPalette={colorsPalette}>
      {levelsJSX}
    </StyledLevelStrip>
  );
};

LevelStrip.propTypes = {
  levels: propTypes.array.isRequired,
  colorsPalette: propTypes.objectOf(propTypes.string).isRequired
};

export default LevelStrip;
