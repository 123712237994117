'use client';
import propTypes from 'prop-types';
import StyledFilledTabs from './StyledFilledTabs';
import {useState} from 'react';

const FilledTabs = ({ items = [], onChange = (index) => {} , ...props}) => {
  const [value, setValue] = useState(0);

  const handleClick = (itemIndex) => {
    setValue(itemIndex);
    onChange && onChange(itemIndex);
  };

  const tabs = items.map(item => {
    const className = value === items.indexOf(item) ? 'active' : 'not-active';
    return (
      <div
        onClick={() => handleClick(items.indexOf(item))}
        className={`option ${className}`}
        key={item}
      >
        {item}
      </div>
    );
  }) || [];

  return (
    <StyledFilledTabs color='light' {...props}>
      {tabs}
    </StyledFilledTabs>
  );
};

FilledTabs.propTypes = {
  items: propTypes.arrayOf(propTypes.string),
  onChange: propTypes.func
};
export default FilledTabs;