
'use client';

import FinishColorsSection from '@/Templates/FinishTemplate/FinishColorsSection';
import StyledColorsSection from './StyledColorsSection';
import propTypes from 'prop-types';

const ClientColorsSection = ({title='',finishFields={}, generalText = '', order, layout}) => {  

  return (
    <StyledColorsSection id={order} $layout={layout}>
      <FinishColorsSection title={title} finishFields={finishFields} generalText={generalText} />
    </StyledColorsSection>
  );
};

ClientColorsSection.propTypes = {
  layout: propTypes.object,
  order: propTypes.string,
  finishFields: propTypes.object,
  title: propTypes.string,
  generalText: propTypes.string
};

export default ClientColorsSection;
