import React from 'react';
import propTypes from 'prop-types';
import StyledSelectBox from './StyledSelectBox';
import CustomDropdown from './CustomDropDown';

const SelectBox = ({ id = '', errors = '', list = [], value = '',label, setValue = () => {}, register = () => {}, required, placeholder = '', size = 'big' }) => {
  const error =`אנא הזינו ${label} תקין`;

  const isError = errors[id];
  const errorJSX = <span className={`${!(isError) ? 'error' : 'error show' }`}>{error}</span>;

  const inputLabel = `${label}${required ? '*': ''}`;

  return (
    <StyledSelectBox isError={isError} htmlFor={id} size={size}>
      <label className="label-input">{inputLabel}</label>
      <div className="select-wrapper">
        <CustomDropdown
          isError={isError}
          options={list}
          placeholder={placeholder}
          register={register(id, { required })}
          id={id}
          name={id}
          setValue={setValue}
          value={value}
        />
      </div>
      {errorJSX}
    </StyledSelectBox>
  );
};
SelectBox.propTypes = {
  id: propTypes.string,
  list: propTypes.array,
  errors: propTypes.object,
  label: propTypes.string,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  setValue: propTypes.func,
  register: propTypes.func,
  required: propTypes.bool,
  placeholder : propTypes.string,
  size: propTypes.string
};
export default SelectBox;