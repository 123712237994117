import styled from 'styled-components';

const StyledColorsComponent = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding-bottom: 20px;
  padding-top: 35px;

  ${({ theme }) => theme.bps?.mobile} {
    margin: 0;
    padding: 40px 65px 25px;
    overflow-y: auto;
    gap: 24px;
    justify-content: ${({ colorsType, colorsLength }) =>
      colorsType === 'inner' || colorsLength <= 4 ? 'center' : 'flex-start'};
  }
`;

export default StyledColorsComponent;
