import { useEffect, useState } from 'react';

const useHorizontalScroll = (ref) => {
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(null);
    const [scrollLeft, setScrollLeft] = useState(null);
    useEffect(() => {
        const container = ref.current;

        if (!container) return;

        const handleMouseDown = (e) => {
            setIsDragging(true);
            setStartX(e.pageX - container.offsetLeft);
            setScrollLeft(container.scrollLeft);
            container.classList.add('grabbing');
        };

        const handleMouseUp = () => {
            setIsDragging(false);
            container.classList.remove('grabbing');
        };

        const handleMouseLeave = () => {
            if (isDragging) {
                setIsDragging(false);
                container.classList.remove('grabbing');
            }
        };

        const handleMouseMove = (e) => {
            if (!isDragging) return;
            e.preventDefault();
            const x = e.pageX - container.offsetLeft;
            const walk = (x - startX); // Adjust the scroll speed as needed
            container.scrollLeft = scrollLeft-walk;
        };

        container.addEventListener('mousedown', handleMouseDown);
        container.addEventListener('mouseup', handleMouseUp);
        container.addEventListener('mouseleave', handleMouseLeave);
        container.addEventListener('mousemove', handleMouseMove);

        return () => {
            container.removeEventListener('mousedown', handleMouseDown);
            container.removeEventListener('mouseup', handleMouseUp);
            container.removeEventListener('mouseleave', handleMouseLeave);
            container.removeEventListener('mousemove', handleMouseMove);
        };
    }, [ref, isDragging, startX, scrollLeft]);

    return isDragging;
};

export default useHorizontalScroll;
