
'use client';

import StyledTextWithTitle from './StyledTextWithTitle';
import SectionTitle from '@/UIComponents/SectionTitle';
import propTypes from 'prop-types';

const ClientTextWithTitle = ({layout, order, content, title, titleTextSize}) => {

  return (
    <StyledTextWithTitle id={order} $layout={layout} $titleTextSize={titleTextSize} >
      <SectionTitle className='ctwt-title'>{title}</SectionTitle>
      <div className='content' dangerouslySetInnerHTML={{'__html' : content}} />
    </StyledTextWithTitle>
  );
};

ClientTextWithTitle.propTypes = {
  layout: propTypes.object,
  order: propTypes.oneOfType([propTypes.string, propTypes.number]),
  content: propTypes.string,
  title: propTypes.string,
  titleTextSize: propTypes.shape({
    desktop: propTypes.number,
    mobile: propTypes.number
  }),
};

export default ClientTextWithTitle;
