import styled from 'styled-components';

const StyledButton = styled.span`
  display: flex;
  gap: 10px;
  height: 48px;
  padding: 16px 36px;
  border-radius: 48px;
  justify-content: center;
  align-items: center;
  
  ${({ theme, variant }) => theme.mixins?.buttons[variant]};
  
  .icon {
    align-self: baseline;
    filter: invert(${({ variant }) => (variant === 'primary' ? 0 : variant === 'disabled' ? 50 : 100)}%);
  }
`;

export default StyledButton;
