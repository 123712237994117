import propTypes from 'prop-types';
import ButtonAction from '@/UIComponents/Button/ButtonAction';
import ButtonLink from '@/UIComponents/Button/ButtonLink';
import ArrowLeftIcon from 'public/icons/arrow-left.svg';
import Image from 'core/image';

const Button = ({ type = 'action', href, exteriorUrl, interiorUrl = {}, isNewWindow, label = '', children, withIcon = false, ...rest }) => {
  const isActionButton = type === 'action';
  const innerJSX = <>
    {children || label}
    {withIcon && (
      <div className='icon'>
        <Image src={ArrowLeftIcon} alt='arrow-left'/>
      </div>
    )}
  </>;
  
  if (isActionButton) return (<ButtonAction {...rest}>{innerJSX}</ButtonAction>);
  
  const target = isNewWindow ? '_blank' : null;
  const hrefUrl = exteriorUrl || interiorUrl.uri || href;

  return (<ButtonLink target={target} href={hrefUrl} {...rest}>{innerJSX}</ButtonLink>);
};

Button.propTypes = {
  type: propTypes.oneOf(['action', 'url', 'page_link']),
  children: propTypes.any,
  exteriorUrl: propTypes.string,
  href: propTypes.string,
  interiorUrl: propTypes.object,
  isNewWindow: propTypes.bool,
  withIcon: propTypes.bool,
  label: propTypes.string
};
export default Button;