import styled from 'styled-components';

const StyledStickySideMenu = styled.div`
  position: fixed;
  top: 453px;
  left: 55px;
  z-index: 2;
  ${({ theme }) => theme.bps?.mobile} {
    top: 314px;
    left: 12px;
  }

  .sticky-menu-list {
    display: flex;
    flex-direction: column;
    gap: 19px;
    justify-content: space-between;

    > a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      width: 57px;
      height: 57px;
      background-color: ${({ theme }) => theme.colors?.oliveBright};
      position: relative;
      transition: all 0.8s ease-in-out;
      cursor: pointer;
      border: solid 3px rgba(255, 255, 255, 0.15);

      ${({ theme }) => theme.bps?.mobile} {
        width: 42px;
        height: 42px;
      }

      > div {
        width: 22px;
        height: 22px;
        transition: all 0.5s ease;

        ${({ theme }) => theme.bps?.mobile} {
          width: 15px;
          height: 15px;
        }
      }

      &:hover {
        > div {
          transform: scale(0.7);

          ${({ theme }) => theme.bps?.mobile} {
            transform: scale(1);
          }
        }
      }
    }
  }
`;

export default StyledStickySideMenu;
