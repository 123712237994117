import styled from 'styled-components';

const StyledColorViewer = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  ${({ theme }) => theme.mixins?.layout};

  display: flex;
  flex-direction: ${({ mediaPosition }) => (mediaPosition === 'left' ? 'row-reverse' : 'unset')};
  gap: 160px;

  ${({ theme }) => theme.bps?.mobile} {
    gap: 34px;
    font-size: 13px;
    flex-direction: column;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding: 0;
  }

  .title {
    font-size: 25px;
    margin-bottom: 28px;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts?.smartSans};

    ${({ theme }) => theme.bps?.mobile} {
      text-align: center;
    }
  }

  .video-ctr {
    position: relative;

    ${({ theme }) => theme.bps?.desktop} {
      max-height: 710px;
    }

    video {
      ${({ theme }) => theme.bps?.desktop} {
        width: 800px;
      }

      ${({ theme }) => theme.bps?.mobile} {
        height: 327px;
      }
    }

    .btn-wrapper {
      justify-content: flex-start;
      padding-left: 25px;
      bottom: 25px;

      ${({ theme }) => theme.bps?.mobile} {
        bottom: 15px;
        padding-left: 15px;
      }
    }

    .tabs {
      position: absolute;
      right: 24px;
      bottom: 24px;
      z-index: 10;

      ${({ theme }) => theme.bps?.mobile} {
        bottom: -60px;
        right: 10px;
      }
    }
  }

  .info-ctr {
    ${({ theme }) => theme.bps?.mobile} {
      width: unset;
    }

    .btn {
      margin-bottom: 42px;

      ${({ theme }) => theme.bps?.mobile} {
        margin-bottom: 25px;
      }
    }

    .mini-title {
      color: ${({ theme }) => theme.colors?.disabled};
      font-size: 14px;
      width: 40%;

      ${({ theme }) => theme.bps?.desktop} {
        margin-bottom: 12px;
      }

      &.color-title {
        ${({ theme }) => theme.bps?.mobile} {
          padding-top: 8px;
        }
      }
    }

    .description {
      margin-bottom: 68px;

      ${({ theme }) => theme.bps?.mobile} {
        font-size: 14px;
        text-align: center;
        margin: 0 auto;
        width: 260px;
      }
    }

    .colors-ctr {
      display: flex;
      margin-bottom: 28px;
      gap: 12px;

      ${({ theme }) => theme.bps?.desktop} {
        flex-direction: column;
      }

      .colors {
        display: flex;
        gap: 12px;

        ${({ theme }) => theme.bps?.desktop} {
          flex-wrap: wrap;
        }

        ${({ theme }) => theme.bps?.mobile} {
          overflow-x: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .color {
          cursor: pointer;

          &.active {
            border: 1px solid ${({ theme }) => theme.colors?.dark};
            border-radius: 50%;
          }
        }
      }
    }

    .finishes-ctr {
      display: flex;
      margin-bottom: 12px;
      gap: 12px;

      ${({ theme }) => theme.bps?.desktop} {
        flex-direction: column;
      }

      .finishes {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 12px;

        ${({ theme }) => theme.bps?.mobile} {
          flex-direction: row;
          overflow-x: auto;

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .finish-name {
          white-space: nowrap;
          font-family: ${({ theme }) => theme.fonts?.smartSans};
          color: ${({ theme }) => theme.colors?.disabled};
          cursor: pointer;

          &.active {
            color: ${({ theme }) => theme.colors?.dark};
          }
        }
      }
    }
  }
`;

export default StyledColorViewer;
