import styled from 'styled-components';

const StyledButton = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  ${({ theme }) => theme.mixins?.layout};

  .button {
    width: fit-content;
    margin: 0 auto;
  }
`;

export default StyledButton;
