'use client';
import InputText from '@/UIComponents/Input/InputText';
import InputRadio from '@/UIComponents/Input/InputRadio';
import propTypes from 'prop-types';

const Input = ({ label = '', type = '', isError = false, errorMessage = '', className = 'input-component', ...rest }) => {
  if (type === 'text' || type === 'textarea')
    return <InputText type={type} label={label} isError={isError} errorMessage={errorMessage} className={className} {...rest} />;
  if (type === 'radio')
    return <InputRadio label={label} isError={isError} errorMessage={errorMessage} className={className} />;
};

Input.propTypes = {
  label:propTypes.string,
  type: propTypes.oneOf(['text', 'radio','textarea']),
  isError: propTypes.bool,
  errorMessage: propTypes.string,
  className: propTypes.string
};

export default Input;
