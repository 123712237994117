import { css } from 'styled-components';

const mixins = {
  primary: css`
    background-color: ${({ theme }) => theme.colors?.black};
    color: ${({ theme }) => theme.colors?.white};
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts?.ploni};
    font-weight: bold;
    border-width: 0;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors?.buttons.primaryHover};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors?.buttons.primaryPress};
    }
  `,

  filledWhite: css`
    background-color: ${({ theme }) => theme.colors?.white};
    color: ${({ theme }) => theme.colors?.black};
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts?.ploni};
    font-weight: bold;
    border-width: 0;
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors?.buttons.outlinedPress};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors?.buttons.outlinedPress};
    }
  `,


  outlined: css`
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors?.dark};
    color: ${({ theme }) => theme.colors?.dark};
    font-size: 16px;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts?.ploni};
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors?.buttons.outlinedHover};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors?.buttons.outlinedPress};
    }
  `,

  outlinedWhite: css`
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors?.white};
    color: ${({ theme }) => theme.colors?.white};
    font-size: 16px;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fonts?.ploni};
    cursor: pointer;

    &:hover {
      background-color: ${({ theme }) => theme.colors?.buttons.primaryHover};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors?.buttons.primaryPress};
    }
  `,

  disabled: css`
    position: relative;
    border: 1px solid ${({ theme }) => theme.colors?.white};
    color: ${({ theme }) => theme.colors?.white};
    font-family: ${({ theme }) => theme.fonts?.ploni};
    background-color: ${({ theme }) => theme.colors?.buttons.disabled};
    font-weight: bold;
    cursor: not-allowed;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 25px;
      width: 100%;
      height: 100%;
      background-color: inherit;
      backdrop-filter: blur(2px);
      z-index: -1;
    }

    &:hover {
      background-color: ${({ theme }) => theme.colors?.buttons.disabledHover};
    }

    &:active {
      background-color: ${({ theme }) => theme.colors?.buttons.disabledPress};
    }
  `,

  text: css`
    font-size: 13px;
    font-weight: 600;
    text-decoration: underline;
    border: unset;
    padding: 0;
    
  `
};

export default mixins;
