'use client';

import { useState, useEffect } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import propTypes from 'prop-types';
import { hasCookie, deleteCookie } from 'cookies-next';
import { revalidatePage } from 'core/revalidation/onDemandApp';

import StyledInfoBar from 'styles/StyledInfoBar'; 
import moment from 'utils/moment';

const LOGOUT = 'התנתק';
const ACTIVE = 'פעיל';
const INACTIVE = 'כבוי';

const PREVIEW_MODE = 'תצוגה מקדימה:';
const CLICK_TO_DISABLE = 'לחץ לכיבוי מצב תצוגה מקדימה';
const CLICK_TO_ENABLE = 'לחץ להפעלת מצב תצוגה מקדימה';
const LAST_VALIDATE = 'עדכון אחרון לעמוד הושלם בשעה';

const AuthBar = ({ id: pageId, isDraftMode: isPreviewMode, lastValidate, cookieName = '' }) => {
  const router = useRouter();
  const pathname = usePathname();

  //! Coockie behavior
  const hideAuthbar = () => deleteCookie(cookieName);
  const isAuth = () => hasCookie(cookieName);

  //! Check if the authbar cookie exists
  const [isAuthUser, setIsAuthUser] = useState(false);

  useEffect(() => {
    const isAuthUser = isAuth();
    setIsAuthUser(isAuthUser);
  }, []);
  
  //! Revalidate page states
  const [isRevalidating, setIsRevalidating] = useState(false);
  const [reavalidateStatus, setReavalidateStatus] = useState('רענן נתונים');

  //! Logout handler: hide the authbar and redirect to wp-login.php?action=logout
  const logoutHandler = () => {
    hideAuthbar();
    router.push(`${process.env.NEXT_PUBLIC_URL}/wp-login.php?action=logout`);
  };

  const normalizedLastValidate = moment(lastValidate).format('HH:mm:ss (DD/MM)');

  //! Revalidate handler: revalidate the page
  const revalidateHandler = async () => {
    if(isRevalidating) return;
    setIsRevalidating(true);
    await revalidatePage({
      path: pathname, 
      setReavalidateStatus,
      window
    });
    setIsRevalidating(false);
  };

  //! Custom menu
  const menu = [
    {
      label: 'עבור לדשבורד',
      href: 'wp-admin/'
    }, !isPreviewMode && {
      label: 'ערוך דף',
      href: `wp-admin/post.php?post=${pageId}&action=edit`
    }, 
  ].filter(Boolean);

  //! If the user is not logged in, return null
  if(!isAuthUser) return null;

  return (
    <StyledInfoBar className='auth-bar' isPreviewMode={isPreviewMode}>
      <ul>
        {menu.map(({ label, href }) => (
          <li className='item' key={href}>
            <a href={`${process.env.NEXT_PUBLIC_URL}/${href}`} target="_blank" rel="noreferrer">{label}</a> 
          </li>
        ))}
        <li className='item'>
          <span className={isRevalidating ? 'busy' : 'link'} onClick={revalidateHandler}>{reavalidateStatus}</span>
        </li>
        <li>
          <span className="link" onClick={logoutHandler}>{LOGOUT}</span>
        </li>
      </ul> 
      {!isPreviewMode && <span>
        {LAST_VALIDATE} <span className='bold'>{normalizedLastValidate}</span>
      </span>}
      <span> 
        {PREVIEW_MODE}
        <span className='preview-stauts'> {isPreviewMode ? ACTIVE : INACTIVE} </span>
        <> | <a className='preview-disable' href={`/preview${isPreviewMode ? '/disable' : ''}?previewPathname=${pathname}`}>{isPreviewMode ? CLICK_TO_DISABLE : CLICK_TO_ENABLE}</a></>
      </span>
    </StyledInfoBar>
  );
};

AuthBar.propTypes = {
  id: propTypes.number,
  isDraftMode: propTypes.bool,
  lastValidate: propTypes.string,
  cookieName: propTypes.string
};

export default AuthBar;
