import styled from 'styled-components';

const StyledBreadcrumb = styled.ul`
  /* display: flex; */
  display: none;
  align-items: center;
  position: relative;
  padding: 8px 0;
  color: ${({ theme }) => theme.colors?.dark?.green};
  margin: initial;

  ${({ theme }) => theme.bps?.mobile} {
    padding-right: 0;
  }

  .container {
    ${({ theme }) => theme.mixins?.shrink};
    max-width: 1361px;
    width: 1361px;
    padding: 10px;

    ${({ theme }) => theme.bps?.mobile} {
      width: 100%;
      max-width: initial;
      padding: initial;
    }
  }

  .path-item {
    display: inline-block;

    a:visited,
    a {
      text-decoration: none;
      font-size: 12px;
      color: ${({ theme }) => theme.colors?.dark?.green};
    }
  }

  .root-path {
    font-size: 12px;
    line-height: 1.2;
    text-align: right;

    .path-item {
      margin-left: 10px;
    }
  }

  .path {
    font-size: 12px;
    line-height: 1.2;
    text-align: right;

    .path-item {
      margin-right: 10px;
      margin-left: 10px;
    }
  }
`;

export default StyledBreadcrumb;
