import styled from 'styled-components';

const StyledInformationBanner = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  ${({ theme }) => theme.mixins?.layout};

  display: flex;
  flex-direction: ${({ mediaPosition }) => (mediaPosition === 'left' ? 'row-reverse' : 'unset')};
  gap: 220px;

  ${({ theme }) => theme.bps?.mobile} {
    gap: 43px;
    flex-direction: column-reverse;
  }

  .title {
    font-family: ${({ theme }) => theme.fonts?.smartNext};
  }

  .description {
    font-size: 21px;
    margin: 29px 0;
    line-height: 1.33;
    font-weight: 500;
    font-family: ${({ theme }) => theme.fonts?.ploni};

    ${({ theme }) => theme.bps?.mobile} {
      font-size: 16px;
    }
  }

  .info-ctr {
    align-self: center;
    text-align: center;
  }
`;

export default StyledInformationBanner;
