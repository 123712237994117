import styled from 'styled-components';

export const StyledApSafetyTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 22px;

  .table-header {
    height: 74px;
    border: solid 1px ${({theme}) => theme.colors?.tableBorders};
    background-color: ${({theme}) => theme.colors?.tableBackground};


    .model-name-label {
      width: 44%;
    }

    .label-width {
      width: 13%;
    }
  }

  .table-row,
  .head-cell,
  .table-cell {
    border: solid 1px ${({theme}) => theme.colors?.tableBorders};
    text-align: center;
    height: 50px;
    font-size: 18px;
  }

  .row:nth-child(even) {
    background-color: ${({theme}) => theme.colors?.tableBackground};
  }


  .head-cell {
    ${({theme}) => theme.bps?.mobile} {
      padding: 1px;
      font-size: 16px;
    }
  }

  .bold {
    font-weight: bold;
  }

  .header-label {
    height: 74px;
    text-align: center;
    font-size: 18px;
    border: solid 1px ${({theme}) => theme.colors?.tableBorders};
    background-color: ${({theme}) => theme.colors?.tableBackground};

  }
`;

export const StyledCellPollutionDegree = styled.td`
  font-weight: bold;
  background-color: ${({ theme, pollutionLevel }) => theme.colors?.pollution[pollutionLevel]};
`;
