import styled from 'styled-components';
const StyledCustomDropDown = styled.div`
  flex: 100%;
  position: relative;
  .header-placeholder {
    width: 100%;
    height: 100%;
    cursor: pointer;
    .header-placeholder-input {
      font-size: 18px;
      font-weight: normal;
      flex-grow: 0;
      width: 100%;
      height: 100%;
      padding: 10px 15px 10px 15px;
      border-bottom: 1px solid ${({ theme, isError }) => (isError ? theme.colors?.error : theme.colors?.dark)};
    }
    .icon-toggle {
      position: absolute;
      left: 15px;
      top: 20px;
      ${({ theme }) => theme.bps?.mobile} {
        top: 22px;
      }
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      flex-grow: 0;
      background-color: ${({ theme }) => theme.colors?.dark.green};
      &.toggled {
        transform: rotate(180deg);
        left: 20px;
        left: 20px;
      }
    }
    .icon-toggle::before {
      content: '';
      position: absolute;
      width: 7px;
      height: 1.4px;
      background-color: ${({ theme }) => theme.colors?.dark.green};
    }
    .icon-toggle::after {
      content: '';
      position: absolute;
      width: 7px;
      height: 1.4px;
      left: 0.8px;
      background-color: ${({ theme }) => theme.colors?.dark.green};
    }
    .icon-toggle::before {
      transform: rotate(45deg); /* Rotate by 45 degrees to form a 90-degree angle */
    }
    .icon-toggle::after {
      transform: rotate(-45deg); /* Rotate by -45 degrees to form a 90-degree angle */
    }
  }
  .dropdown-options {
    list-style: none;
    padding: 10px;
    margin: 5px 0 0 0;
    color: ${({ theme }) => theme.colors?.dark.green};
    background-color: ${({ theme }) => theme.colors?.white};
    border: solid 1px ${({ theme }) => theme.colors?.dark.green};
    width: 100%;
    position: absolute;
    height: auto;
    max-height: 250px;
    overflow-y: scroll;
    z-index: 1;
    cursor: pointer;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    li {
      padding: 5px;
      &:hover {
        font-weight: bold;
      }
      .seperator {
        margin-top: 5px;
        height: 1px;
        align-self: stretch;
        flex-grow: 0;
        transform: rotate(-180deg);
        background-color: rgba(2, 66, 65, 0.1);
      }
    }
  }
`;
export default StyledCustomDropDown;
