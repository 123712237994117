import styled from 'styled-components';

const StyledGallery = styled.div`
  ${({ theme }) => theme.bps?.mobile} {
    ${({ theme }) => theme.mixins?.shrink};
  }

  ${({ theme }) => theme.mixins?.layout};

  position: relative;
  display: flex;
  flex-direction: column;

  .section-title {
    text-align: center;
    padding-bottom: 40px;

    ${({ theme }) => theme.bps?.mobile} {
      max-width: 400px;
    }

    max-width: 800px;
    margin: 0 auto;
  }

  .wrapper-gallery {
    cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'auto')};
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    position: relative;
    scrollbar-width: none; /* From Firefox */
    -ms-overflow-style: none; /* From IE and Edge */

    &::-webkit-scrollbar {
      display: none;
    }

    ${({ theme }) => theme.bps?.mobile} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    ${({ isScrollable }) =>
      !isScrollable &&
      `
        justify-content: center;
        overflow-x: hidden; `}
  }

  .item-block {
    width: ${({ isScrollable }) => (isScrollable ? '25vw' : '29.16667vw')};
    flex-shrink: 0;

    ${({ imagePosition }) =>
      imagePosition === 'down' && 'display: flex;' + 'flex-direction: column-reverse; ' + 'align-self: flex-start;'}

    margin-right: 4.1667vw;

    ${({ theme }) => theme.bps?.mobile} {
      margin-right: 0;
    }

    &:first-child {
      margin-right: ${({ isScrollable }) => (isScrollable ? '4.16667vw' : '0')};

      ${({ theme }) => theme.bps?.mobile} {
        margin-top: 0;
        padding-top: 0;
        margin-right: 0;
      }
    }

    img {
      height: 100%;
      width: 100%;
      display: block;
    }

    &-0 {
      padding-top: calc((29.16667vw / 0.75 / 2) - (25vw / 2));

      ${({ theme }) => theme.bps?.mobile} {
        margin: 65px auto 0;
        width: 100%;
        padding-top: 0;
      }
    }

    &-1 {
      width: ${({ isScrollable }) => (isScrollable ? '29.16667vw' : '33.333vw')};

      ${({ theme }) => theme.bps?.mobile} {
        margin: 65px auto 0;
        width: 100%;
      }
    }

    &-2 {
      padding-top: calc((29vw) / 0.75 / 2);

      ${({ theme }) => theme.bps?.mobile} {
        margin: 65px auto 0;
        width: 100%;
        padding-top: 0;
      }
    }
  }

  .details {
    padding: 40px 24px 0;

    ${({ imagePosition }) => imagePosition === 'down' && 'padding: 0 24px 24px;'}
    ${({ theme }) => theme.bps?.mobile} {
      padding: 23px 0 0 0;
      ${({ imagePosition }) => imagePosition === 'down' && 'padding: 0 24px 24px;'}
    }

    .title-image {
      font-size: 20px;
      line-height: 1.4;
      letter-spacing: 0.2px;

      ${({ theme }) => theme.bps?.mobile} {
        text-align: center;
      }

      text-align: ${({ imagePosition }) => (imagePosition === 'down' ? 'center' : 'right')};
    }

    .description {
      margin: 0;
      line-height: 1.4;
      letter-spacing: 0.16px;

      ${({ theme }) => theme.bps?.mobile} {
        text-align: center;
      }

      text-align: ${({ imagePosition }) => (imagePosition === 'down' ? 'center' : 'right')};
    }
  }

  .disclaimer {
    font-size: 21px;
    max-width: 1445px;
    padding-inline: 20px;
    margin: 110px auto 0;

    ${({ theme }) => theme.bps?.mobile} {
      margin-top: 65px;
      padding-inline: 7.8px;
      font-size: 14px;
    }
  }

  .progress-bar {
    margin-top: 20px;
    height: 3px;
    background-color: ${({ theme }) => theme.colors?.buttons.outlinedPress}; /* Progress bar color */
    align-self: center;
  }

  progress[value]::-webkit-progress-value {
    transition: width 0.5s;
    background: black;
  }

  ${({ theme }) => theme.bps?.mobile} {
    .progress-bar {
      display: none;
    }
  }
`;

export default StyledGallery;
