import propTypes from 'prop-types';
import StyledCheckBox from './StyledCheckBox';
import checkedIcon from 'public/icons/forms/checked.svg';
import uncheckedIcon from 'public/icons/forms/unchecked.svg';
import Image from '@/Core/Image/Image';

const CheckBox = ({label, className = '', value, setValue=()=>{}, isDisable, id, register,...rest}) => {

  const toggleChecked = (e) => {
    setValue(!value);
  };
  const {name, onBlur, ref, onChange = () => {}} = register ? register(id,{required:false}) : {};

  return (
    <StyledCheckBox htmlFor={id} isDisable={isDisable} className={className}>
      <input
        type='checkbox'
        id={id}
        onChange={onChange}
        onClick={toggleChecked}
        checked={value}
        disabled={isDisable}
        name={name}
        onBlur={onBlur}
        ref={ref}
        {...rest}
      />
      <span className='check'> <Image src={value ? checkedIcon : uncheckedIcon} width={20} height={20} alt={id} fill={false} /></span>
      <span className='label'>{label}</span>
    </StyledCheckBox>
  );
};
CheckBox.propTypes = {
  label: propTypes.oneOfType([propTypes.string,propTypes.object]),
  className: propTypes.string,
  value: propTypes.bool,
  setValue: propTypes.func,
  isDisable: propTypes.bool,
  id:propTypes.string,
  register:propTypes.func
};
export default CheckBox;