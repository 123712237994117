import propTypes from 'prop-types';
import Image from 'core/image';
import Video from '@/UIComponents/Video';

const Media = ({ type = 'image', imageFields = {}, videoFields = {}, responsive = false, ...props }) => {
  if (type === 'video') return <Video {...videoFields} {...props} />;
  return <Image {...imageFields} responsive={responsive} {...props} />;
};


Media.propTypes = {
  type: propTypes.oneOf(['video', 'image']),
  imageFields: propTypes.object,
  videoFields: propTypes.object,
  responsive: propTypes.bool
};
export default Media;