import styled from 'styled-components';

const StyledCard = styled.li`
  display: flex;
  flex-direction: column;
  min-width: 480px;
  width: 480px;
  background-color: ${({ theme }) => theme.colors?.buttons.outlinedHover};
  border-radius: 8px;

  ${({ theme }) => theme.bps?.mobile} {
    width: 100%;
    min-width: 100%;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    flex: 1;

    .card-image {
      position: relative;
      display: flex;
      margin: 0;
      width: 100%;
      height: 360px;

      ${({ theme }) => theme.bps?.mobile} {
        height: auto;
      }
    }

    .card-bottom {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-self: stretch;
      padding: 47px 48px;

      ${({ theme }) => theme.bps?.mobile} {
        padding: 24px 14px;
      }

      & > h3.card-title {
        font-size: 20px;
        line-height: 28px;

        ${({ theme }) => theme.bps?.mobile} {
          font-size: 16px;
          line-height: 22px;
        }
      }

      .card-desc {
        font-size: 16px;
        line-height: 23px;
        margin: 18px 0;

        ${({ theme }) => theme.bps?.mobile} {
          font-size: 13px;
          line-height: 16px;
          margin: 21px 0;
        }
      }

      .card-link {
        margin-top: auto;

        & > a {
          font-size: 13px;
          line-height: 15px;
          color: ${({ theme }) => theme.colors?.dark};
          font-weight: bold;
          border-bottom: 2px solid ${({ theme }) => theme.colors?.dark};
        }
      }
    }
  }
`;

export default StyledCard;
