import { styled } from 'styled-components';

const StyledFinishesCardsList = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  ${({ theme }) => theme.mixins?.layout};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
  width: 100%;
  position: relative;
  list-style-type: none;
  padding: 0;

  ${({ theme }) => theme?.bps?.mobile} {
    flex-direction: column;
    align-items: center;
    column-gap: 24px;
    row-gap: 0;
    margin: 0;
  }

  .finishes-cards-wrap {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    overflow: auto;

    ${({ theme }) => theme?.bps?.mobile} {
      max-width: 320px;
      width: 320px;
    }

    ul.finishes-cards-inner-wrap {
      cursor: ${({ isDragging }) => (isDragging ? 'grabbing' : 'auto')};
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: center;
      overflow-x: scroll;
      scroll-snap-type: x mandatory;
      position: relative;
      scrollbar-width: none; /* From Firefox */
      -ms-overflow-style: none; /* From IE and Edge */
      gap: 12px;
      padding: 0;

      &.scrollable {
        justify-content: flex-start;
      }

      ${({ theme }) => theme?.bps?.mobile} {
        flex-direction: column;
        justify-content: center;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .bottom-text {
    width: 80%;
    text-align: center;
    margin: 0 auto;

    ${({ theme }) => theme.bps?.mobile} {
      ${({ theme }) => theme.mixins?.shrink};
      width: unset;
      max-width: 320px;
    }

    & > p {
      font-size: 14px;
      padding: 0;
      margin: 0;

      ${({ theme }) => theme.bps?.mobile} {
        font-size: 11px;
      }
    }
  }

  .progress-bar {
    margin-top: 20px;
    height: 3px;
    background-color: ${({ theme }) => theme.colors?.buttons.outlinedPress}; /* Progress bar color */
    align-self: center;
  }

  progress[value]::-webkit-progress-value {
    transition: width 0.5s;
    background: black;
  }
`;

export default StyledFinishesCardsList;
