import styled from 'styled-components';

const StyledExampleSection = styled.div`
  .buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
  }

  .example-empty {
    height: 1200px;
    width: 100%;
    text-align: center;
    border-top: 1px solid lightgray;
  }
`;

export default StyledExampleSection;
