import styled from 'styled-components';

const StyledShowroomTemplate = styled.div`
  width: 100%;

  ${({ theme }) => theme.bps?.mobile} {
    margin-top: ${({ theme }) => -theme.layoutHeights.mobileHeader}px;
  }

  .list-showroom,
  .list-service-centers {
    max-width: 1280px;
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${({ theme }) => theme.bps?.mobile} {
      max-width: 320px;
      margin: 80px auto;
      padding: 0 14px;
    }

    .showroom-title,
    .service-center-title {
      margin: 0;
      font-size: 63px;
      font-weight: bold;
      line-height: 1;
      text-align: left;
      color: ${({ theme }) => theme.colors?.dark};
      margin-bottom: 75px;

      ${({ theme }) => theme.bps?.mobile} {
        font-size: 40px;
        line-height: 1.2;
        margin-bottom: 40px;
      }
    }

    .showroom-list {
      display: flex;
      flex-direction: column;
      row-gap: 75px;

      ${({ theme }) => theme.bps?.mobile} {
        row-gap: 40px;
      }
    }
  }
`;

export default StyledShowroomTemplate;
