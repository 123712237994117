import { getUTMValues } from '@/UIComponents/ContactForm/utils';
import { getMASKU } from '@colmobil/colmolib/minify/getMASKU';

const fetchMaskU = async (UTMCampaign, UTMSource, UTMMedium, site, defaultPhone, setPhone) => {
  const phone = await getMASKU(UTMCampaign, UTMSource, UTMMedium, site, defaultPhone);
  setPhone(phone);
};

export const fetchAndSetMaskuPhone = (phone, setPhone, defaultPhone, site) => {
  if (!phone) {
    const { UTMCampaign, UTMMedium, UTMSource } = getUTMValues() || {};
    if (UTMCampaign && UTMMedium && UTMSource) {
      fetchMaskU(UTMCampaign, UTMSource, UTMMedium, site, defaultPhone, setPhone);
      return;
    }
  }
  setPhone(defaultPhone);
  return;
};
