import styled from 'styled-components';

const StyledSectionTitle = styled.span`
  margin: 0;
  font-weight: bold;
  line-height: 1;
  font-size: ${({ as, variant }) => as === 'h2' && variant === 'secondary' && '40px'};
  direction: ltr;
  .h2-en,
  &.h2-en {
    font-size: ${({ as, variant }) => as === 'h2' && variant === 'secondary' && '40px'};
  }

  ${({ theme }) => theme.bps?.mobile} {
    font-size: ${({ variant }) => (variant === 'primary' ? '40px' : '25px')};
  }
`;

export default StyledSectionTitle;
