import StyledModelsListItem from './StyledModelListItem';
import propTypes from 'prop-types';
import Image from 'core/image';
import Link from 'core/link';

const ModelsListItem = ({ modelFields, uri }) => {
  const { modelGeneralFields, menuDetails } = modelFields || {};
  const { modelName } = modelGeneralFields || {};
  const { description, linkText, extraDetails, imageFields } = menuDetails || {};

  return (
    <StyledModelsListItem>
      <div className='model-content'>
        {modelName && <Link href={uri} className='text-space title'>{modelName}</Link>}
        {description && <div className='text-space description' dangerouslySetInnerHTML={{ __html: description }}/>}
        {linkText && <Link href={uri} className='text-space model-link'>{linkText}</Link>}
        {extraDetails && <div dangerouslySetInnerHTML={{ __html: extraDetails }}/>}
      </div>

      <div className='model-image'>
        <Image {...imageFields}/>
      </div>
    </StyledModelsListItem>
  );
};

ModelsListItem.propTypes = {
  modelFields: propTypes.object,
  uri: propTypes.string,
};

export default ModelsListItem;
