
'use client';
import propTypes from 'prop-types';
import useIsMobile from 'hooks/useIsMobile';
import Image from './Image';

const ResponsiveImage = ({mobile, desktop, className, ...rest}) => {
  const isMobile = useIsMobile();
  if(isMobile && (!!mobile || !!rest?.src)) return <Image {...mobile} className={className} responsive={true} {...rest} />;
  else if (!isMobile || !mobile) return <Image {...desktop} className={className} {...rest} />;
};

ResponsiveImage.propTypes = {
  mobile: propTypes.object,
  desktop: propTypes.object,
  className: propTypes.string
};

export default ResponsiveImage;
