import styled from 'styled-components';

const StyledApSafetyTemplate = styled.div`
  .ctr {
    ${({theme}) => theme.mixins?.shrink};
    margin-top: 22px;

    .title {
      font-weight: 700;
      font-size: 32px;
    }
  }
`;
export default StyledApSafetyTemplate;
