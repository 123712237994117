'use client';

import propTypes from 'prop-types';
import Media from '../Media';
import SectionTitle from '../SectionTitle';
import StyledHeroBanner from './StyledHeroBanner';

const HeroBanner = ({
  title,
  mediafields,
  descriptionJSX,
  fontSize,
  fontSizeMobile = fontSize,
  titleColor,
  mediaHeight,
  underBannerText,
}) => {
  return (
    <StyledHeroBanner mediaHeight={mediaHeight} titleFontSize={fontSize} titleColor={titleColor} fontSizeMobile={fontSizeMobile}>
      <div className='content'>
        <SectionTitle as='h1' className='title'>
          {title}
        </SectionTitle>
        {descriptionJSX && descriptionJSX}
        {underBannerText && <div className="bottom-text" dangerouslySetInnerHTML={{__html: underBannerText}} />}
      </div>
      <Media className={mediafields?.type === 'video' ? 'background responsive-video' : 'background'} {...mediafields} isAutoplay={true} isMuted={true} responsive={true} displayControlsPanel={true} priority={true} />
    </StyledHeroBanner>
  );
};

HeroBanner.propTypes = {
  title: propTypes.string.isRequired,
  mediafields: propTypes.object,
  description: propTypes.string,
  descriptionJSX: propTypes.node,
  titleColor: propTypes.string,
  buttons: propTypes.array,
  fontSize: propTypes.number,
  fontSizeMobile: propTypes.number,
  layout: propTypes.object,
  mediaHeight: propTypes.oneOf(['fullHeight','auto']),
  bannerType: propTypes.string,
  underBannerText: propTypes.string,
  model: propTypes.object
};

export default HeroBanner;
