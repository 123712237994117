'use client';
import useIsMobile from 'hooks/useIsMobile';
import propTypes from 'prop-types';
import location from 'public/icons/location.svg';
import StyledFooter from './StyledFooter';
import Link from 'core/link';
import Image from 'core/image';
import { useState } from 'react';
import ModalLegal from '@/UIComponents/ModalLegal';

const ClientFooter = ({ menuItems = {}, footerSettings = {} }) => {
  const isMobile = useIsMobile();
  const { logos = {}, legalRemarks = '', socialMediaList = [], targetPlace = '' } = footerSettings || {};
  const { logoColmobil = {}, logoSmartFooter = {} } = logos || {};
  const { menu } = menuItems || {};
  const [isModalShown, setIsModalShown] = useState(false);

  const LEGAL_RIGHTS = '© כל הזכויות שמורות';
  const LOCATION = 'ישראל';
  const READ_MORE = 'קרא עוד »';

  const linksJSX = menu?.map((group, index) => (
    <div key={index} className={'group ' + group.classes?.join(' ')}>
      <ul className="items-list">
        {group.subItems?.map(({ label, classes, uri }, index) => (
          <li key={index} className={'item ' + classes?.join(' ')}>
            <Link href={uri || ''}>{label}</Link>
          </li>
        ))}
      </ul>
    </div>
  ));

  const socialNetworksJSX = socialMediaList?.map(({ link, iconImage }, index) => {
    const { alt = 'social', src = '' } = iconImage || {};
    return (
      <Link key={index} href={link} className="social-link" rel="me" target="_blank">
        <Image alt={alt} src={src || ''} width={24} height={24} />
      </Link>
    );
  });

  const handleModalClose = () => setIsModalShown(false);
  const handleModalOpen = () => setIsModalShown(true);

  return (
    <StyledFooter>
      <div className="container">
        <div className="menu">
          <div className="logo">
            <Image
              alt={logoSmartFooter?.logoWhite.alt || ''}
              src={logoSmartFooter?.logoWhite.src || ''}
              width={70}
              height={95}
            />
          </div>
          {linksJSX}
        </div>

        <div className="bottom">
          <div className="colmobil-logo">
            <Image
              alt={logoColmobil.logoWhite.alt || 'colmobil'}
              src={logoColmobil.logoWhite.src || ''}
              width={isMobile ? 90 : 122}
              height={isMobile ? 32 : 43}
            />
          </div>
          {isMobile && (
            <Link href={targetPlace} className="localisation">
              <Image {...location} width={16} height={16} />
              <span className="label">{LOCATION}</span>
            </Link>
          )}
          {!isMobile && <div className="social-links">{socialNetworksJSX}</div>}
        </div>

        {!isMobile && (
          <Link href={targetPlace} className="localisation">
            <Image {...location} width={20} height={20} />
            <span className="label">{LOCATION}</span>
          </Link>
        )}

        {isMobile && <div className="social-links">{socialNetworksJSX}</div>}

        <span className="legal-right">{LEGAL_RIGHTS}</span>

        <div className="remarks" dangerouslySetInnerHTML={{ __html: legalRemarks }} />
        <span className="read-more" onClick={handleModalOpen}>
          {READ_MORE}
        </span>
      </div>

      {isModalShown && <ModalLegal isMobile={isMobile} isModalShown={isModalShown} setIsModalOpen={handleModalClose} />}
    </StyledFooter>
  );
};

ClientFooter.propTypes = {
  menuItems: propTypes.object,
  footerSettings: propTypes.object
};

export default ClientFooter;
