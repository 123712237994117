'use client';

import StyledButton from './StyledButton';
import propTypes from 'prop-types';
import { Button } from '@/UIComponents/Button';

const ClientButton = ({ order, layout, buttonFields }) => {
  return (
    <StyledButton id={order} $layout={layout}>
      <Button className={'button'} {...buttonFields}>
        {buttonFields.label}
      </Button>
    </StyledButton>
  );
};

ClientButton.propTypes = {
  layout: propTypes.object,
  order: propTypes.oneOfType([propTypes.string, propTypes.number]),
  buttonFields: propTypes.object
};

export default ClientButton;
