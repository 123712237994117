
'use client';

import Media from '@/UIComponents/Media';
import StyledFinishCard from './StyledCard';
import propTypes from 'prop-types';
import Link from 'next/link';
import SectionTitle from '@/UIComponents/SectionTitle';

const Card = ({imageFields, cardText, cardTitle, link, linkText}) => {
  const {uri = ''} = link || {};

  return (
    <StyledFinishCard>
      <div className='card-content'>
        <div className="card-image">
          <Media {...imageFields} responsive />
        </div>
        <div className='card-bottom'>
          <SectionTitle type="h3" className="card-title">{cardTitle}</SectionTitle>
          <div className="card-desc">{cardText}</div>
          <div className='card-link'>
            <Link href={uri}>{linkText}</Link>
          </div>
        </div>
      </div>
    </StyledFinishCard>
  );
};

Card.propTypes = {
  imageFields: propTypes.shape({
    desktop: propTypes.object,
    mobile: propTypes.object,
  }),
  cardText: propTypes.string,
  cardTitle: propTypes.string,
  link: propTypes.shape({
    uri: propTypes.string
  }),
  linkText: propTypes.string
};

export default Card;
