'use client';

import StyledFinishDetails from './StyledFinishDetails';
import SectionTitle from '@/UIComponents/SectionTitle';
import propTypes from 'prop-types';
import useIsMobile from '../../../hooks/useIsMobile';
import Media from '@/UIComponents/Media';
import { Button } from '@/UIComponents/Button';
import Link from 'core/link';
import { useState } from 'react';
import ModalCarBook from '@/UIComponents/ModalCarBook';

const ClientFinishDetails = ({
  title,
  description,
  downloadCarbook,
  downloadCatalog,
  buttons,
  mediaPosition,
  media,
  order,
  layout,
  selectedFinish
}) => {
  const isMobile = useIsMobile();
  const [isModalShown, setIsModalShown] = useState(false);
  const { ConfigurationPrices = {}, finishFields = {} } = selectedFinish || {};
  const { vehicletotalpricewvat = '', vehiclelicensefee = '' } = ConfigurationPrices || {};
  const { modelFields = {}, modelId } = finishFields?.parentModel || {};
  const {mediaItemUrl: technicalSpecsFileLink} = modelFields?.technicalSpecsFile || {};
  const carBookFile = modelFields?.carbooks?.[0]?.file;
  return (
    <StyledFinishDetails mediaPosition={mediaPosition} id={order} $layout={layout}>
      <Media responsive={!!isMobile} {...media} width={635} height={606} />

      <div className="info-ctr">
        {title && (
          <SectionTitle as="h3" className="title" variant="secondary">
            {title}
          </SectionTitle>
        )}
        {vehicletotalpricewvat && (
          <SectionTitle as="h6" className="min-price" variant="secondary">
            ₪ החל מ - {parseInt(vehicletotalpricewvat).toLocaleString('en-US')}
          </SectionTitle>
        )}
        {vehiclelicensefee && (
          <span className="fee">בתוספת אגרת רישוי {parseInt(vehiclelicensefee).toLocaleString('en-US')} ₪</span>
        )}
        {description && <div className="description" dangerouslySetInnerHTML={{ __html: description }} />}
        {buttons?.map(({ button }, index) => (
          <div className="button" key={index}>
            <Button {...button}>{button?.label}</Button>
          </div>
        ))}
        <div className={'links'}>
          {technicalSpecsFileLink && downloadCatalog && (
            <Link className={'link'} href={technicalSpecsFileLink}>
              {downloadCatalog}
            </Link>
          )}
          {carBookFile && downloadCarbook && (
            <Link className={'link'} onClick={() => setIsModalShown(true)}>
              {downloadCarbook}
            </Link>
          )}
        </div>
        {isModalShown && (
          <ModalCarBook
            carBookFile={carBookFile}
            modelId={modelId}
            isModalShown={isModalShown}
            setIsModalShown={setIsModalShown}
          ></ModalCarBook>
        )}
      </div>
    </StyledFinishDetails>
  );
};

ClientFinishDetails.propTypes = {
  media: propTypes.object,
  title: propTypes.string.isRequired,
  tags: propTypes.array,
  description: propTypes.string,
  downloadCarbook: propTypes.string,
  downloadCatalog: propTypes.string,
  buttons: propTypes.array,
  mediaPosition: propTypes.string,
  coords: propTypes.object,
  infoType: propTypes.string,
  layout: propTypes.object,
  order: propTypes.oneOfType([propTypes.string, propTypes.number]),
  selectedFinish: propTypes.object
};

export default ClientFinishDetails;
