
'use client';

import { useState } from 'react';
import StyledFinishColorsSection from './StyledFinishColorsSection';
import propTypes from 'prop-types';
import SectionTitle from '@/UIComponents/SectionTitle';
import ColorsComponent from './ColorsComponent';
import Media from '@/UIComponents/Media';
import SafetyAndPollutionStrip from '@/UIComponents/SafetyAndPollutionStrip';

const FinishColorsSection = ({title, finishFields, generalText, layout}) => {
  const {airPollutionFields, safetyFields, colorsfields } = finishFields || {};
  const {exteriorColorsTitle, innerColorsTitle, exteriorColorDetails, interiorColorDetails} = colorsfields || {};
  const {pollutionLevel: airPollutionLevel} = airPollutionFields || {};
  const {safetyLevel} = safetyFields || {};

  const [colorsType, setColorsType] = useState('exterior');
  const [selectedVideo, setSelectedVideo] = useState(null);
  const handleClick = (type) => {
    setColorsType(type);
  };
  return (
    <StyledFinishColorsSection $layout={layout}>
      <div className='colors-section-main'>
        <div className='video-wrap'>
          <Media
            type='video'
            buttonStyle='column' 
            iconsColorType='outline'
            controlsBottom={103}
            mobileControlsBottom={113}
            minHeight={selectedVideo?.desktop?.sizes?.height}
            {...selectedVideo} 
            isAutoplay={true}
            isMuted={true}
          />
        </div>
        <div className='top-wrap'>
          <SectionTitle as={'h2'}>{title}</SectionTitle>
          <div className="colors-tabs">
            <div className={`tab ${colorsType === 'inner' ? 'selected' :''}`} onClick={()=>handleClick('inner')}>{innerColorsTitle}</div>
            <div className={`tab ${colorsType === 'exterior' ? 'selected' :''}`} onClick={()=>handleClick('exterior')}>{exteriorColorsTitle}</div>
          </div>
        </div>
        <div className='colors-content'>
          <ColorsComponent colorsLength={exteriorColorDetails?.length || 0} colorsType={colorsType} colorsDetails={colorsType === 'inner' ? interiorColorDetails : exteriorColorDetails} setSelectedVideo={setSelectedVideo} selectedVideo={selectedVideo} />
        </div>
      </div>
      <div className='colors-ap-safety'>
        <div className='bottom-text'>{generalText}</div>
        <SafetyAndPollutionStrip safetyLevels={[safetyLevel]} pollutionLevels={[airPollutionLevel]} showMoreDetailsLink brand='smart'/>
      </div>
    </StyledFinishColorsSection>
  );
};

FinishColorsSection.propTypes = {
  title: propTypes.string,
  Finishcolors: propTypes.object,
  finishFields: propTypes.object,
  layout: propTypes.object,
  generalText: propTypes.string,
};

export default FinishColorsSection;
