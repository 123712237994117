import { E_DAYS_HEBREW } from './enums';

export const isTimeBetween = (start, end) => {
  const now = new Date();
  const startTime = new Date(now.toDateString() + ' ' + start);
  const endTime = new Date(now.toDateString() + ' ' + end);
  const currentTime = now.getTime();
  return currentTime >= startTime.getTime() && currentTime <= endTime.getTime();
};


export const isTimeAndDateInOpeningHours = (openingHours) => {
  if (!openingHours) return false;

  const now = new Date();
  let dayOfWeek = now.toLocaleDateString('en-us', { weekday: 'long' }); // Sunday = 0, Monday = 1, ..., Saturday = 6
  dayOfWeek = dayOfWeek.toLowerCase();

  const isOpen = openingHours?.map((opening)=>{    
    const {day} = opening ||{};
    if (day === dayOfWeek) {
      const { openTime = '', closeTime = '' } = opening || {};
      return isTimeBetween(openTime, closeTime);      
    }
    return false;
  }).some(Boolean);

  return isOpen;
};

export const workingDays = (openingHours = {}) => {
  let prevTime = null;
  let prevDay = null;
  let openingHoursText = '';
  let startDay = '';
  let endDay = '';

  for (const day in openingHours) {
    const time = `${openingHours[day].openTime} - ${openingHours[day].closeTime}`;

    if (prevTime === time) {
      if (!prevDay) {
        startDay = day;
      } else {
        endDay = day;
      }
    } else {
      if (startDay && endDay) {
        openingHoursText += `${E_DAYS_HEBREW[startDay]}'-${E_DAYS_HEBREW[endDay]}' ${prevTime}`;
      }
      startDay = day;
      endDay = '';
      if (prevDay) {
        openingHoursText += ` ${E_DAYS_HEBREW[day]}' ${time}`;
      }
    }
    prevDay = day;
    prevTime = time;
  }
  return <p>{openingHoursText}</p>;
};

