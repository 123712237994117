import styled from 'styled-components';

const StyledColmobilCropIconsModal = styled.div`
  position: relative;
  .close-btn {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 10px;
    left: 10px;
    border: none;
    background: transparent;
  }
`;

export default StyledColmobilCropIconsModal;
