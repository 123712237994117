import styled from 'styled-components';

const StyledSeparator = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  ${({ theme }) => theme.mixins?.layout};
  width: 2px;
  height: ${({ height }) => height && height}px;
  background-color: ${({ theme }) => theme.colors?.dark};
  position: relative;

  .yellow-part {
    background-color: #d7e600;
    position: relative;
    width: 2px;
    filter: drop-shadow(0 0 16px rgba(215, 230, 0, 0.4)) drop-shadow(0 0 2px rgba(215, 230, 0, 0.8));
    max-height: ${({ height }) => height && height}px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 4px;
      height: 8px;
      margin: auto;
      left: -1px;

      filter: blur(2px);
      border-radius: 50%;
      background-color: #d7e600;
    }
  }
`;

export default StyledSeparator;
