'use client';

import { useState } from 'react';
import StyledInputRadio from './StyledInputRadio';
import propTypes from 'prop-types';

const Input = ({ label = '', isError = false, className = 'input-radio' }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <StyledInputRadio isChecked={isChecked} className={className} isError={isError}>
      <span className='checkmark' onClick={()=>setIsChecked(!isChecked)}></span>
      <label className="label-input">{label}</label>
    </StyledInputRadio>
  );
};

Input.propTypes = {
  label: propTypes.string,
  isError: propTypes.bool,
  errorMessage: propTypes.string,
  className: propTypes.string
};

export default Input;
