import styled from 'styled-components';
import { smartSans } from '../../../styles/fonts';

const StyledInformationBannerTopText = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  ${({ theme }) => theme.mixins?.layout};

  display: flex;
  flex-direction: ${({ mediaPosition }) => (mediaPosition === 'left' ? 'row-reverse' : 'unset')};
  gap: 160px;

  .title {
    font-family: ${({ theme }) => theme.fonts?.smartSans};
  }

  .button {
    margin-top: 10px;
  }

  ${({ theme }) => theme.bps?.mobile} {
    gap: 43px;
    flex-direction: column-reverse;
    .info-ctr {
      text-align: center;
    }
  }

  .description {
    margin: 29px 0 68px;
    p {
      font-size: 21px;
    }
  }
`;

export default StyledInformationBannerTopText;
