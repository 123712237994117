import { useTheme } from 'styled-components';
import LevelStrip from './LevelStrip';
import propTypes from 'prop-types';
import StyledSafetyAndPollutionStrip from './StyledSafetyAndPollutionStrip';
import STRIP_CONSTANTS from './StripConstants';
import {uniq} from "lodash";

const SafetyAndPollutionStrip = ({safetyLevels = [], pollutionLevels = [], order, showMoreDetailsLink = false, brand = 'colmobil'} ) => {
  const {colors} = useTheme() || {};
  const {safety, pollution} = colors || {};
  const constants = STRIP_CONSTANTS(brand);

  return (
    <StyledSafetyAndPollutionStrip id={`ui-sap-strip-${order}`}>
      <div className='container'>
        {safetyLevels.length > 0 && (
          <div className='safety'>
            <div className='label'>{constants.SAFETY_LABEL}</div>
            <LevelStrip levels={uniq(safetyLevels?.sort())} colorsPalette={safety} />
          </div>
        )}

        {pollutionLevels.length > 0 && (
          <div className='pollution'>
            <div className='label'>{constants.POLLUTION_LABEL}</div>
            <LevelStrip levels={uniq(pollutionLevels?.sort())} colorsPalette={pollution} />
          </div>
        )}
      </div>
      {showMoreDetailsLink && <div className="more-details">{constants.MORE_DETAILS}</div>}
    </StyledSafetyAndPollutionStrip>
  );
};

SafetyAndPollutionStrip.propTypes = {
  safetyLevels: propTypes.array.isRequired,
  pollutionLevels: propTypes.array.isRequired,
  order: propTypes.number,
  showMoreDetailsLink : propTypes.bool,
  brand: propTypes.string
};

export default SafetyAndPollutionStrip;
