import propTypes from 'prop-types';
import useIsMobile from 'hooks/useIsMobile';
import Modal from '@/UIComponents/Modal';
import Image from 'core/image';
import { Button } from '@/UIComponents/Button';
import StyledColmobilCropIconsModal from './StyledModalColmobilBrands';
import closeIconBlack from 'public/icons/close-black.svg';

const ModalColmobilBrands = ({ isModalOpen = false, setIsModalOpen, colmobilGrpImage }) => {
  const isMobile = useIsMobile();
  const WIDTH = isMobile ? 312 : 382;
  const HEIGHT = isMobile ? 475 : 580;

  const desktop = {
    alt: colmobilGrpImage?.altText || '',
    src: colmobilGrpImage?.mediaItemUrl || ''
  };

  const modalStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.6)'
    },
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      borderRadius: '0',
      padding: '0'
    }
  };

  return (
    <Modal isShown={isModalOpen} handleClose={() => setIsModalOpen(false)} styles={modalStyles}>
      <StyledColmobilCropIconsModal>
        <Button variant="text" className="close-btn" onClick={() => setIsModalOpen(false)}>
          <Image src={closeIconBlack || ''} alt="close" fill responsive={false} />
        </Button>
        <Image desktop={desktop} width={WIDTH} height={HEIGHT} responsive={false} />
      </StyledColmobilCropIconsModal>
    </Modal>
  );
};

ModalColmobilBrands.propTypes = {
  isModalOpen: propTypes.bool,
  colmobilGrpImage: propTypes.object,
  setIsModalOpen: propTypes.func
};

export default ModalColmobilBrands;
