import styled from 'styled-components';

const StyledSpecificationAndCarbook = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  ${({ theme }) => theme.mixins?.layout};

  display: flex;
  flex-direction: column;

  .content {
    font-size: 21px;
    font-family: ${({ theme }) => theme.fonts?.ploni};
    margin: 0 auto 40px auto;

    ${({ theme }) => theme.bps?.desktop} {
      width: 50%;
    }

    ${({ theme }) => theme.bps?.mobile} {
      font-size: 16px;
      margin: 0 auto 30px auto;
    }
  }

  .buttons {
    justify-content: center;
    display: flex;

    ${({ theme }) => theme.bps?.desktop} {
      gap: 26px;
    }

    .link {
      cursor: pointer;
      font-size: 13px;
      font-family: ${({ theme }) => theme.fonts?.ploni};
      font-weight: 600;
      line-height: 0.7;
      text-align: center;
      margin: 0 15px;
      color: ${({ theme }) => theme.colors?.black};
      border-bottom: 1px solid ${({ theme }) => theme.colors?.black};
    }
  }
`;

export default StyledSpecificationAndCarbook;
