import styled from 'styled-components';

const StyledContentWithActions = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  ${({ theme }) => theme.mixins?.layout};

  display: flex;
  flex-direction: column;

  .content {
    font-size: 21px;
    font-family: ${({ theme }) => theme.fonts?.ploni};
    margin: 0 auto 40px auto;

    ${({ theme }) => theme.bps?.desktop} {
      width: 50%;
    }

    ${({ theme }) => theme.bps?.mobile} {
      font-size: 16px;
      margin: 0 auto 30px auto;
    }
  }

  .buttons {
    justify-content: center;
    display: flex;

    ${({ theme }) => theme.bps?.desktop} {
      gap: 26px;
    }
  }
`;

export default StyledContentWithActions;
