'use client';

import StyledSeparator from './StyledSeparator';
import propTypes from 'prop-types';
import { useRef } from 'react';

import { motion, useScroll, useSpring, useTransform } from 'framer-motion';

const ClientSeparator = ({ order, layout, height = 235 }) => {
  const scrollRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: scrollRef,
    offset: ['end end', 'start start']
  });

  const heightSpring = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  const heightPercentage = useTransform(heightSpring, [0, 1], [0, height*2]);

  return (
    <div className='separator-wrapper' style={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <StyledSeparator 
        ref={scrollRef}
        id={order} 
        height={height} 
        $layout={layout} 
      >
        <motion.div className="yellow-part" style={{ height: heightPercentage }} />
      </StyledSeparator>
    </div>
  );
};

ClientSeparator.propTypes = {
  layout: propTypes.object,
  order: propTypes.oneOfType([propTypes.string, propTypes.number]),
  height: propTypes.number
};

export default ClientSeparator;
