import propTypes from 'prop-types';
import StyledModelsMenu from './StyledModelsMenu';
import ModelsListItem from './ModelListItem/ModelListItem';

const ModelsMenu = ({ modelsList, isModelsMenuOpen, handleModelsMenuClick }) => {
  const ModelsMenuJSX = modelsList?.map((model)=> <ModelsListItem {...model} key={model?.id} />);

  return (
    <StyledModelsMenu isModelsMenuOpen={isModelsMenuOpen}>
      {modelsList?.length > 0 && (
        <>
          <div className='menu-button' onClick={handleModelsMenuClick}>
            <div className='text'>דגמים</div>
            <div className='arrow-down'></div>
          </div>
          <div className='menu' onMouseLeave={handleModelsMenuClick}>{ModelsMenuJSX}</div>
        </>
      )}
    </StyledModelsMenu>
  )
  ;
};

ModelsMenu.propTypes = {
  modelsList: propTypes.array,
  isModelsMenuOpen: propTypes.bool,
  handleModelsMenuClick: propTypes.func
};

export default ModelsMenu;
