import styled from 'styled-components';

const StyledItemInfos = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .title-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .title {
      margin: 0;
      font-size: 33px;
      font-weight: bold;
      text-align: right;
      color: ${({ theme }) => theme.colors?.dark};
      margin-left: 20px;

      ${({ theme }) => theme.bps?.mobile} {
        font-size: 20px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: 0.2px;
      }
    }

    .icon-waze {
      margin-bottom: 5px;
    }
  }

  .text {
    margin: 0;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.33;
    text-align: right;
    color: ${({ theme }) => theme.colors?.dark};

    ${({ theme }) => theme.bps?.mobile} {
      font-size: 16px;
      font-weight: normal;
      line-height: 1.4;
      letter-spacing: 0.16px;
    }

    p {
      margin: 0;
      margin-bottom: 10px;
      font-size: 21px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      text-align: right;
      color: ${({ theme }) => theme.colors?.dark};

      ${({ theme }) => theme.bps?.mobile} {
        font-size: 16px;
        font-weight: normal;
        line-height: 1.4;
        letter-spacing: 0.16px;
      }
    }
    p:last-child {
      margin: 0;
    }
  }

  .label {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: 0.2px;

    ${({ theme }) => theme.bps?.mobile} {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.12;
    }
  }

  .phone-container {
    display: flex;
    flex-direction: row;

    .phone {
      margin-right: 5px;
      text-decoration: underline;
    }
  }
`;

export default StyledItemInfos;
