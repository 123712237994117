'use client';

import Breadcrumb from '@/UIComponents/Breadcrumb';
import StyledDefaultTemplate from './StyledDefaultTemplate';
import propTypes from 'prop-types';

const ClientDefaultTemplate = ({ sections, breadcrumbs }) => {

  return (
    <StyledDefaultTemplate>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      {sections}
    </StyledDefaultTemplate>
  );
};

ClientDefaultTemplate.propTypes = {
  sections: propTypes.node,
  breadcrumbs: propTypes.array
};

export default ClientDefaultTemplate;
