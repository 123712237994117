'use client';

import { Button } from '@/UIComponents/Button';
import StyledExampleSection from './StyledExampleSection';
import { useState } from 'react';
import ModalColmobilBrands from '@/UIComponents/ModalColmobilBrands/ModalColmobilBrands';

const ClientExampleSection = () => {
  const TEXT = 'הידע והנתונים המופיעים באתר זה מתייחסים למגוון דגמים המשווקים במקומות שונים בעולם ומעודכנים למועד הבאת המקור הלועזי לתרגום. ייתכנו הבדלים בין התיאור המובא באתר זה לבין הדגמים המשווקים על-ידי חברתנו, הן מבחינת המפרט הטכני והן מבחינת האביזרים, הציוד והמערכות הנלוות. כמו כן ייתכנו הבדלים בדגם מסויים, בהתאם לשינויים הנעשים מזמן לזמן. חלק מהאביזרים ו/או המערכות המפורטים באתר זה מצוי רק בחלק מדגמי הרכבים, אין בפרסום המובא באתר זה כדי לחייב את היצרן ו/או את החברה בהספקת דגמים שיכללו את כל או חלק מהאביזרים, הציוד והמערכות המתוארים בו והם שומרים לעצמם את הזכות לבטל, להוסיף, לשנות ולשפר, ללא הודעה מוקדמת וללא עידכון באתר זה. התמונות באתר הינן להמחשה בלבד. האתר כפוף לשינוי ללא הודעה מוקדמת. ט.ל.ח.';
  const [isOpenDialogBox, setIsOpenDialogBox] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <StyledExampleSection>
      <div className="buttons">
        <Button variant="primary" onClick={()=>setIsOpenDialogBox(true)}>OPEN DIALOG BOX</Button>
        <Button variant="outlined">OUTLINED</Button>
        <Button variant="disabled">NEGATIVE=DISABLED</Button>
        <Button onClick={() => setIsModalOpen(!isModalOpen)}>
          מודל מותגי כלמוביל
        </Button>
        <ModalColmobilBrands isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />

      </div>
      <div className='example-empty' />
      <div className='example-empty' id='contactus-form'><h3>Contact Us Form</h3></div>

    </StyledExampleSection>
  );
};

export default ClientExampleSection;
