'use client';
import StyledButton from './StyledButton';
import propTypes from 'prop-types';
import {VARIANT_TYPES} from 'utils/generalProptypes';

const ButtonAction = ({ variant = 'primary', ...props }) => {
  return <StyledButton as="button" variant={variant} {...props} />;
};

ButtonAction.propTypes = {
  variant: propTypes.oneOf(VARIANT_TYPES),
  props: propTypes.object
};

export default ButtonAction;
