import styled from 'styled-components';

const StyledBulletsText = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  ${({ theme }) => theme.mixins?.layout};
  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }
`;

export default StyledBulletsText;
