'use client';
import StyledInformationBanner from './StyledInformationBanner';
import propTypes from 'prop-types';
import { Button } from '@/UIComponents/Button';
import Media from '@/UIComponents/Media';
import SectionTitle from '@/UIComponents/SectionTitle';
import useIsMobile from 'hooks/useIsMobile';

const ClientInformationBanner = ({ title, description, buttonFields, mediaPosition, media, order, layout }) => {
  const isMobile = useIsMobile();
  return (
    <StyledInformationBanner mediaPosition={mediaPosition} id={order} $layout={layout}>
      <Media responsive={!!isMobile} {...media} width={635} height={606} />
      <div className="info-ctr">
        {title && (
          <SectionTitle as='h3' className="title" variant="secondary">
            {title}
          </SectionTitle>
        )}
        {description && <div className="description">{description}</div>}
        {buttonFields?.label && <Button {...buttonFields}>{buttonFields?.label}</Button>}
      </div>
    </StyledInformationBanner>
  );
};

ClientInformationBanner.propTypes = {
  media: propTypes.object,
  title: propTypes.string.isRequired,
  tags: propTypes.array,
  description: propTypes.string,
  buttonFields: propTypes.object,
  mediaPosition: propTypes.string,
  coords: propTypes.object,
  infoType: propTypes.string,
  layout: propTypes.object,
  order: propTypes.oneOfType([propTypes.string, propTypes.number]),
};

export default ClientInformationBanner;
