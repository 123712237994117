import Link from 'core/link';
const STRIP_CONSTANTS = (brand) => {
  const link = `www.${brand}.co.il`;
  return {
    SAFETY_LABEL: 'רמת אבזור בטיחותי:',
    POLLUTION_LABEL: 'דרגת זיהום אוויר:',
    MORE_DETAILS: <span className='more-details-link'>לפרטים: <Link href='/'>{link}</Link></span>,
  };
};

export default STRIP_CONSTANTS;