import styled from 'styled-components';

const StyledTimeLineContentSection = styled.div`
  ${({ theme }) => theme.mixins?.layout};

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 180px;
    .section-title {
      display: block;
      font-weight: 400;
      line-height: 110%;
      letter-spacing: -0.02em;
      font-size: 124px;
      text-align: center;
      overflow-wrap: break-word;
      margin: 0;
    }
  }
  .bottom {
    display: flex;
    height: 880px;
    .media {
      flex-shrink: 0;
      margin-bottom: 0;
    }
    .timeline-list {
      display: flex;
      align-self: stretch;
      flex-direction: column;
      align-items: flex-start;
      width: 880px;
      .list-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-left: 12px;
        margin-right: 12px;
        max-width: 70%;
        .timeline-item {
          display: flex;
          position: relative;
          flex-direction: column;
          padding-bottom: 80px;

          .item-title-wrapper {
            display: flex;
            flex-direction: row;
            margin-bottom: 22px;
            align-items: flex-start;
            gap: 19px;
            .item-number {
              width: 24px;
              height: 24px;
              border-radius: 999px;
              color: ${({ theme }) => theme.colors?.white};
              background-color: ${({ theme }) => theme.colors?.dark};
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 15px;
              font-size: 20px;
            }
            .item-title {
              display: block;
              overflow-wrap: break-word;
              color: ${({ theme }) => theme.colors?.dark};
              margin: 0;
              font-size: 63px;
              width: 100%;
            }
          }
          .item-content {
            font-size: 21px;
            font-weight: 500;
            text-align: right;
            margin-right: 43px;
            color: ${({ theme }) => theme.colors?.dark};
          }
          &:not(:last-child) {
            &::after {
              content: '';
              width: 2px;
              height: 100%;
              position: absolute;
              top: 15px;
              right: 10px;
              background: ${({ theme }) => theme.colors?.dark};
              z-index: -1;
            }
          }
        }
      }
    }
  }
  ${({ theme }) => theme.bps?.mobile} {
    ${({ theme }) => theme.mixins?.shrink};

    .top {
      gap: 35px;
      .section-title {
        font-size: 63px;
      }
      margin-bottom: 36px;
    }
    .bottom {
      flex-direction: column-reverse;
      height: unset;
      gap: 64px;
      .timeline-list {
        width: 100%;
        align-items: center;

        .list-wrapper {
          max-width: unset;
          margin: 0;
          align-items: center;
          .timeline-item {
            padding-bottom: 65px;

            &:not(:last-child) {
              &::after {
                content: unset;
              }
            }
            .item-title-wrapper {
              flex-direction: column;
              align-items: center;
              gap: 8px;
              margin-bottom: 15px;

              .item-title {
                font-size: 25px;
                text-align: center;
              }
              .item-number {
                margin: 0;
                font-size: 16px;
                padding-top: 3px;
              }
            }
            .item-content {
              font-size: 16px;
              margin-right: unset;
              text-align: center;
            }
          }
        }
      }
    }
  }
`;

export default StyledTimeLineContentSection;
