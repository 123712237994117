
'use client';
import ReactModal from 'react-modal';
import propTypes from 'prop-types';

const defaultStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const Modal = ({ isShown, handleClose, children, styles = defaultStyles, isWithOverlay = true, className ='' }) => {
    
  return (
    <ReactModal
      isOpen={isShown}
      onRequestClose={handleClose}
      style={styles}
      ariaHideApp={false}
      className={className}
    >
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  isShown:propTypes.bool,
  handleClose:propTypes.func,
  styles:propTypes.object,
  children:propTypes.node,
  className: propTypes.string
};

export default Modal;
