import styled from 'styled-components';

const StyledApSafetyRules = styled.div`
  p {
    margin: 0;
  }

  .infection-level {
    font-weight: bold;
    margin: 15px 0;
    display: flex;
    flex-direction: row;
    position: relative;

    ${({theme}) => theme.bps?.mobile} {
      margin: 22px 0 60px;
    }

    .air-pol-max {
      border-top: 1px solid ${({theme}) => theme.colors?.pollution[15]};

      ${({theme}) => theme.bps?.mobile} {
        position: absolute;
        top: 35px;
      }
    }

    .container-boxes {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin: 0 1px;

      ${({theme}) => theme.bps?.mobile} {
        width: 100%;
      }
    }

    .air-pol-min {
      border-top: 1px solid ${({theme}) => theme.colors?.pollution[1]};

      ${({theme}) => theme.bps?.mobile} {
        position: absolute;
        top: 35px;
        left: 0;
      }
    }
  }

  .air-pollution-comments {
    margin-bottom: 22px;
  }
`;

export default StyledApSafetyRules;

export const StyledColoredBox = styled.div`
  height: 22px;
  margin: 0 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({theme}) => theme.colors?.black};
  width: 31.5px;
  line-height: 20px;
  background-color: ${({theme, pollutionDegree}) => theme.colors?.pollution[pollutionDegree]};

  ${({theme}) => theme.bps?.mobile} {
    width: 100%;
    max-width: 31.5px;
  }
`;
