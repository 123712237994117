import { styled } from 'styled-components';

const StyledFinishCard = styled.li`
  position: relative;
  justify-content: center;
  display: flex;
  height: 382px;

  ${({ theme }) => theme?.bps?.mobile} {
    width: 100%;
    max-width: ${({ theme }) => `${theme.minWidth?.mobile}px`};
    margin: 0 0 24px 0;
    height: 341px;
  }

  .finish-card-image {
    width: auto;
    height: 100%;

    ${({ theme }) => theme.bps?.mobile} {
      width: 100%;
      height: auto;
    }
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.25) 50%,
      rgba(0, 0, 0, 0.08),
      rgba(0, 0, 0, 0)
    );

    ${({ theme }) => theme.bps?.mobile} {
      max-width: ${({ theme }) => `${theme.minWidth?.mobile}px`};
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .price-title {
      font-size: 16px;
      line-height: 18px;
      color: ${({ theme }) => theme.colors?.white};
      margin-top: 7px;
    }

    .finish-name,
    .starting-price,
    .license-fee {
      text-shadow: 0 0 12px rgba(0, 0, 0, 0.65);
      color: ${({ theme }) => theme.colors?.white};
    }

    .finish-name {
      font-size: 25px;
      margin: 32px 0 0.2px 0;

      ${({ theme }) => theme?.bps?.mobile} {
        font-size: 16px;
        margin: 24px 0 4px 0;
      }
    }

    .starting-price {
      font-size: 16px;
      letter-spacing: 0.16px;
      line-height: 22.4px;
      margin: 0 0 0.2px 0;

      ${({ theme }) => theme?.bps?.mobile} {
        margin: 0 0 4px 0;
      }
    }

    .license-fee {
      font-size: 11px;
      margin: 0;
    }
  }

  .cta-icon {
    width: 32px;
    height: 32px;
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export default StyledFinishCard;
