import styled from 'styled-components';

const StyledInputText = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 456px;
  width: 100%;

  ${({ theme }) => theme.bps?.mobile} {
    max-width: 278px;
  }

  .label-input {
    font-size: 13px;
    line-height: 1.2;
    letter-spacing: 0.12px;
    text-align: right;
    color: ${({ theme, isError }) => (isError ? theme.colors?.error : theme.colors?.dark)};

    ${({ theme }) => theme.bps?.mobile} {
    }
  }

  .input,
  .textarea {
    border: none;
    border-bottom: 1px solid ${({ theme, isError }) => (isError ? theme.colors?.error : theme.colors?.dark)};
    padding: 8px 0;
    outline: none;
    font-size: 16px;
    line-height: 1.4;
    letter-spacing: 0.16px;
    text-align: right;
    color: ${({ theme }) => theme.colors?.black};
  }
  .textarea {
    resize: none;
  }

  .error-message {
    margin-top: 5px;
    font-size: 9px;
    line-height: 1.2;
    text-align: right;
    color: ${({ theme }) => theme.colors?.error};
  }
`;

export default StyledInputText;
