
'use client';

import StyledColorCircle from './StyledColorCircle';
import propTypes from 'prop-types';

const ColorCircle = ({colorName, upperColor, bottomColor, video, setSelectedVideo, isColorSelected}) => {
  
  return (
    <StyledColorCircle upperColor={upperColor} bottomColor={bottomColor} isColorSelected={isColorSelected}>
      <div className='color-circle' onClick={()=>setSelectedVideo(video)}>
        <div className='color-name'>{colorName}</div>
        <div className='circle-wrap'>
          <div className="upper" />
          <div className="bottom" />
        </div>
      </div>
    </StyledColorCircle>
  );
};

ColorCircle.propTypes = {
  colorName: propTypes.string,
  upperColor: propTypes.string,
  bottomColor: propTypes.string,
  video: propTypes.object,
  setSelectedVideo: propTypes.func,
  isColorSelected: propTypes.bool

};

export default ColorCircle;
