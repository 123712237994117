'use client';

import propTypes from 'prop-types';
import { useState, useEffect } from 'react';
import useIsMobile from 'hooks/useIsMobile';
import ContactForm from '@/UIComponents/ContactForm';
import SectionTitle from '@/UIComponents/SectionTitle';
import Image from '@/Core/Image/Image';
import SectionBgDesktop from 'public/images/contactUsSectionBgDesktop.png';
import SectionBgMobile from 'public/images/contactUsSectionBgMobile.jpg';
import contactValue from 'utils/contactValue';
import StyledContactUsSection from './StyledContactUsSection';
import useContactUs from 'providers/useContactUs';

const ClientContactUsSection = ({ order, layout, formFields, formDisplayData }) => {
  const isMobile = useIsMobile();
  const [leadResponse, setLeadResponse] = useState({});

  const { title, titleColor, fontSizeMobile, fontSize, description, contactOptions, colmobilGrpImage } =
    formDisplayData || {};

    const titleFontSize = isMobile && fontSizeMobile ? fontSizeMobile : fontSize;
  const { isFormSubmitted = false } = leadResponse || {};

  const contactOptionsJSX = contactOptions?.map((contactOption, index) => {
    const contactElement = contactValue(contactOption);
    return (
      <div className="contact-option" key={index}>
        {contactElement}
      </div>
    );
  });
  const bgImage = isMobile ? SectionBgMobile : SectionBgDesktop;

  const {contactUsData: {contactUsFilled}} = useContactUs() || {};
  useEffect(() => {
    if(!contactUsFilled) {
      setLeadResponse({isFormSubmitted: false});
    }
  }, [contactUsFilled]);

  return (
    <StyledContactUsSection
      isFormSubmitted={isFormSubmitted}
      titleColor={titleColor}
      titleSize={titleFontSize}
      id={order}
      $layout={layout}
    >
      <div className="background-image">
        <Image className="image" {...bgImage} responsive={true} />
      </div>
      <div className="content">
        {!isFormSubmitted && (
          <>
            <SectionTitle className="section-title">{title}</SectionTitle>
            {description && <div className="description" dangerouslySetInnerHTML={{ __html: description }} />}
          </>
        )}

        <ContactForm
          leadResponse={leadResponse}
          setLeadResponse={setLeadResponse}
          formFields={formFields}
          colmobilGrpImage={colmobilGrpImage}
        />
        <div className="contact-options">{contactOptionsJSX}</div>
      </div>
    </StyledContactUsSection>
  );
};

ClientContactUsSection.propTypes = {
  layout: propTypes.object,
  order: propTypes.oneOfType([propTypes.string, propTypes.number]),
  formFields: propTypes.object,
  formDisplayData: propTypes.object
};

export default ClientContactUsSection;
