'use client';

import StyledItemInfos from './StyledItemInfos';
import waze from 'public/icons/waze.svg';
import propTypes from 'prop-types';
import Image from '@/Core/Image/Image';
import Link from 'core/link';

const ItemInfos = ({ infos = {} }) => {
  const HOURS_LABEL = 'שעות פתיחה:';
  const CONTACT_US_LABEL = 'צור קשר:';
  const PHONE_LABEL = 'טלפון:';

  const { address = {}, openHours = '', phone: {phoneToCall = '', phoneToDisplay = ''} = {}, contactUs = '', title = '' } = infos || {};

  return (
    <StyledItemInfos>
      <div className="title-container">
        <h3 className="title">{title}</h3>
        <Link href={`https://waze.com/ul?q=${address.latitude},${address.longitude}&navigate=yes`} className="text"><Image {...waze} className='icon-waze' width={23} height={20} /></Link>
      </div>
      <Link href={`http://maps.google.com/?q=${address?.streetName} ${address?.streetNumber}, ${address?.city} ${address?.country}`} className="text">{`${address?.streetName} ${address?.streetNumber}, ${address?.city}`}</Link>
      <p className="text label">{HOURS_LABEL}</p>
      <div className='text' dangerouslySetInnerHTML={{ __html: openHours }}></div>
      <p className="text label">{CONTACT_US_LABEL}</p>
      <div className='phone-container'>
        <p className="text">{PHONE_LABEL}</p>
        <Link href={`tel:${phoneToCall}`} className="text phone">{phoneToDisplay}</Link>
      </div>
      <div className='text' dangerouslySetInnerHTML={{ __html: contactUs }}></div>
    </StyledItemInfos>
  );
};

ItemInfos.propTypes = {
  infos: propTypes.object
};

export default ItemInfos;
