import styled from 'styled-components';
const StyledSelectBox = styled.label`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${({ size }) => (size === 'big' ? '100' : '48')}%;
  .label-input {
    font-size: 13px;
    line-height: 1.2;
    letter-spacing: 0.12px;
    text-align: right;
    color: ${({ theme, isError }) => (isError ? theme.colors?.error : theme.colors?.dark)};
  }
  .select-wrapper {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
  }
`;
export default StyledSelectBox;
