import styled from 'styled-components';

const StyledPopupPrivacyCookies = styled.div`
  width: 100%;
  padding: 14px 59px 14px 59px;
  box-shadow: 0 -11px 24.3px 0 rgba(191, 191, 191, 0.25);
  background-color: ${({ theme }) => theme.colors?.black};
  position: fixed;
  bottom: 0;
  z-index: 1000;
  display: ${({ isPrivacyCookies }) => (isPrivacyCookies ? 'none' : 'flex')};
  flex-direction: row;
  justify-content: space-between;
  align-items: center;


  ${({ theme }) => theme.bps?.mobile} {
    padding: 13px 19px 12px 8px;
    align-items: initial;
    column-gap: 21px;
  }

  .text {
    font-size: 18px;
    line-height: 1;
    color: ${({ theme }) => theme.colors?.white};
    margin: 0;

    ${({ theme }) => theme.bps?.mobile} {
      font-size: 12px;
    }
  }

  .privacy-text {
    text-decoration: underline;
    cursor: pointer;
    font-size: 18px;
    color: ${({ theme }) => theme.colors?.white};

    ${({ theme }) => theme.bps?.mobile} {
      font-size: 12px;
    }
  }

  .button {
    min-width: 100px;
    background-color: ${({ theme }) => theme.colors?.white};
    color: ${({ theme }) => theme.colors?.black};
    font-size: 16px;
    font-weight: normal;
  }

  .close-button {
    width: 26px;
    height: 14px;
    cursor: pointer;

    img {
      width: initial;
    }
  }
`;

export default StyledPopupPrivacyCookies;
