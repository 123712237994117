'use client';
import StyledImageInformationLegal from './StyledImageInformationLegal';
import propTypes from 'prop-types';
import Image from 'core/image';
import {responsiveMediaItemPropTypes} from '../../../utils/generalProptypes';

const ClientImageInformationLegal = ({ imageFields, title, description, mediaPosition, layout, order, legalText, detailsBoxes }) => {
  return (
    <StyledImageInformationLegal mediaPosition={mediaPosition} id={order} $layout={layout}>
      <>
        <div className='content'>
          <div className='image'>
            <Image responsive {...imageFields} />
          </div>

          <div className="info-ctr">
            {title && <h3 className="title">{title}</h3>}
            {description && <div className="description" dangerouslySetInnerHTML={{__html: description}}/>}
            {detailsBoxes?.length > 0 && <div className='details'>
              {detailsBoxes.map(detail => (
                <div key={detail?.boxTitle} className='detail'>
                  <div className='detail-title'>{detail?.boxTitle}</div>
                  <div className='detail-description'>{detail?.boxDescription}</div>
                </div>
              ))}
            </div>}
          </div>
        </div>
        {legalText && <p dangerouslySetInnerHTML={{__html: legalText}}></p>}
      </>
    </StyledImageInformationLegal>
  );
};

ClientImageInformationLegal.propTypes = {
  layout: propTypes.object,
  imageFields: responsiveMediaItemPropTypes,
  order: propTypes.string,
  title: propTypes.string,
  description: propTypes.string,
  mediaPosition: propTypes.string,
  legalText: propTypes.string,
  detailsBoxes: propTypes.arrayOf(propTypes.shape({
    boxTitle: propTypes.string,
    boxDescription: propTypes.string
  }))
};

export default ClientImageInformationLegal;