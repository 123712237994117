import styled, { css } from 'styled-components';
import 'react-video-seek-slider/styles.css';

const StyledVideo = styled.figure`
  display: flex;
  position: relative;
  margin: 0;

  video {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors?.black};
    min-height: ${({ minHeight }) => (minHeight !== 0 ? `${minHeight}px` : 'initial')};

    ${({ theme }) => theme.bps?.desktop} {
      ${({ $width, $height }) => css`
        height: ${$height}px;
      `}
    }
  }

  video::backdrop {
    position: initial;
  }

  figcaption {
    display: flex;
    flex-direction: ${({ buttonStyle }) => (buttonStyle === 'row' ? 'row-reverse' : 'column')};
    row-gap: ${({ buttonStyle }) => (buttonStyle === 'column' ? '20px' : 'initial')};
    align-items: center;
    height: fit-content;
    width: 100%;
    justify-content: center;
    visibility: inherit;
    position: static;
    cursor: pointer;

    .button-video:hover {
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.05);
    }

    .button-video {
      ${({ theme }) => theme.bps?.mobile} {
        width: 35px;
        height: 35px;
        aspect-ratio: 1;
      }
    }

    .progress-wrapper {
      position: relative;
      width: ${({ progressBarWidth }) => progressBarWidth};
      height: 20px;
      margin: 0 22px;

      ${({ theme }) => theme.bps?.mobile} {
        margin: 0 10px;
      }
    }

    .progress-bar {
      width: 100%;
      height: 2px;
      background-color: ${({ theme }) => theme.colors?.white};
      direction: ltr;
      position: absolute;

      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
    }

    .ui-video-seek-slider .track .main .connect {
      background-color: ${({ theme }) => theme.colors?.oliveBright};
    }
    .ui-video-seek-slider .thumb .handler {
      background-color: ${({ theme }) => theme.colors?.oliveBright};
      display: ${({ buttonStyle }) => (buttonStyle !== 'row' ? 'none' : '')};
    }
    .ui-video-seek-slider .track .main:before {
      background-color: ${({ theme, buttonStyle }) =>
        buttonStyle === 'row' ? theme.colors?.white : theme.colors?.dark2Gray};
    }
    .ui-video-seek-slider .track .main .buffered {
      background-color: transparent;
    }

    .sound-icon {
      margin-right: 22px;
      ${({ theme }) => theme.bps?.mobile} {
        margin-right: 10px;
      }
    }
  }

  .btn-wrapper {
    bottom: ${({ buttonStyle, $controlsBottom }) => (buttonStyle === 'centered' ? '50%' : `${42 + $controlsBottom}px`)};
    position: absolute;
    z-index: 2;

    ${({ theme }) => theme.bps?.mobile} {
      bottom: ${({ buttonStyle, $mobileControlsBottom }) =>
        buttonStyle === 'centered' ? '45%' : `${10 + $mobileControlsBottom}px`};
      padding: 0 23px;
    }

    .btn {
      display: flex;
    }
  }
`;

export default StyledVideo;
