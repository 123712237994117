
'use client';
import StyledSectionTitle from './StyledSectionTitle';
import propTypes from 'prop-types';

const SectionTitle = ({type = 'h2', variant = 'primary', children, className='', ...props}) => {
  return (
    <StyledSectionTitle as={type} variant={variant} className={className} {...props}>
      {children}
    </StyledSectionTitle>
  );
};

SectionTitle.propTypes = {
  type: propTypes.string,
  variant: propTypes.oneOf(['primary', 'secondary']),
  children: propTypes.node,
  className: propTypes.string
};

export default SectionTitle;
