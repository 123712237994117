'use client';
import styled from 'styled-components';

const StyledSuccessMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .infos-container {
    text-align: center;
    margin-bottom: 24px;

    ${({ theme }) => theme.bps?.mobile} {
      margin-bottom: 16px;
    }

    &-title {
      margin: 0;
      font-size: 63px;
      font-weight: bold;
      margin-bottom: 8px;
    }
    &-message {
      margin: 0;
      font-size: 21px;
      font-weight: 500;
      padding: 0 180px;
    }
    ${({ theme }) => theme.bps?.mobile} {
      &-title {
        font-size: 25px;
      }
      &-message {
        font-size: 13px;
        font-weight: normal;
        padding: 0 38.4px;
      }
    }
  }
`;

export default StyledSuccessMessage;
