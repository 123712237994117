
'use client';

import propTypes from 'prop-types';
import Media from '@/UIComponents/Media';
import SectionTitle from '@/UIComponents/SectionTitle';
import useIsMobile from 'hooks/useIsMobile';
import ClientSeparator from '../Separator/ClientSeparator';
import StyledTimeLineContentSection from './StyledTimeLineContentSection';

const ClientTimeLineContentSection = ({title, media, timeLineList = [], order}) => {
  const isMobile = useIsMobile();
  const sizesProp = isMobile ? {} : {width:880,height:880};
  
  const timeLineListJSX = timeLineList?.map(({timeLineTitle, timeLineContent}, index)=>{
    return (
      <div className='timeline-item' key={index}>
        <div className='item-title-wrapper'>
          <div className='item-number'>{index + 1}</div>
          <span className='item-title'>{timeLineTitle}</span>
        </div>
        <div className='item-content'>{timeLineContent}</div>
      </div>
    );
  });

  return (
    <StyledTimeLineContentSection id={order}>
      <div className='top'>
        <SectionTitle className='section-title'>{title}</SectionTitle>
        {isMobile && <ClientSeparator/>} 
      </div>
      <div className='bottom'>
        <div className='timeline-list'>
          <div className='list-wrapper'>
            {timeLineListJSX}
          </div>
        </div>
        <div className='media'>
          <Media responsive={!!isMobile} {...media} {...sizesProp} />
        </div>
      </div>
    </StyledTimeLineContentSection>
  );
};

ClientTimeLineContentSection.propTypes = {
  title:propTypes.string,
  media:propTypes.object,
  timeLineList:propTypes.array,
  order: propTypes.string,
};

export default ClientTimeLineContentSection;
