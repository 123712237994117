'use client';

import StyledModalLegal from './StyledModalLegal';
import Modal from '@/UIComponents/Modal';
import Image from 'core/image';
import { Button } from '@/UIComponents/Button';
import closeBlack from 'public/icons/close-x-black.svg';
import propTypes from 'prop-types';

const ModalLegal = ({ isModalShown = false, setIsModalOpen, isMobile }) => {
  const modalStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      bottom: 'auto',
      right: 'auto',
      width: isMobile ? '90%' : '618px',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'rgb(255, 255, 255)',
      borderRadius: '13px',
      boxShadow: '0 1px 28.7px 0 rgba(0, 0, 0, 0.17);',
      fontSize: '16px',
      lineHeight: '1.4',
      padding: '35px 38.3px 34.7px 38px'
    }
  };
  const CONTENT =
    'הידע והנתונים המופיעים באתר זה מתייחסים למגוון דגמים המשווקים במקומות שונים בעולם ומעודכנים למועד הבאת המקור הלועזי לתרגום. ייתכנו הבדלים בין התיאור המובא באתר זה לבין הדגמים המשווקים על-ידי חברתנו, הן מבחינת המפרט הטכני והן מבחינת האביזרים, הציוד והמערכות הנלוות. כמו כן ייתכנו הבדלים בדגם מסויים, בהתאם לשינויים הנעשים מזמן לזמן. חלק מהאביזרים ו/או המערכות המפורטים באתר זה מצוי רק בחלק מדגמי הרכבים, אין בפרסום המובא באתר זה כדי לחייב את היצרן ו/או את החברה בהספקת דגמים שיכללו את כל או חלק מהאביזרים, הציוד והמערכות המתוארים בו והם שומרים לעצמם את הזכות לבטל, להוסיף, לשנות ולשפר, ללא הודעה מוקדמת וללא עידכון באתר זה. התמונות באתר הינן להמחשה בלבד. ייתכנו הבדלים בין הנתונים והתמונות המוצגים לבין הדגמים המשווקים בישראל, הן מבחינת המפרט הטכני והן מבחינת האביזרים והציוד.';

  return (
    <Modal isShown={isModalShown} handleClose={() => setIsModalOpen(false)} styles={modalStyles}>
      <StyledModalLegal>
        <Button variant="text" className="close-btn" onClick={() => setIsModalOpen(false)}>
          <Image src={closeBlack || ''} alt={'close'} fill responsive={false} />
        </Button>
        <div className="footer-modal-content">{CONTENT}</div>
      </StyledModalLegal>
    </Modal>
  );
};

ModalLegal.propTypes = {
  isModalShown: propTypes.bool,
  setIsModalOpen: propTypes.func,
  isMobile: propTypes.bool
};

export default ModalLegal;
