'use client';
import styled from 'styled-components';

const StyledErrorMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors?.dark?.green};

  .icon-error {
    position: relative;
    width: 58px;
    height: 34px;
    margin-bottom: 32px;

    ${({ theme }) => theme.bps?.mobile} {
      margin-bottom: 24px;
    }
  }

  .infos-container {
    text-align: center;
    margin-bottom: 16px;

    ${({ theme }) => theme.bps?.mobile} {
      margin-bottom: 8px;
    }

    &-title {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .btn-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
    font-size: 16px;
  }
`;

export default StyledErrorMessage;
