'use client';

import { createContext, useContext } from 'react';
import propTypes from 'prop-types';

const ContactUsContext = createContext();

export const ContactUsProvider = ({ value, children }) => {
  return <ContactUsContext.Provider value={value}>{children}</ContactUsContext.Provider>;
};
  
const useContactUs = () => {
  const context = useContext(ContactUsContext);
  
  if (context === undefined) {
    throw new Error('This provider no longer exists, all relevant data goes to the server component'); //! //! ONLY after update to App Router
  }
  
  return context;
};

ContactUsProvider.propTypes = {
  value: propTypes.object.isRequired,
  children: propTypes.node.isRequired,
};
    
export default useContactUs;