import moment from 'moment';

moment.updateLocale('he', {
  months: [
    'ינואר', 'פברואר', 'מרץ', 'אפריל', 'מאי', 'יוני', 'יולי',
    'אוגוסט', 'ספטמבר', 'אוקטובר', 'נובמבר', 'דצמבר'
  ],
  weekdays: [
    'ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'
  ],
  relativeTime: {
    future: 'בעוד %s',
    past: 'לפני %s',
    s: 'מספר שניות',
    ss: '%d שניות',
    m: 'דקה אחת',
    mm: '%d דקות',
    h: 'שעה אחת',
    hh: '%d שעות',
    d: 'יום אחד',
    dd: '%d ימים',
    w: 'שבוע אחד',
    ww: '%d שבועות',
    M: 'חודש אחד',
    MM: '%d חודשים',
    y: 'שנה אחת',
    yy: '%d שנים'
  }
});

export default moment;
