export const numberWithCommas = (number = 0, isPrice = false, numbersAfterDot = 0) => {
  const convertedNumber = isPrice ? Math.ceil(Number(number || 0)) : Number(number || 0);
  return (
    (isPrice ? '₪' : '') +
    convertedNumber
      .toFixed(numbersAfterDot)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  );
};

export const maskPhoneNumber = (phoneNumber) => {
  let num = phoneNumber;
  if (typeof num !== 'string') {
    num = '0' + String(num);
  }
  const firstThreeDigits = num.substring(0, 3);
  const lastFourDigits = num.substring(6);
  const maskedPhoneNumber = `${firstThreeDigits}***${lastFourDigits}`;
  return maskedPhoneNumber;
};

export const removeSpecialChars = (text = '') => text?.replace(/[&\/\\#,+()$~@%=.'":*?<>{}]/g, '');
