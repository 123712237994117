import React from 'react';
import propTypes from 'prop-types';

const FINISH_TITLE = 'רמת גימור';

const Finishes = ({finishesArr, selectedFinish, updateFinishName}) => {
  return (
    <div className='finishes-ctr'>
      <div className='mini-title'>{FINISH_TITLE}</div>
      <div className='finishes'>
        {finishesArr?.map(finish => <div key={finish?.finishName} className={`finish-name ${selectedFinish === finish?.finishName ? 'active' : ''}`} onClick={() => updateFinishName(finish?.finishName)}>{finish?.finishName}</div>)}
      </div>
    </div>
  );
};

Finishes.propTypes = {
  finishesArr: propTypes.array,
  selectedFinish: propTypes.string,
  updateFinishName: propTypes.func,
};

export default Finishes;