import styled from 'styled-components';

const StyledInfoBar = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 16px;
  background: ${(props) => props.color || 'rgb(0, 57, 122)'};
  height: 35px;
  color: white;
  font-weight: 500;
  border-bottom: ${(props) => props.theme.borders?.primary};
  text-align: center;

  .item a,
  .item span,
  span,
  a {
    color: white;
  }

  .preview-stauts {
    font-weight: 600;
    color: ${({ isPreviewMode }) => (isPreviewMode ? '#a7f995' : 'red')};
  }

  .bold {
    font-weight: 900;
  }
  .preview-disable {
    cursor: pointer;
    text-decoration: underline;
  }

  .link {
    background-color: unset;
    padding: unset;
    border: none;
    color: white;
    font-family: ${({ theme }) => theme.fonts?.text};
    font-size: 18px;
    cursor: pointer;
  }

  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: ${(props) => props.margin || '3px'};
    padding: 0;
    li {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 16px;
      border-right: ${(props) => props.theme.borders?.primary};

      font-size: ${(props) => props.fontSize || '14px'};
      font-weight: ${(props) => props.fontWeight || 400};

      &:first-child {
        border-right: 0;
        padding-right: 0;
      }

      &:last-child {
        border-left: 0;
      }

      a {
        display: flex;
        align-items: center;

        &:hover {
          font-weight: 600;
        }

        span {
          padding-right: 6px;
        }
      }
    }
  }
  ${({ theme }) => theme.bps?.mobile} {
    display: none;
  }
`;

export default StyledInfoBar;
