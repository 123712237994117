// Configurations and imports
import revalidatePath from './revalidatePath';
import { revalidate } from 'core/aws/cloudfront';

const DEFAULT_WAITING_TIME = 5; 

const waitingHandler = () => new Promise(resolve => setTimeout(resolve, DEFAULT_WAITING_TIME * 1000));

const revalidateOnNext = async (path) => {
  const res = await revalidatePath(path);
  if (res?.status !== 200) throw res;
  await waitingHandler();
};

const revalidateOnCloudfront = async (path) => {
  const res = await revalidate(path);
  if(res?.status !== 200) throw res;
};

export const revalidatePage = async ({ path, setReavalidateStatus, window }) => {

  try {
    setReavalidateStatus('מעדכן נתוני עמוד...');
    await revalidateOnNext(path, setReavalidateStatus);
    await revalidateOnCloudfront(path, setReavalidateStatus);

    setReavalidateStatus('התהליך הושלם בהצלחה, מרענן דף...');
    window.location.reload();
    
  } catch ({status, message}) {
    setReavalidateStatus(`ERROR ${status}: ${message}`);
  }
};