'use client';

import Link from 'core/link';
import propTypes from 'prop-types';
import StyledBreadcrumb from './StyledBreadcrumb';

const Breadcrumb = ({ variant = 'default', items = [], breadcrumbs = [] }) => {
  
  const link = (title, href) => {
    
    return <li className='path-item'>{href ? <Link href={href}>{title}</Link> : title}</li>;
  };

  //** in case of home page there are two items in breadcrumbs of homepage, so it removes it
  const removeObjectsWithSameValue = (array, key) => {
    const uniqueValues = new Set();
    return array?.filter((obj) => {
      if (uniqueValues?.has(obj[key])) {
        return false;
      }
      uniqueValues?.add(obj[key]);
      return true;
    });
  };

  const arrow = '>';
  const filteredBreadcrumbs = removeObjectsWithSameValue(breadcrumbs, 'text');
  const mappedItems = items?.map((item) => ({ text: item, url: '/' })) || [];
  const breadcrumbsWithItems = [...filteredBreadcrumbs, ...mappedItems];
  const breadcrumbsList = breadcrumbsWithItems?.map(({ text, url }, index) => {
    if (index === breadcrumbsWithItems.length - 1) {
      return link(text, '');
    } else {
      return link(text, url);
    }
  });
  const breadcrumbsListJSX = breadcrumbsList?.map((item, index) =>
    item && index !== 0 ? (
      <span key={index} className="path">
        {arrow} {item}
      </span>
    ) : (
      <span key={index} className="root-path">
        {item}
      </span>
    )
  );

  if (breadcrumbsListJSX.length === 1) return null;

  return (
    <StyledBreadcrumb variant={variant}>
      <div className="container">{breadcrumbsListJSX}</div>
    </StyledBreadcrumb>
  );
};

export default Breadcrumb;

Breadcrumb.propTypes = {
  variant: propTypes.oneOf(['default', 'underBanner']),
  items: propTypes.array,
  breadcrumbs: propTypes.array
};
