import styled from 'styled-components';

const StyledMediaSection = styled.div`
  ${({ theme }) => theme.mixins?.layout};

  max-width: 100%;

  .container {
    ${({ isShrink, theme }) => (!isShrink ? theme.mixins?.shrink : '')};
    position: relative;
    text-align: center;
    color: white;

    & > div {
      width: 100%;

      video,
      img {
        width: 100%;
        height: auto;
      }
    }

    .btn {
      width: fit-content;
      margin: 0 auto;
    }

    .centered {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .title {
        font-size: 63px;
        margin-bottom: 24px;

        ${({ theme }) => theme.bps?.mobile} {
          font-size: 25px;
        }
      }

      .description {
        padding: 0;
        margin: 0 0 24px 0;
      }
    }
  }
`;

export default StyledMediaSection;
