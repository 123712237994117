import styled from 'styled-components';

const StyledTextWithTitle = styled.div`
  ${({ theme }) => theme.mixins?.shrink};
  ${({ theme }) => theme.mixins?.layout};
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;

  .ctwt-title {
    font-size: ${({ $titleTextSize }) => `${$titleTextSize?.desktop}px`};

    ${({ theme }) => theme.bps?.mobile} {
      font-size: ${({ $titleTextSize }) => `${$titleTextSize?.mobile}px`};
    }
  }

  .content {
    p,
    pre {
      width: 100%;
      height: 100%;
      font-size: 21px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      text-align: right;
      margin: 76.2px 0 15px 0;

      ${({ theme }) => theme.bps?.mobile} {
        font-size: 16px;
        margin: 15px 0 0 0;
      }
    }
  }
`;

export default StyledTextWithTitle;
