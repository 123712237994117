import React, { useRef, useEffect, useState } from 'react';
import StyledAdvertisingAllowanceComponent from './StyledAdvertisingAllowanceComponent';
import propTypes from 'prop-types';
import CheckBox from '@/UIComponents/CheckBox';
import { CONSTANTS } from '../../Constants';
import ModalColmobilBrands from '@/UIComponents/ModalColmobilBrands';

const AdvertisingAllowanceComponent = ({
  inputAttributes = {},
  register = () => {},
  errors = {},
  errorText = '',
  activeInput = {},
  setActiveInput = () => {},
  value,
  colmobilGrpImage
}) => {
  const componentReference = useRef(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  let { [inputAttributes.name]: isError } = Object(errors);
  isError = !!isError;
  const { name: inputAttributeName } = inputAttributes ?? {};
  const [initialValue, setInitialValue] = useState(!!value || activeInput[inputAttributeName]);
  useEffect(() => {
    if (value) {
      handleClickInputEvt(inputAttributes.name, !!value);
    }
  }, [value]);
  useEffect(() => {
    if (componentReference.current) {
      //not first time
      componentReference.current = false;
    }
  }, [isError]);
  const handleClickInputEvt = (e, value = null) => {
    const tempState = {
      ...activeInput,
      [e]: !activeInput[inputAttributeName]
    };
    setInitialValue(!activeInput[inputAttributeName]);
    setActiveInput(tempState);
  };
  const handleModalToggle = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <StyledAdvertisingAllowanceComponent errors={errors}>
      <div className={'float-label'}>
        <div className="checkbox-item">
          <CheckBox
            id={inputAttributeName}
            setValue={() => handleClickInputEvt(inputAttributeName)}
            value={initialValue}
            name={inputAttributeName}
            register={register}
            label={
              <>
                {CONSTANTS.DISCLAIMER_APPROVE}
                <span className="clickable-crop-icons-text" onClick={handleModalToggle}>
                  {CONSTANTS.COLMOBIL_CORP}
                </span>
              </>
            }
          />
        </div>
      </div>
      <span className="input-required label-error">{errorText}</span>
      {isModalOpen && (
        <ModalColmobilBrands
          colmobilGrpImage={colmobilGrpImage}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </StyledAdvertisingAllowanceComponent>
  );
};
AdvertisingAllowanceComponent.propTypes = {
  isActive: propTypes.bool,
  register: propTypes.func.isRequired,
  isError: propTypes.bool,
  errors: propTypes.object,
  inputAttributes: propTypes.shape({
    default: propTypes.string,
    name: propTypes.string.isRequired,
    labelLink: propTypes.string.isRequired
  }),
  errorText: propTypes.string,
  activeInput: propTypes.object,
  setActiveInput: propTypes.func,
  value: propTypes.string,
  colmobilGrpImage: propTypes.object
};
export default AdvertisingAllowanceComponent;
