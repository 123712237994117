import styled from 'styled-components';
const StyledFilledTabs = styled.div`
  display: inline-flex;
  border-radius: 36px;
  font-weight: bold;
  border: 1px solid ${({ theme }) => theme?.colors?.white};
  color: ${({ theme }) => theme?.colors?.white};
  cursor: pointer;

  ${({ theme }) => theme.bps.mobile} {
    display: flex;
    max-width: 197px;
    margin: 0 auto 78px;
  }

  .option {
    padding: 10px 32px;
    border-radius: 36px;
    font-size: 14px;

    ${({ theme }) => theme.bps.mobile} {
      padding: 6px 30px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  .not-active:hover {
    text-shadow: 0 0 1px ${({ theme }) => theme?.colors?.dark};
    cursor: pointer;
  }

  .active {
    color: ${({ theme }) => theme?.colors?.dark};
    background-color: ${({ theme }) => theme?.colors?.white};
  }
`;
export default StyledFilledTabs;
