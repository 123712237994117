import { m } from 'framer-motion';

const colors = {
  veryLightGray: '#fafafa',
  white: '#fff',
  black: '#000',
  dark: '#141413',
  divDefault: '#494d50',
  defaultTransparent: '#494d5050',
  pressedGray: '#babfc4',
  pressedLightGray: '#d5d8da',
  disabled: '#6b747b',
  darkTransparent: '#14141340',
  lightGray: '#eaebed',
  dark2Gray: '#595959',
  standartGray: '#606569',
  darkGray: '#585d61',
  brightGreen: '#32d74b',
  olive: '#c4d031',
  oliveLight:'#d1df10',
  oliveBright:'#d7e600',
  oliveTransparent:'#d7e60060',
  lightOrange:'#f7bf31',
  focusBlue: '#034ec8',
  error: '#e64040',
  carBlue: '#144e8f',
  carRed: '#db1a27',
  carBlack: '#242528',
  carGreen: '#c5d4ca',
  carYellow: '#fff760',
  carGray: '#788396',
  tableBackground: '#f6f3f2',
  tableBorders: '#bf2126',

  buttons: {
    primaryHover: '#363636',
    primaryPress: '#494d50',
    outlinedHover: '#f2f2f2',
    outlinedPress: '#ebebeb',
    disabled: '#36393b21',
    disabledHover: '#969da320',
    disabledPress:'#969da330'
  },

  //? Safety and pollution colors:
  safety: {
    0: '#a72c32',
    1: '#f15a22',
    2: '#ffcb05',
    3: '#26b78d',
    4: '#3189a0',
    5: '#248bcb',
    6: '#1b6fb7',
    7: '#4270b7',
    8: '#435daa',
  },

  pollution: {
    1: '#015e35',
    2: '#007f42',
    3: '#03924a',
    4: '#009f4d',
    5: '#04aa4c',
    6: '#0cab4c',
    7: '#81c241',
    8: '#f4d218',
    9: '#fbc63f',
    10: '#f6a048',
    11: '#f37526',
    12: '#ee6624',
    13: '#ec2238',
    14: '#ea222a',
    15: '#bd2227',
  },
};

export default colors;