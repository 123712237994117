'use client';
import StyledApSafety from './StyledApSafety';
import theme from 'styles/theme';
import { APSafetySection } from '@colmobil/colmolib/minify/APSafetySection';
import propTypes from 'prop-types';

const ClientApSafety = ({ order, layout, modelGeneralFields, finishes }) => {
  const { modelName, modelEngineType, modelStandard } = modelGeneralFields || {};
  const modelLines = finishes?.map(({ finish: { title: finishName = '', finishFields = {} } }) => {
    const { airPollutionFields = {}, safetyFields = {} } = finishFields || {};
    const {
      pollutionLevel = null,
      lineElectricDistanceRange: finishBatteryRange = null,
      lineFuelAverageConsumption: finishFuelAverageConsumption,
      lineElectricUsage: finishElectricUsage
    } = airPollutionFields;
    const { safetyLevel = null, finishCode = null, items: safetyFieldsList = {} } = safetyFields;
    const {
      activePathControl,
      cruiseControl,
      isa,
      airBags,
      collisionPrevention,
      alcoholLocks,
      auotBreak,
      emergencyBrakes,
      reverseCamera,
      reverseAutoStop,
      dAlert,
      driverDistractionAlert,
      childrenForgetAlert,
      openDoorAlert
    } = safetyFieldsList;

    const lineFields = {
      title: finishName,
      engineType: modelEngineType,
      code: finishCode,
      batteryRange: finishBatteryRange,
      fuelConsCombined: finishFuelAverageConsumption,
      safetyFields: {
        air_bags: {
          label: 'כריות אוויר',
          order: 1,
          value: airBags
        },
        active_path_control: {
          label: 'מערכת אקטיבית למניעת סטיה מנתיב',
          order: 2,
          value: activePathControl
        },
        auot_break: {
          label: 'מערכת בלימה אוטומטית בעת חירום',
          order: 3,
          value: auotBreak
        },
        emergency_brakes: {
          label: 'מערכת לבלימת חירום מפני הולכי רגל ורוכבי אופניים',
          order: 4,
          value: emergencyBrakes
        },
        reverse_camera: {
          label: 'מערכת זיהוי כלי רכב ב"שטח מת"',
          order: 5,
          value: reverseCamera
        },
        revention: {
          label: 'מערכת אקטיבית למניעת התנגשות צד ב"שטח מת"',
          order: 6,
          value: collisionPrevention
        },
        reverse_auto_stop: {
          label: 'בלימה אוטומטית בנסיעה לאחור',
          order: 7,
          value: reverseAutoStop
        },
        d_alert: {
          label: 'מערכת התרעה במצב של עייפות נהג',
          order: 8,
          value: dAlert
        },
        driver_distraction_alert: {
          label: 'מערכת התרעה במצב של היסח דעת הנהג',
          order: 9,
          value: driverDistractionAlert
        },
        children_forget_alert: {
          label: 'מערכת עזר לנהג להתרעה על השארת ילדים ברכב',
          order: 10,
          value: childrenForgetAlert
        },
        cruise_control: {
          label: 'בקרת שיוט אדפטיבית',
          order: 11,
          value: cruiseControl
        },
        isa: {
          label: 'בקרת מהירות חכמה (ISA)',
          order: 12,
          value: isa
        },
        alcohol_locks: {
          label: 'הכנה להתקנת מנעולי אלכוהול',
          order: 13,
          value: alcoholLocks
        },
        open_door_alert: {
          label: 'מערכת אזהרת פתיחת דלת',
          order: 14,
          value: openDoorAlert
        },
        level: safetyLevel
      },
      airPollutionFields: {
        level: pollutionLevel,
        engine_type: {
          label: 'סוג מנוע',
          order: 1,
          value: modelEngineType
        },
        line_electric_usage: {
          label: 'טווח נסיעה חשמלית (וואט שעה / ק"מ)',
          order: 1,
          value: finishElectricUsage
        },
        line_electric_distance_range: {
          label: 'טווח נסיעה חשמלית (ק"מ)',
          order: 2,
          value: finishBatteryRange
        }
      }
    };
    return lineFields;
  });

  const modelMappedData = {
    title: modelName,
    modelGeneralFields: {
      modelStandard,
      engineType: modelEngineType,
      lines: modelLines
    }
  };

  return (
    <StyledApSafety id={order} $layout={layout}>
      <APSafetySection data={{ model: modelMappedData }} themeProvider={theme} siteName="smart" />
    </StyledApSafety>
  );
};

ClientApSafety.propTypes = {
  layout: propTypes.object,
  order: propTypes.oneOfType([propTypes.string, propTypes.number]),
  modelGeneralFields: propTypes.object,
  finishes: propTypes.array
};

export default ClientApSafety;
