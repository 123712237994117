import React from 'react';
import Image from 'core/image';
import propTypes from 'prop-types';

const COLOR_TITLE = 'צבע';

const Colors = ({ finishColors, updateColor, selectedColor }) => {
  return (
    <div className="colors-ctr">
      <div className="mini-title color-title">{COLOR_TITLE}</div>
      <div className="colors">
        {finishColors?.map((color, index) => (
          <Image
            key={index}
            onClick={() => updateColor(color)}
            desktop={color?.colorIcon?.desktop}
            title={color?.colorIcon?.desktop.alt}
            mobile={color?.colorIcon?.mobile}
            className={`color ${
              selectedColor?.colorIcon?.desktop?.src === color?.colorIcon?.desktop?.src ? 'active' : ''
            }`}
          />
        ))}
      </div>
    </div>
  );
};

Colors.propTypes = {
  finishColors: propTypes.array,
  updateColor: propTypes.func,
  selectedColor: propTypes.object
};

export default Colors;
