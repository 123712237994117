import styled from 'styled-components';

const StyledHeroBannerSection = styled.div`
  ${({ theme }) => theme.mixins?.layout};

  .buttons {
    display: flex;
    gap: 10px;
    margin: 32px auto;

    .button-item {
      border-radius: 48px;
    }
  }
`;

export default StyledHeroBannerSection;
