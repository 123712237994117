import {useEffect, useState, useRef} from 'react';
import useDebounce from './useDebounce';

const useScroll = ({delay = 0} = {}) => {
  const [direction, setDirection] = useState('up');
  const [scrollPosition, setScrollPosition] = useState(0);
  const debuncedPosition = useDebounce(scrollPosition, delay);
  const prevScrollY = useRef(0);

  useEffect(() => {
    prevScrollY.current = window.pageYOffset;

    const onScroll = () => {
      const scrollY = window.pageYOffset;
      const isScrolling = Math.abs(scrollY - prevScrollY.current) >= 0;

      if (isScrolling) {
        const direction = scrollY > prevScrollY.current ? 'down' : 'up';
        prevScrollY.current = scrollY > 0 ? scrollY : 0;
        setScrollPosition(scrollY);
        setDirection(direction);
      }
    };

    const scrollEvent = () => window.requestAnimationFrame(onScroll);
    window.addEventListener('scroll', scrollEvent);
    return () => window.removeEventListener('scroll', scrollEvent);
  }, [direction]);

  return {direction, isScrolling: debuncedPosition !== scrollPosition, prevScrollY};
};

export default useScroll;