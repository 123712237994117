'use client';
import Link from 'core/link';
import StyledButton from './StyledButton';
import propTypes from 'prop-types';
import {VARIANT_TYPES} from 'utils/generalProptypes';

const ButtonLink = ({ variant = 'primary', ...props }) => {
  return <StyledButton as={Link} variant={variant} {...props} />;
};

ButtonLink.propTypes = {
  variant: propTypes.oneOf(VARIANT_TYPES),
  props: propTypes.object
};

export default ButtonLink;
