'use client';
import propTypes from 'prop-types';
import Breadcrumb from '@/UIComponents/Breadcrumb';
import HeroBanner from '@/UIComponents/HeroBanner';
import SafetyAndPollutionStrip from '@/UIComponents/SafetyAndPollutionStrip';
import StyledModelPage from './StyledModelPage';
import { numberWithCommas } from '../../../utils/text';
import without from 'lodash/without';
import { max } from 'lodash';

const ClientModelPage = ({
  sections,
  breadcrumbs,
  modelHeroBanner,
  lowestPrice,
  lowestLicenseFee,
  airPollutionLevels,
  safetyLevels,
  finishes
}) => {
  const { title, titleColor, fontSize, fontSizeMobile, mediaFields, layout, priceTitle, underBannerText } =
    modelHeroBanner || {};

  const descriptionJSX = lowestPrice ? (
    <>
      <h2 className="price-title">{priceTitle}</h2>
      <h2 className="description-banner" style={{ color: titleColor }}>
        החל מ-{numberWithCommas(lowestPrice, true)}*
      </h2>
      <span className="license-fee-text">בתוספת אגרת רישוי על סך-₪{lowestLicenseFee}</span>
    </>
  ) : null;

  const createStructuredSchemaData = () => {
    let originPath = process.env.NEXT_PUBLIC_FRONTEND_URL || '';
    if (typeof window !== 'undefined') {
      originPath = window ? window?.location?.origin : '';
    }

    const flatFinishes = finishes?.map((finish) => finish.finish);
    const finishesPrices = flatFinishes?.map(
      ({ ConfigurationPrices }) => ConfigurationPrices?.vehicletotalpricewvat || 0
    );
    const highestPrice = max(without(finishesPrices, 0));

    const offers = {
      '@type': 'AggregateOffer',
      priceCurrency: 'ILS',
      lowPrice: lowestPrice,
      highPrice: highestPrice,
      offerCount: flatFinishes?.length || 0,
      offers: flatFinishes?.map((finish) => ({
        url: `${originPath}${finish?.uri?.slice(0, -1)}`,
        description: finish?.title,
        priceCurrency: 'ILS',
        price: finish?.ConfigurationPrices?.vehicletotalpricewvat || 0,
        itemCondition: 'https://schema.org/NewCondition',
        availability: 'https://schema.org/InStock'
      }))
    };

    return {
      '@context': 'https://schema.org/',
      '@type': 'Vehicle',
      name: 'רכבי smart',
      offers
    };
  };

  return (
    <StyledModelPage $layout={layout}>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className="space-layout">
        <HeroBanner
          title={title}
          titleColor={titleColor}
          fontSize={fontSize}
          fontSizeMobile={fontSizeMobile || fontSize}
          mediafields={mediaFields}
          descriptionJSX={descriptionJSX}
          underBannerText={underBannerText}
        />
        <div className="shrink">
          <SafetyAndPollutionStrip
            safetyLevels={safetyLevels}
            pollutionLevels={airPollutionLevels}
            showMoreDetailsLink
            brand="smart"
          />
        </div>
      </div>
      {sections}

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(createStructuredSchemaData()) }}
      />
    </StyledModelPage>
  );
};

ClientModelPage.propTypes = {
  sections: propTypes.node,
  breadcrumbs: propTypes.array,
  modelHeroBanner: propTypes.object,
  lowestPrice: propTypes.number,
  airPollutionLevels: propTypes.arrayOf(propTypes.number),
  safetyLevels: propTypes.arrayOf(propTypes.number),
  finishes: propTypes.array,
  lowestLicenseFee: propTypes.number
};

export default ClientModelPage;
