import localFont from 'next/font/local';

export const rosenberg = localFont({
  variable: '--rosenberg-font',
  src: [{
    path: './rosenberg/RosenbergTextileSquare-Light_MFW.woff',
    weight: '300',
  },{
    path: './rosenberg/rosenbergtextilesquare-bold_MFW.woff2',
    weight: '700',
  }]
});

export const smartNext = localFont({
  variable: '--smartNext-font',
  src: [{
    path: './smartNext/FORsmartNext-Bold.otf',
    weight: '700',
  },
  {
    path: './smartNext/FORsmartNext-Regular.otf',
    weight: '300',
  }]
});

export const smartSans = localFont({
  variable: '--smartSans-font',
  src:[{
    path: './smartSans/FORsmartSans-Bold.otf',
    weight: '700'
  },{
    path:'./smartSans/FORsmartSans-Regular.otf',
    weight: '400'
  }]
});

export const ploni = localFont({
  variable: '--ploni-font',
  src: [{
    path: './ploni/ploni-demibold-aaa.woff2',
    weight: '700',
  },{
    path: './ploni/ploni-regular-aaa.woff2',
    weight: '300',
  }]
});
