'use client';
import StyledContentWithActions from './StyledContentWithActions';
import propTypes from 'prop-types';
import { Button } from '@/UIComponents/Button';
import {buttonItemProps} from '../../../utils/generalProptypes';
import useIsMobile from '../../../hooks/useIsMobile';

const ClientContentWithActions = ({order, layout, content, actionButtons}) => {
  const isMobile = useIsMobile();

  return (
    <StyledContentWithActions id={order} $layout={layout}>
      <p className='content'>{content}</p>

      {actionButtons.length > 0 && (
        <div className='buttons'>
          {actionButtons.map((btn, i) => {
            const variant = btn?.variant;

            if (variant !== 'text' && variant !== 'outlined') {
              return <Button key={i} {...btn} />;
            }

            return <Button key={i} {...btn} variant={isMobile ? 'text' : 'outlined'}/>;
          })}
        </div>
      )}
    </StyledContentWithActions>
  );
};

ClientContentWithActions.propTypes = {
  layout: propTypes.object,
  order: propTypes.number,
  content: propTypes.string,
  actionButtons: propTypes.arrayOf(buttonItemProps)
};

export default ClientContentWithActions;
